import { FC } from 'react';

import { Grid, TextInput } from '@mantine/core';

import { LoanProductFormData } from './LoanProductForm.types';

const LoanProductForm: FC<LoanProductFormData> = ({ code, feeValue, frequencyType, frequencyValue }) => {
  return (
    <form id="loan-product-form">
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput label="Code" size="md" value={code} readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput label="Fee" size="md" value={feeValue} readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput label="Repayment type" size="md" value={frequencyType} readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput label="Repayment frequency" size="md" value={frequencyValue} readOnly />
        </Grid.Col>
      </Grid>
    </form>
  );
};

export { LoanProductForm };
