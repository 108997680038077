import { isLength, zodResolver, z } from 'ui';

const DISBURSEMENT_REFERENCE_MIN_LENGTH = 5;

const createNewLoansFormZodResolver = () => {
  return zodResolver(
    z.object({
      disbursementReference: z
        .string()
        .min(1, {
          message: 'Disbursement reference is required',
        })
        .refine(
          (value) =>
            isLength(value, {
              min: DISBURSEMENT_REFERENCE_MIN_LENGTH,
            }),
          {
            message: `Disbursement reference must be at least ${DISBURSEMENT_REFERENCE_MIN_LENGTH} characters long`,
          },
        ),
    }),
  );
};

export { createNewLoansFormZodResolver };
