import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedLoanApplicationsForDisbursementQuery: DocumentNode = gql`
  query retrievePaginatedLoanApplicationsForDisbursement(
    $adminStatuses: [LoanApplicationAdminStatus!]!
    $pagination: PaginationInput!
    $sort: SortInput!
  ) {
    retrievePaginatedLoanApplicationsByAdminStatus(adminStatuses: $adminStatuses, pagination: $pagination, sort: $sort) {
      count
      data {
        adminStatus
        approvedAtUtc
        entity {
          name
          id
        }
        id
        invoice {
          id
          supplier {
            id
            name
          }
        }
        niceId
        totalLoanAmountInCents
      }
    }
  }
`;

export { retrievePaginatedLoanApplicationsForDisbursementQuery };
