import { DocumentNode, gql } from 'graphql-library';

const updateUserForEntityMutation: DocumentNode = gql`
  mutation updateUserForEntity($id: ID!, $input: UpdateUserInput!) {
    updateUserForStaffMember(id: $id, input: $input) {
      createdAtUtc
      email
      entityId
      firstName
      id
      lastName
      mobileNumber
    }
  }
`;

const retrieveUserForEntityPageUserInfoTabQuery: DocumentNode = gql`
  query retrieveUserForEntityPageUserInfoTab($entityId: String!) {
    retrieveUserForEntity(entityId: $entityId) {
      createdAtUtc
      email
      entityId
      firstName
      id
      lastName
      mobileNumber
    }
  }
`;

export { retrieveUserForEntityPageUserInfoTabQuery, updateUserForEntityMutation };
