import { FC } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayAdminPortalDynamicNextJsTitle, FullPageLayout } from 'ui';

import { UsersTable } from '../UsersTable';

import { useNavigationRoutes } from '../../hooks';

const UsersPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <FullPageLayout
        mainComponent={
          <UsersTable
            paginationOptions={{
              isPaginationEnabled: true,
            }}
          />
        }
      />
    </>
  );
};

export { UsersPage };
