import { FC, useEffect, useState } from 'react';

import { dayjs, saveAs, sentenceCase, useAuthentication } from 'common';
import { useRetrievePaginatedDocumentsForEntityLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  Anchor,
  BizPayPagination,
  BizPayPrimaryButton,
  BizPayTable,
  createColumnHelper,
  FullHeightContainer,
  getCoreRowModel,
  Group,
  RefreshDataControl,
  SortingState,
  TablePageLayout,
  Updater,
  UploadEntityDocumentModal,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { EntityDocument, EntityDocumentsTableProps } from './EntityDocumentsTable.types';

import { useSignOut } from '../../hooks';

const EntityDocumentsTable: FC<EntityDocumentsTableProps> = ({
  entityId,
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination({
    pageSize: 5,
  });
  const { query } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [documents, setDocuments] = useState<EntityDocument[]>([]);
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedDocumentsForEntityQuery, { loading: isLoading, refetch }] =
    useRetrievePaginatedDocumentsForEntityLazyQuery({
      nextFetchPolicy: 'cache-and-network',
      onCompleted: ({ retrievePaginatedDocuments: { count, data } }) => {
        setDocuments(data);
        setHasRetrievedData(true);
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setShouldRefetchData(false);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve entity documents',
        });
      },
    });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<EntityDocument>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor(
        ({ originalFileName, signedUrl }) => ({
          originalFileName,
          signedUrl,
        }),
        {
          cell: ({ getValue }) => {
            const { originalFileName, signedUrl } = getValue();

            return signedUrl ? (
              <Anchor onClick={() => handleDownloadInvoice(originalFileName, signedUrl)}>{originalFileName}</Anchor>
            ) : (
              originalFileName
            );
          },
          enableSorting: isSortingEnabled,
          header: 'File name',
          minSize: 0,
          size: 0,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor('description', {
        cell: ({ getValue }) => {
          const description = getValue();
          return description ? sentenceCase(description) : '-';
        },
        enableSorting: isSortingEnabled,
        header: 'Description',
        size: 0,
        minSize: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('type', {
        cell: ({ getValue }) => sentenceCase(getValue()),
        enableSorting: isSortingEnabled,
        header: 'Type',
        size: 250,
        sortDescFirst: false,
      }),
      columnHelper.accessor(
        ({ uploadedByStaffMember, uploadedByUser }) => ({
          uploadedByStaffMember,
          uploadedByUser,
        }),
        {
          cell: ({ getValue }) => {
            const { uploadedByStaffMember, uploadedByUser } = getValue();

            const uploadedBy = (uploadedByStaffMember ?? uploadedByUser)?.fullName;

            return uploadedBy ? uploadedBy : 'System';
          },
          enableSorting: isSortingEnabled,
          header: 'Uploaded by',
          size: 150,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date uploaded',
        size: 200,
        sortDescFirst: false,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="document"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          minSize: 10,
          size: 10,
        },
      ),
    ];
  };

  const handleDownloadInvoice = (originalFileName: string, url: string) => {
    saveAs(url, originalFileName);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchData(true);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const handleUploadEntityDocumentModalClose = () => {
    setIsModalOpen(false);
  };

  const handleUploadEntityDocumentModalCompleted = () => {
    setIsModalOpen(false);
    setShouldRefetchData(true);
  };

  const handleUploadEntityDocumentModalOpen = () => {
    setIsModalOpen(true);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<EntityDocument>({
    columns: getTableColumns(totalRecords),
    data: documents,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedDocumentsForEntityQuery({
      variables: {
        entityId,
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <>
      <UploadEntityDocumentModal
        endpointPath="upload-document-by-staff-member"
        entityId={entityId}
        isOpen={isModalOpen}
        onClose={handleUploadEntityDocumentModalClose}
        onCompleted={handleUploadEntityDocumentModalCompleted}
      />

      <Group mb="sm" position="right">
        <BizPayPrimaryButton onClick={handleUploadEntityDocumentModalOpen}>Upload new document</BizPayPrimaryButton>
      </Group>

      <FullHeightContainer>
        <TablePageLayout
          loadingMessage={isLoading ? 'Retrieving documents...' : undefined}
          paginationComponent={
            <BizPayPagination
              description={generatePaginationResultsDescription(
                isPaginationEnabled,
                currentPageNumber,
                currentPageSize,
                calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
                totalRecords,
              )}
              hasRetrievedData={hasRetrievedData}
              isEnabled={isPaginationEnabled}
              pageSize={currentPageSize}
              totalPages={totalPages}
              totalRecords={totalRecords}
              value={currentPageNumber}
              onChange={handlePageNumberChange}
            />
          }
          tableComponent={
            <BizPayTable<EntityDocument>
              hasRetrievedData={hasRetrievedData}
              headerGroups={getHeaderGroups()}
              noRecordsMessage="No documents found for this entity"
              rowModel={getRowModel()}
            />
          }
        />
      </FullHeightContainer>
    </>
  );
};

export { EntityDocumentsTable };
