import { currency } from 'common';
import { isCurrency, z, zodResolver } from 'ui';

import { FACILITY_LIMIT_TYPE_TO_ARTICLE_MAP } from './EntityFinancialInfoForm.constants';
import { FacilityLimitType } from './EntityFinancialInfoForm.types';

const facilityLimitZodEffect = (facilityLimitType: FacilityLimitType) => {
  const article = FACILITY_LIMIT_TYPE_TO_ARTICLE_MAP[facilityLimitType];

  return z
    .string()
    .min(1, {
      message: `Please enter ${article} ${facilityLimitType} facility limit`,
    })
    .refine((value) => !value.includes('$'), {
      message: `Please enter ${article} ${facilityLimitType} facility limit without the $ symbol`,
    })
    .refine((value) => isCurrency(value), {
      message: `Please enter a valid ${facilityLimitType} facility limit`,
    });
};

const entityFinancialInfoFormZodResolver = () => {
  return zodResolver(
    z
      .object({
        approvedFacilityLimit: facilityLimitZodEffect('approved'),
        currentFacilityLimit: facilityLimitZodEffect('current'),
        dscr: z.number().nullable(),
        provision: z
          .string()
          .refine((value) => !value.includes('$'), {
            message: `Please enter provision without the $ symbol`,
          })
          .refine(
            (value) => {
              if (!value) {
                return true;
              }

              return isCurrency(value);
            },
            {
              message: `Please enter a valid provision`,
            },
          )
          .nullable(),
      })
      .refine(
        ({ approvedFacilityLimit, currentFacilityLimit }) =>
          currency(currentFacilityLimit).intValue <= currency(approvedFacilityLimit).intValue,
        {
          message: 'The current facility limit cannot be greater than the approved facility limit',
          path: ['currentFacilityLimit'],
        },
      ),
  );
};

export { entityFinancialInfoFormZodResolver };
