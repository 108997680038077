import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { ProductFeeType, useCreateProductMutation, useUpdateProductMutation } from 'graphql-library';
import { BizPayLoader, BizPayModal, Stack, useBizPayNotification } from 'ui';

import { ProductForm } from '../ProductForm/ProductForm';

import { convertFeeValue } from './ProductModal.helpers';
import { ProductModalProps } from './ProductModal.types';

import { useSignOut } from '../../hooks';

const ProductModal: FC<ProductModalProps> = ({ isOpen, onClose, onCompleted, product }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();

  const [executeCreateProductMutation, { loading: isCreateProductLoading }] = useCreateProductMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Product has been added successfully',
      });

      onCompleted();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to add the product',
      });
    },
  });

  const [executeUpdateProductMutation, { loading: isUpdateProductLoading }] = useUpdateProductMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Product has been updated successfully',
      });

      onCompleted();
    },
    onError: ({ message }) => {
      displayErrorNotification({
        message,
      });
    },
  });

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isLoading = isCreateProductLoading || isUpdateProductLoading;

  const action = product ? 'Update' : 'Create';
  const title = `${action} product`;
  const loadingMessage = `${action.substring(0, action.length - 1)}ing product, please wait...`;

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      opened={isOpen}
      size={850}
      title={title}
      withCloseButton={!isCreateProductLoading}
      onClose={handleClose}
    >
      <Stack h={450} w="100%">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            <BizPayLoader message={loadingMessage} />
          </Stack>
        ) : (
          <ProductForm
            initialDefaultValues={{
              code: product?.code ?? '',
              customerFee: product?.customerFee ?? undefined,
              displayName: product?.displayName ?? '',
              feeType: product?.feeType ?? undefined,
              feeValue: convertFeeValue(product?.feeType, product?.feeValue),
              frequencyType: product?.frequencyType ?? undefined,
              frequencyValue: product?.frequencyValue ?? undefined,
              partnerFee: product?.partnerFee ?? undefined,
            }}
            submitButtonText={title}
            onSubmit={({ code, customerFee, displayName, feeType, feeValue, frequencyType, frequencyValue, partnerFee }) => {
              // Note: When the fee type is fixed fee, the value is in dollars but we save the value in cents
              const updatedFeeValue = feeType === ProductFeeType.FixedFee ? feeValue * 100 : feeValue;

              if (product) {
                executeUpdateProductMutation({
                  variables: {
                    id: product.id,
                    input: {
                      code,
                      customerFee,
                      displayName,
                      feeType,
                      feeValue: updatedFeeValue,
                      frequencyType,
                      frequencyValue,
                      partnerFee,
                    },
                  },
                });

                return;
              }

              executeCreateProductMutation({
                variables: {
                  input: {
                    code,
                    customerFee,
                    displayName,
                    feeType,
                    feeValue: updatedFeeValue,
                    frequencyType,
                    frequencyValue,
                    partnerFee,
                  },
                },
              });
            }}
          />
        )}
      </Stack>
    </BizPayModal>
  );
};

export { ProductModal };
