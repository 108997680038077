import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedProductsQuery: DocumentNode = gql`
  query retrievePaginatedProducts($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedProducts(pagination: $pagination, sort: $sort) {
      count
      data {
        code
        customerFee
        displayName
        feeType
        feeValue
        frequencyType
        frequencyValue
        id
        name
        partnerFee
      }
    }
  }
`;

export { retrievePaginatedProductsQuery };
