/* eslint-disable prefer-destructuring */
import { CSSObject, MantineSizes, MantineThemeOverride } from '@mantine/core';

import { USE_BIZPAY_UI_THEME_CONSTANTS } from './useBizPayUITheme.constants';
import { UseBizPayUITheme } from './useBizPayUITheme.types';

const {
  colors,
  inputFocusStyles: { border },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const useBizPayUITheme = (): UseBizPayUITheme => {
  const generateMantineTheme = (): MantineThemeOverride => {
    const customFontSizes: MantineSizes = {
      xs: '12',
      sm: '14',
      md: '16',
      lg: '18',
      xl: '20',
    };

    const customSpacing: MantineSizes = {
      xs: '1rem',
      sm: '1.25rem',
      md: '1.5rem',
      lg: '1.75rem',
      xl: '2rem',
    };

    const inputFocusStyles: CSSObject = {
      borderColor: colors.darkBlueGrayPalette[3],
      borderWidth: border.width,
    };

    return {
      colors: {
        'bizpay-dark-blue-gray': colors.darkBlueGrayPalette,
        'bizpay-light-gray': colors.lightGrayPalette,
        'bizpay-red': colors.redPalette,
        'bizpay-teal': colors.tealPalette,
        'bizpay-yellow': colors.yellowPalette,
        'bizpay-white': ['#fff'],
      },
      components: {
        Accordion: {
          styles: ({ colors }) => ({
            content: {
              padding: '1rem',
            },
            control: {
              paddingLeft: '1rem',
              paddingRight: '1rem',
            },
            item: {
              backgroundColor: colors['bizpay-dark-blue-gray'][0],
              border: `0.075rem solid ${colors['bizpay-light-gray'][1]}`,
              borderRadius: '1rem',
              boxShadow: `0.05rem 0.05rem ${colors['bizpay-light-gray'][2]}`,
            },
            label: {
              paddingBottom: '1rem',
              paddingTop: '1rem',
            },
          }),
        },
        Alert: {
          styles: ({ fontSizes: { md, lg } }) => ({
            icon: {
              height: 30,
              marginRight: 0,
              width: 30,
            },
            message: {
              fontSize: Number(md),
              marginTop: '0.5rem',
            },
            root: {
              overflow: 'visible',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            },
            title: {
              fontSize: Number(lg),
              marginBottom: 0,
              marginTop: 0,
            },
          }),
        },
        Anchor: {
          styles: ({ colors }) => ({
            root: {
              color: colors['bizpay-light-gray'][7],
              textDecoration: 'underline',
            },
          }),
        },
        AppShell: {
          styles: ({ colors }) => ({
            main: {
              backgroundColor: colors['bizpay-dark-blue-gray'][0],
            },
          }),
        },
        Badge: {
          styles: ({ fontSizes, spacing }) => ({
            root: {
              borderRadius: '0.5rem',
              fontSize: Number(fontSizes.xs),
              padding: Number(spacing.xs),
              textTransform: 'capitalize',
            },
          }),
        },
        Breadcrumbs: {
          styles: () => ({
            separator: {
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
            },
          }),
        },
        Button: {
          styles: ({ black, fontSizes: { sm } }) => ({
            root: {
              color: black,
              fontSize: Number(sm),
              fontWeight: 'normal',
            },
          }),
        },
        Checkbox: {
          styles: ({ colors }) => ({
            body: {
              alignItems: 'center',
              justifyContent: 'center',
            },
            inner: {
              input: {
                borderRadius: '5px',
              },
            },
            input: {
              ':checked': {
                backgroundColor: colors['bizpay-teal'][3],
                borderColor: colors['bizpay-teal'][7],
              },
            },
          }),
        },
        Container: {
          defaultProps: {
            sizes: {
              xs: 280,
              sm: 460,
              md: 640,
              lg: 960,
              xl: 1000,
            },
          },
        },
        DatePickerInput: {
          styles: ({ colors, fontSizes: { sm } }) => ({
            calendar: {
              fontSize: Number(sm),
            },
            input: {
              fontSize: Number(sm),
              backgroundColor: 'white !important',

              '&[data-invalid]': {
                border: `0.075rem solid ${colors.red[8]}`,
                color: colors.red[8],

                '::placeholder': {
                  color: colors.red[8],
                },
              },

              '&[data-read-only]': {
                backgroundColor: `${colors['bizpay-dark-blue-gray'][0]} !important`,
              },
            },
            label: {
              width: '100%',
            },
          }),
        },
        Group: {
          styles: ({ fontSizes: { sm } }) => ({
            root: {
              fontSize: Number(sm),
              gap: 0,
            },
          }),
        },
        Input: {
          styles: ({ colors }) => ({
            input: {
              border: `0.075rem solid ${colors['bizpay-dark-blue-gray'][3]}`,
              color: colors['bizpay-light-gray'][7],

              ':focus': {
                ...inputFocusStyles,
              },

              ':focus-within': {
                ...inputFocusStyles,
              },

              ':read-only': {
                backgroundColor: colors['bizpay-dark-blue-gray'][0],
                borderColor: colors['bizpay-light-gray'][3],
                borderWidth: 1,
              },
            },
            invalid: {
              border: `0.075rem solid ${colors.red[8]}`,
              color: colors.red[8],

              '::placeholder': {
                color: colors.red[8],
              },
            },
          }),
        },
        InputWrapper: {
          styles: ({ colors, fontSizes: { sm } }) => ({
            label: {
              color: colors['bizpay-light-gray'][7],
              fontSize: Number(sm),
            },
          }),
        },
        List: {
          styles: ({ colors, fontSizes: { sm } }) => ({
            itemIcon: {
              marginRight: 0,
            },
            itemWrapper: {
              color: colors['bizpay-light-gray'][7],
              fontSize: Number(sm),
            },
          }),
        },
        Menu: {
          styles: ({ colors, fontSizes: { sm } }) => ({
            item: {
              padding: '0.75rem',

              ':hover': {
                backgroundColor: colors['bizpay-teal'][3],
              },
            },
            itemIcon: {
              width: 18,
            },
            itemLabel: {
              fontSize: Number(sm),
            },
            label: {
              color: colors['bizpay-teal'][3],
              fontSize: Number(sm),
            },
          }),
        },
        Modal: {
          styles: ({ fontSizes: { xl } }) => ({
            title: {
              fontSize: Number(xl),
              fontWeight: 700,
            },
          }),
        },
        NavLink: {
          styles: ({ black, radius }) => ({
            label: {
              color: black,
            },
            root: {
              backgroundColor: 'transparent',
              borderRadius: radius.md,
              color: black,
              marginBottom: 2,

              '&[data-active]': {
                color: black,
              },

              '&[data-active]:hover': {
                color: black,
              },

              '&:hover': {
                color: black,
              },
            },
          }),
        },
        Notification: {
          styles: ({ fontSizes: { sm } }) => ({
            title: {
              fontSize: Number(sm),
              fontWeight: 600,
            },
            description: {
              fontSize: Number(sm),
            },
          }),
        },
        NumberInput: {
          styles: ({ colors, fontSizes: { sm } }) => {
            const redColor = colors['bizpay-red'][7];

            return {
              input: {
                fontSize: Number(sm),

                '&[data-invalid]': {
                  borderColor: redColor,
                  color: redColor,
                },
                '&[data-invalid]::placeholder': {
                  color: redColor,
                },
              },
              label: {
                width: '100%',
              },
            };
          },
        },
        Pagination: {
          styles: ({ black, colors }) => {
            return {
              control: {
                '&[data-active]': {
                  backgroundColor: colors['bizpay-teal'][3],
                  borderColor: colors['bizpay-teal'][4],
                  color: black,
                },
                '&[data-active]:not([data-disabled]):hover': {
                  backgroundColor: colors['bizpay-teal'][4],
                  borderColor: colors['bizpay-teal'][3],
                },
              },
            };
          },
        },
        Radio: {
          styles: ({ colors, radius }) => ({
            inner: {
              alignSelf: 'center',
            },
            root: {
              backgroundColor: colors['bizpay-teal'][0],
              border: `0.075rem solid ${colors['bizpay-teal'][3]}`,
              borderRadius: radius.md,
              boxShadow: `0.05rem 0.05rem ${colors['bizpay-light-gray'][2]}`,

              '&:hover': {
                backgroundColor: colors['bizpay-teal'][1],
              },
            },
          }),
        },
        Select: {
          styles: ({ black, colors, fontSizes: { sm }, white }) => {
            const bizpayTealColor = colors['bizpay-teal'][3];

            return {
              input: {
                backgroundColor: `${white} !important`,
                fontSize: Number(sm),

                ':read-only': {
                  ':hover': {
                    cursor: 'default',
                  },
                },

                ':hover': {
                  cursor: 'pointer',
                },
              },
              item: {
                padding: '0.75rem',
                fontSize: Number(sm),
                fontWeight: 'normal',

                '&[data-selected]': {
                  '&, &:hover': {
                    backgroundColor: bizpayTealColor,
                    color: black,
                  },
                },

                '&[data-hovered]': {
                  backgroundColor: bizpayTealColor,
                },
              },
            };
          },
        },
        Stack: {
          styles: () => ({
            root: {
              gap: 0,
            },
          }),
        },
        Stepper: {
          styles: ({ colors, white }) => {
            const bizpayTealColor = colors['bizpay-teal'][3];

            return {
              stepIcon: {
                backgroundColor: white,
                '&[data-completed]': {
                  backgroundColor: bizpayTealColor,
                  borderColor: bizpayTealColor,
                },
                '&[data-progress]': {
                  backgroundColor: white,
                  borderColor: bizpayTealColor,
                },
              },
              stepLabel: {
                fontWeight: 600,
              },
              verticalSeparatorActive: {
                borderColor: bizpayTealColor,
              },
            };
          },
        },
        Switch: {
          styles: ({ colors }) => ({
            root: {
              alignItems: 'center',
              borderRadius: 9,
              display: 'flex',
              justifyContent: 'center',
              padding: '6px 8px',

              'input + label': {
                height: 16,
                margin: 'auto',
                minWidth: 32,
                overflow: 'visible',
                width: 32,

                'div:last-child': {
                  padding: 0,
                },
              },

              '&:has(input:checked)': {
                backgroundColor: colors['bizpay-teal'][2],
              },

              'input:checked + label': {
                backgroundColor: colors['bizpay-teal'][4],
                borderColor: colors['bizpay-teal'][4],

                'div:first-child': {
                  borderColor: colors['bizpay-teal'][4],
                  height: 15,
                  marginLeft: 3,
                  width: 15,
                },
              },
            },
          }),
        },
        Table: {
          styles: ({ colors, fontSizes: { sm }, spacing: { xs }, white }) => ({
            root: {
              thead: {
                tr: {
                  th: {
                    backgroundColor: white,
                    border: 'none',
                    borderBottomColor: colors['bizpay-light-gray'][3],
                    borderBottomStyle: 'solid',
                    borderBottomWidth: 1,
                    color: colors['bizpay-light-gray'][7],
                    fontSize: Number(sm),
                    fontWeight: 'bold',
                    paddingBottom: Number(xs),
                    paddingTop: Number(xs),
                    whiteSpace: 'nowrap',
                  },
                },
              },
              tbody: {
                tr: {
                  td: {
                    border: 'none',
                    color: colors['bizpay-light-gray'][7],
                    fontSize: Number(sm),
                  },
                },
              },
            },
          }),
        },
        Tabs: {
          styles: ({ colors, fontSizes: { sm } }) => {
            const bizpayTealColor = colors['bizpay-teal'][3];

            return {
              tab: {
                paddingBottom: '1rem',
                paddingLeft: '1.5rem',
                paddingRight: '1.5rem',
                paddingTop: '1rem',

                '&[data-active]': {
                  borderColor: bizpayTealColor,
                },
                '&[data-active]:hover': {
                  borderColor: bizpayTealColor,
                },
              },
              tabLabel: {
                color: colors['bizpay-light-gray'][7],
                fontSize: Number(sm),
                fontWeight: 'bold',
              },
            };
          },
        },
        Text: {
          styles: ({ colors, fontSizes: { sm } }) => ({
            root: {
              color: colors['bizpay-light-gray'][7],
              fontSize: Number(sm),
              whiteSpace: 'pre-wrap',
            },
          }),
        },
        TextInput: {
          styles: ({ colors, fontSizes: { sm } }) => {
            const redColor = colors['bizpay-red'][7];

            return {
              input: {
                fontSize: Number(sm),

                '&[data-invalid]': {
                  borderColor: redColor,
                  color: redColor,
                },
                '&[data-invalid]::placeholder': {
                  color: redColor,
                },
              },
              label: {
                width: '100%',
              },
            };
          },
        },
        Title: {
          styles: () => ({
            root: {
              whiteSpace: 'nowrap',
            },
          }),
        },
        Tooltip: {
          styles: ({ black, colors }) => ({
            arrow: {
              backgroundColor: colors.gray[1],
              borderColor: colors.gray[4],
              borderStyle: 'solid',
              borderWidth: '1px',
            },
            tooltip: {
              backgroundColor: colors.gray[1],
              borderColor: colors.gray[4],
              borderStyle: 'solid',
              borderWidth: '1px',
              color: black,
              fontSize: '0.75rem',
              fontWeight: 'normal',
            },
          }),
        },
      },
      defaultRadius: 'md',
      fontFamily: 'Roboto, sans-serif !important',
      fontSizes: customFontSizes,
      spacing: customSpacing,
    };
  };

  return {
    theme: generateMantineTheme(),
  };
};

export { useBizPayUITheme };
