import { FC } from 'react';

import { Group, Menu, ActionIcon } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';

import { TableActionDropdownMenuProps } from './TableActionDropdownMenu.types';

const TableActionDropdownMenu: FC<TableActionDropdownMenuProps> = ({ menuItems, menuPosition = 'bottom-end' }) => {
  return (
    <Group position="right">
      <Menu position={menuPosition}>
        <Menu.Target>
          <ActionIcon>
            <IconDotsVertical size={16} stroke={1.5} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {menuItems.map(({ color, disabled, icon, id, label, onClick }) => {
            return (
              <Menu.Item key={id} color={color} disabled={disabled} icon={icon} onClick={onClick}>
                {label}
              </Menu.Item>
            );
          })}
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
};

export { TableActionDropdownMenu };
