import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { useSelfCertifySupplierBankAccountForLoanApplicationMutation } from 'graphql-library';
import { BizPayLoader, BizPayModal, Stack, useBizPayNotification } from 'ui';

import { EftsureSelfCertifyForm } from '../EftsureSelfCertifyForm/EftsureSelfCertifyForm';

import { EftsureSelfCertifyModalProps } from './EftsureSelfCertifyModal.types';

import { useSignOut } from '../../hooks';

const EftsureSelfCertifyModal: FC<EftsureSelfCertifyModalProps> = ({
  isLoading,
  isOpen,
  loanApplicationId,
  onClose,
  onCompleted,
  supplierBankAccountId,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [
    executeSelfCertifySupplierBankAccountForLoanApplicationMutation,
    { loading: isSelfCertifySupplierBankAccountForLoanApplicationLoading },
  ] = useSelfCertifySupplierBankAccountForLoanApplicationMutation({
    onCompleted: ({ selfCertifySupplierBankAccountWithEftsureForLoanApplication: returnedSupplierBankAccount }) => {
      onCompleted(returnedSupplierBankAccount);
      handleClose();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to self certify supplier bank account',
      });
    },
  });

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      opened={isOpen}
      size={650}
      title="Are you sure want to self-certify?"
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      <Stack h={200} w="100%">
        {isSelfCertifySupplierBankAccountForLoanApplicationLoading ? (
          <Stack align="center" h="100%" justify="center">
            <BizPayLoader message="Self-certifying supplier bank account, please wait..." />
          </Stack>
        ) : (
          <Stack h="100%" justify="flex-start">
            <EftsureSelfCertifyForm
              onCancel={handleClose}
              onSubmit={({ reason }) => {
                if (!getIsAuthenticated()) {
                  signOut();
                  return;
                }

                executeSelfCertifySupplierBankAccountForLoanApplicationMutation({
                  variables: {
                    id: supplierBankAccountId,
                    loanApplicationId,
                    reason,
                  },
                });
              }}
            />
          </Stack>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { EftsureSelfCertifyModal };
