import { createStyles } from '@mantine/core';

const useBizPayTableStyles = createStyles(({ colors }) => ({
  header: {
    position: 'sticky',
    top: 0,

    '&::after': {
      borderBottom: `0.075rem solid ${colors['bizpay-light-gray'][3]}`,
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
    },
  },
}));

export { useBizPayTableStyles };
