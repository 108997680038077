import { DocumentNode, gql } from 'graphql-library';

const retrieveNotesForEntityQuery: DocumentNode = gql`
  query retrieveNotesForEntity($entityId: String!) {
    retrieveNotesForEntity(entityId: $entityId) {
      createdAtUtc
      id
      message
      submittedByStaffMember {
        fullName
        id
      }
    }
  }
`;

export { retrieveNotesForEntityQuery };
