import { FC } from 'react';

import { Badge } from '@mantine/core';

import { BizPayAdminPortalBadgeProps } from './BizPayAdminPortalBadge.types';

const BizPayAdminPortalBadge: FC<BizPayAdminPortalBadgeProps> = (props) => {
  return (
    <Badge
      size="sm"
      sx={({ black, colors }) => ({
        backgroundColor: colors['bizpay-light-gray'][3],
        color: black,
      })}
      variant="filled"
      {...props}
    >
      Admin portal
    </Badge>
  );
};

export { BizPayAdminPortalBadge };
