import { FC, useEffect } from 'react';

import { Anchor, Grid, Group, Loader, Stack, Text, TextInput } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';
import { TextInputRightSection } from '../TextInputRightSection';

import { userProfileFormZodResolver } from './UserProfileForm.helpers';
import { UserProfileFormData, UserProfileFormProps } from './UserProfileForm.types';

const UserProfileForm: FC<UserProfileFormProps<UserProfileFormData>> = ({
  contactUsEmail,
  entityActionIcon,
  initialDefaultValues,
  isChangeEmailNoteVisible = false,
  isLoading = false,
  onSubmit,
}) => {
  const {
    formState: { defaultValues, errors, isDirty, isValid },
    handleSubmit,
    register,
    reset,
    setFocus,
    setValue,
    trigger,
    watch,
  } = useForm<UserProfileFormData>({
    defaultValues: initialDefaultValues,
    mode: 'onChange',
    resolver: userProfileFormZodResolver(),
  });

  const handleCancel = () => {
    reset(defaultValues);
  };

  const handleMobileNumberChange = (value: string) => {
    setValue('mobileNumber', value.replaceAll(' ', ''), {
      shouldDirty: true,
    });
    trigger('mobileNumber');
  };

  useEffect(() => {
    setFocus('firstName');
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { email: emailError, firstName: firstNameError, lastName: lastNameError, mobileNumber: mobileNumberError } = errors;

  return (
    <form
      id="user-profile-form"
      style={{
        width: '100%',
      }}
      onSubmit={handleSubmit((formData) => {
        onSubmit(formData);
        reset(formData);
      })}
    >
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('firstName')}
            disabled={isLoading}
            error={firstNameError ? <ErrorMessage message={firstNameError.message} /> : null}
            label="First name"
            rightSection={<TextInputRightSection error={firstNameError} value={watch('firstName')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('lastName')}
            disabled={isLoading}
            error={lastNameError ? <ErrorMessage message={lastNameError.message} /> : null}
            label="Last name"
            rightSection={<TextInputRightSection error={lastNameError} value={watch('lastName')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('email')}
            error={emailError ? <ErrorMessage message={emailError.message} /> : null}
            label="Email"
            placeholder="you@yourdomain.com"
            rightSection={<TextInputRightSection error={emailError} value={watch('email')} />}
            size="md"
            readOnly
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('mobileNumber')}
            disabled={isLoading}
            error={mobileNumberError ? <ErrorMessage message={mobileNumberError.message} /> : null}
            label="Mobile number"
            rightSection={<TextInputRightSection error={mobileNumberError} value={watch('mobileNumber')} />}
            size="md"
            onChange={({ target: { value } }) => handleMobileNumberChange(value)}
          />
        </Grid.Col>
      </Grid>

      {initialDefaultValues.entityName && (
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput {...register('entityName')} label="Entity name" rightSection={entityActionIcon} size="md" readOnly />
          </Grid.Col>
          <Grid.Col span="auto"></Grid.Col>
        </Grid>
      )}

      <Group mt={30} w="100%" noWrap>
        {isChangeEmailNoteVisible && (
          <Stack w="100%">
            <Text
              sx={({ colors }) => ({
                color: colors['bizpay-light-gray'][4],
                fontSize: '0.75rem',
              })}
            >
              Contact BizPay to get your email address changed.
            </Text>
            <Text
              sx={({ colors }) => ({
                color: colors['bizpay-light-gray'][4],
                fontSize: '0.75rem',
              })}
            >
              Phone: 1300 424 972 Email:{' '}
              <Anchor
                href={`mailto:${contactUsEmail}`}
                sx={({ colors }) => ({
                  color: colors['bizpay-light-gray'][4],
                  fontSize: '0.75rem',
                })}
                target="_blank"
              >
                {contactUsEmail}
              </Anchor>
            </Text>
          </Stack>
        )}

        <Group position="right" w="100%" noWrap>
          <BizPayPrimaryButton disabled={isLoading || !isDirty || !isValid} mr="xs" type="submit">
            {isLoading ? <Loader size="xs" /> : 'Save'}
          </BizPayPrimaryButton>

          <BizPayPrimaryButton disabled={isLoading || !isDirty} onClick={handleCancel}>
            {isLoading ? <Loader size="xs" /> : 'Cancel'}
          </BizPayPrimaryButton>
        </Group>
      </Group>
    </form>
  );
};

export { UserProfileForm };
