import { FC } from 'react';

import { List, Stack, Text } from '@mantine/core';
import { IconFile } from '@tabler/icons-react';
import { compact, concat, flattenDeep, kebabCase, pluralize, useDropzone, useNumberFormatter, useUnitConversion } from 'common';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';

import { FILE_UPLOAD_CONSTANTS } from './FileUpload.constants';
import { generateFileRejectionErrorMessage } from './FileUpload.helpers';
import { FileUploadProps } from './FileUpload.types';

const FileUpload: FC<FileUploadProps> = ({
  acceptedFileTypes = Object.keys(FILE_UPLOAD_CONSTANTS.fileTypeToMimeTypeMap),
  errorContent,
  instructionText,
  maxFiles = 1,
  maxFileSizeInMegabytes = FILE_UPLOAD_CONSTANTS.maxFileSizeInMegabytes,
  multiple,
  selectFileButtonText,
  shouldDisplayAcceptedFiles = false,
  ...dropzoneProps
}) => {
  const { acceptedFiles, getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: Object.fromEntries(
      acceptedFileTypes.reduce((accumulator, item) => {
        const key = FILE_UPLOAD_CONSTANTS.fileTypeToMimeTypeMap[item];
        const value = accumulator.get(key);

        accumulator.set(key, flattenDeep(compact(concat(value ?? [], [`.${item}`]))));

        return accumulator;
      }, new Map<string, string[]>()),
    ),
    maxFiles,
    maxSize: 1024 * 1024 * maxFileSizeInMegabytes,
    multiple: multiple || maxFiles !== 1,
    ...dropzoneProps,
  });
  const { formatWithThousandsSeparator } = useNumberFormatter();
  const { getMegabytesFromBytes } = useUnitConversion();

  const pluralizedFiles = pluralize('file', maxFiles);

  return (
    <Stack align="center" w="100%" {...getRootProps()}>
      <input data-testid="e2e-file-upload" {...getInputProps()} />

      {isDragActive ? (
        <Text>Drop the {pluralizedFiles} here</Text>
      ) : (
        <>
          <Text mt="md">{instructionText || `Drag and drop the above mentioned ${pluralizedFiles} here`}</Text>
          <Text mt="md">or</Text>
          <BizPayPrimaryButton mt="md">{selectFileButtonText || `Select ${pluralizedFiles}`}</BizPayPrimaryButton>
        </>
      )}

      {!!acceptedFiles.length && shouldDisplayAcceptedFiles && (
        <List icon={<IconFile />} mt="md" center>
          {acceptedFiles.map(({ name, size }) => {
            const sizeInMegabytes = getMegabytesFromBytes(size);

            const fileSizeToDisplay =
              sizeInMegabytes > 1
                ? `${formatWithThousandsSeparator(sizeInMegabytes, 0)} MB`
                : `${formatWithThousandsSeparator(size, 0)} KB`;

            return (
              <List.Item key={kebabCase(name)} data-testid="e2e-file-upload-file-details">
                {name} ({fileSizeToDisplay})
              </List.Item>
            );
          })}
        </List>
      )}

      {!!acceptedFileTypes && (
        <Text
          mt="md"
          sx={({ colors }) => ({
            color: colors.gray[5],
          })}
        >
          {`(${generateFileRejectionErrorMessage()})`}
        </Text>
      )}

      <Text
        sx={({ colors }) => ({
          color: colors.gray[5],
        })}
      >
        (Maximum file size is {maxFileSizeInMegabytes}MB)
      </Text>

      <Text mt={10}>{errorContent}</Text>
    </Stack>
  );
};

export { FileUpload };
