import { zodResolver, z } from 'ui';

const eftsureSelfCertifyFormZodResolver = () => {
  return zodResolver(
    z.object({
      reason: z.string().min(1, {
        message: 'Reason is required',
      }),
    }),
  );
};

export { eftsureSelfCertifyFormZodResolver };
