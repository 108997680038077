import { FC } from 'react';

import { BizPayPieChart } from '../BizPayPieChart';

import { LoanFacilityUsagePieChartProps } from './LoanFacilityUsagePieChart.types';

const LoanFacilityUsagePieChart: FC<LoanFacilityUsagePieChartProps> = (props) => {
  return <BizPayPieChart {...props} />;
};

export { LoanFacilityUsagePieChart };
