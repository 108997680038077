import { FC } from 'react';

import { ActionIcon } from '@mantine/core';
import { IconBuildingSkyscraper } from '@tabler/icons-react';

import { EntityActionIconProps } from './EntityActionIcon.types';

const EntityActionIcon: FC<EntityActionIconProps> = ({ onClick }) => {
  return (
    <ActionIcon
      sx={({ colors }) => ({
        backgroundColor: colors['bizpay-teal'][3],

        ':hover': {
          backgroundColor: colors['bizpay-teal'][4],
          borderColor: colors['bizpay-teal'][5],
        },
      })}
      onClick={onClick}
    >
      <IconBuildingSkyscraper size={20} />
    </ActionIcon>
  );
};

export { EntityActionIcon };
