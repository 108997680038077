import { FC, useEffect } from 'react';

import { Grid, Group, Loader, Textarea } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { BizPayTickIcon } from '../BizPayTickIcon';

import { createNoteFormZodResolver } from './CreateNoteForm.helpers';
import { CreateNoteFormData, CreateNoteFormProps } from './CreateNoteForm.types';

const CreateNoteForm: FC<CreateNoteFormProps<CreateNoteFormData>> = ({
  initialDefaultValues,
  isLoading = false,
  isSubmitButtonDisabled = false,
  onSubmit,
}) => {
  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    reset,
    setFocus,
    watch,
  } = useForm<CreateNoteFormData>({
    defaultValues: initialDefaultValues,
    mode: 'onChange',
    resolver: createNoteFormZodResolver(),
  });

  useEffect(() => {
    setFocus('message');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { message: messageError } = errors;

  return (
    <>
      <form
        id="create-note-form"
        onSubmit={handleSubmit((formData) => {
          onSubmit(formData);
          reset();
        })}
      >
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <Textarea
              {...register('message')}
              disabled={isLoading}
              label="Message"
              rightSection={!!watch('message') && !messageError && <BizPayTickIcon />}
              size="md"
            />
          </Grid.Col>
        </Grid>

        <Group align="center" mt="xl" position="right">
          <BizPayPrimaryButton disabled={isSubmitButtonDisabled || isLoading || !isDirty || !isValid} type="submit">
            {isLoading ? <Loader size="xs" /> : 'Add note'}
          </BizPayPrimaryButton>
        </Group>
      </form>
    </>
  );
};

export { CreateNoteForm };
