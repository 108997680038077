import { sentenceCase } from 'common';

import { LoanApplicationPageTabsConfig } from './LoanApplicationPage.types';

const LOAN_APPLICATION_TAB_ID = 'loan-application';
const LOGS_TAB_ID = 'logs';

const LOAN_APPLICATION_PAGE_TABS_CONFIG: LoanApplicationPageTabsConfig[] = [
  {
    id: LOAN_APPLICATION_TAB_ID,
    title: sentenceCase(LOAN_APPLICATION_TAB_ID),
  },
  {
    id: LOGS_TAB_ID,
    title: sentenceCase(LOGS_TAB_ID),
  },
];

const LOAN_APPLICATION_PAGE_CONSTANTS = Object.freeze({
  tabs: {
    config: LOAN_APPLICATION_PAGE_TABS_CONFIG,
    ids: {
      loanApplication: LOAN_APPLICATION_TAB_ID,
      logs: LOGS_TAB_ID,
    },
  },
});

export { LOAN_APPLICATION_PAGE_CONSTANTS };
