import { DocumentNode, gql } from 'graphql-library';

const assignEntityToStaffMemberMutation: DocumentNode = gql`
  mutation assignEntityToStaffMember($assignedToStaffMemberId: String!, $id: ID!) {
    assignEntityToStaffMember(assignedToStaffMemberId: $assignedToStaffMemberId, id: $id) {
      assignedToStaffMemberId
      id
    }
  }
`;

const createNoteForEntityMutation: DocumentNode = gql`
  mutation createNoteForEntity($entityId: String!, $message: String!) {
    createNoteForEntity(entityId: $entityId, message: $message) {
      id
      message
    }
  }
`;

const entityFragment: DocumentNode = gql`
  fragment entityFragment on Entity {
    abn
    abrRegisteredState
    accountStatus
    acn
    assignedToStaffMemberId
    businessNames {
      id
      name
    }
    createdAtUtc
    debtServiceCoverRatio
    equifaxBureauScore
    facebookUrl
    id
    inceptFacilityLimitInCents
    industry
    instagramUrl
    linkedInUrl
    name
    niceId
    partnerId
    provisionInCents
    registeredBusinessAddress {
      city
      country
      id
      postcode
      street
      state
      suburb
    }
    status
    twitterUrl
    type {
      code
      name
    }
    websiteUrl
  }
`;

const retrieveEntityForEntityPageQuery: DocumentNode = gql`
  ${entityFragment}

  query retrieveEntityForEntityPage($id: ID!) {
    retrieveEntity(id: $id) {
      ...entityFragment
    }
  }
`;

const retrievePartnersForEntity: DocumentNode = gql`
  query retrievePartners {
    retrievePartners {
      id
      name
    }
  }
`;

const updateEntityAccountStatusMutation: DocumentNode = gql`
  mutation updateEntityAccountStatus($entityId: ID!, $accountStatus: String!) {
    updateEntityAccountStatus(id: $entityId, accountStatus: $accountStatus) {
      accountStatus
      id
    }
  }
`;

const updateEntityAddressMutation: DocumentNode = gql`
  mutation updateEntityAddress(
    $city: String
    $entityId: ID!
    $country: String
    $postcode: String
    $state: String!
    $street: String
    $suburb: String
  ) {
    updateEntityAddress(
      city: $city
      country: $country
      id: $entityId
      postcode: $postcode
      state: $state
      street: $street
      suburb: $suburb
    ) {
      city
      country
      id
      postcode
      state
      street
      suburb
    }
  }
`;

const updateEntityMutation: DocumentNode = gql`
  ${entityFragment}

  mutation updateEntity(
    $abrRegisteredState: String
    $debtServiceCoverRatio: Float
    $id: ID!
    $industry: String
    $provisionInCents: Int
    $websiteUrl: String
  ) {
    updateEntityDetails(
      abrRegisteredState: $abrRegisteredState
      id: $id
      debtServiceCoverRatio: $debtServiceCoverRatio
      industry: $industry
      provisionInCents: $provisionInCents
      websiteUrl: $websiteUrl
    ) {
      ...entityFragment
    }
  }
`;

const updateEntityPartnerMutation: DocumentNode = gql`
  mutation updateEntityPartner($partnerId: String, $id: ID!) {
    updateEntityPartner(partnerId: $partnerId, id: $id) {
      id
      partnerId
    }
  }
`;

const updateEntityStatusMutation: DocumentNode = gql`
  mutation updateEntityStatus($entityId: ID!, $status: String!) {
    updateEntityStatus(id: $entityId, status: $status) {
      id
      status
    }
  }
`;

const updateEntitySocialMediaUrlsMutation: DocumentNode = gql`
  mutation updateEntitySocialMediaUrls(
    $entityId: ID!
    $facebookUrl: String
    $instagramUrl: String
    $linkedInUrl: String
    $twitterUrl: String
  ) {
    updateEntitySocialMediaUrls(
      id: $entityId
      facebookUrl: $facebookUrl
      instagramUrl: $instagramUrl
      linkedInUrl: $linkedInUrl
      twitterUrl: $twitterUrl
    ) {
      facebookUrl
      id
      instagramUrl
      linkedInUrl
      twitterUrl
    }
  }
`;

export {
  assignEntityToStaffMemberMutation,
  createNoteForEntityMutation,
  retrieveEntityForEntityPageQuery,
  retrievePartnersForEntity,
  updateEntityAccountStatusMutation,
  updateEntityAddressMutation,
  updateEntityMutation,
  updateEntityPartnerMutation,
  updateEntityStatusMutation,
  updateEntitySocialMediaUrlsMutation,
};
