import { FC } from 'react';

import { currency } from 'common';
import { BizPayPrimaryButton, ErrorMessage, Flex, Grid, NumberInput, TextInput, TextInputRightSection, useForm } from 'ui';

import { entityFinancialInfoFormZodResolver } from './EntityFinancialInfoForm.helpers';
import { EntityFinancialInfoFormData, EntityFinancialInfoFormProps } from './EntityFinancialInfoForm.types';

const EntityFinancialInfoForm: FC<EntityFinancialInfoFormProps<EntityFinancialInfoFormData>> = ({
  isLoading,
  isReadOnly,
  isSubmitButtonDisabled,
  onSubmit,
  values,
}) => {
  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<EntityFinancialInfoFormData>({
    mode: 'onChange',
    resolver: entityFinancialInfoFormZodResolver(),
    values,
  });

  const {
    approvedFacilityLimit: approvedFacilityLimitError,
    currentFacilityLimit: currentFacilityLimitError,
    dscr: dscrError,
    provision: provisionError,
  } = errors;

  const dscr = watch('dscr');

  return (
    <form id="entity-financial-info-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('currentFacilityLimit')}
            error={currentFacilityLimitError ? <ErrorMessage message={currentFacilityLimitError.message} /> : null}
            icon="$"
            label="Current facility limit"
            readOnly={isReadOnly}
            rightSection={<TextInputRightSection error={currentFacilityLimitError} value={watch('currentFacilityLimit')} />}
            size="md"
            onBlur={({ target: { value } }) => {
              const updatedCurrentFacilityLimit = currency(value);

              setValue(
                'currentFacilityLimit',
                updatedCurrentFacilityLimit.format({
                  symbol: '',
                }),
                {
                  shouldDirty: true,
                },
              );

              trigger();
            }}
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('approvedFacilityLimit')}
            error={approvedFacilityLimitError ? <ErrorMessage message={approvedFacilityLimitError.message} /> : null}
            icon="$"
            label="Approved facility limit"
            readOnly={isReadOnly}
            rightSection={<TextInputRightSection error={approvedFacilityLimitError} value={watch('approvedFacilityLimit')} />}
            size="md"
            onBlur={({ target: { value } }) => {
              const updatedApprovedFacilityLimit = currency(value);

              setValue(
                'approvedFacilityLimit',
                updatedApprovedFacilityLimit.format({
                  symbol: '',
                }),
                {
                  shouldDirty: true,
                },
              );

              trigger();
            }}
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <NumberInput
            {...(register('dscr'),
            {
              type: 'number',
            })}
            error={dscrError ? <ErrorMessage message={dscrError.message} /> : null}
            label="DSCR"
            precision={2}
            size="md"
            step={0.01}
            value={dscr ? dscr : undefined}
            onChange={(value) => {
              const actualValue = value.toString() === '' ? null : Number(value);

              setValue('dscr', actualValue, {
                shouldDirty: true,
              });

              trigger('dscr');
            }}
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('provision')}
            error={provisionError ? <ErrorMessage message={provisionError.message} /> : null}
            icon="$"
            label="Provision"
            readOnly={isReadOnly}
            rightSection={<TextInputRightSection error={provisionError} value={watch('provision')} />}
            size="md"
            onBlur={({ target: { value } }) => {
              const updatedProvision = value
                ? currency(value).format({
                    symbol: '',
                  })
                : null;

              setValue('provision', updatedProvision, {
                shouldDirty: true,
              });

              trigger();
            }}
          />
        </Grid.Col>
      </Grid>

      {!isSubmitButtonDisabled && (
        <Flex justify="end" mt="xl">
          <BizPayPrimaryButton disabled={!isDirty || isLoading || isSubmitButtonDisabled || !isValid} type="submit">
            Save changes
          </BizPayPrimaryButton>
        </Flex>
      )}
    </form>
  );
};

export { EntityFinancialInfoForm };
