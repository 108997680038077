import { BIZPAY_COMMON_CONSTANTS, dayjs } from 'common';

const isDateTodaysDate = (dateString: string) => {
  const { dateFormat } = BIZPAY_COMMON_CONSTANTS;

  const dateOfLatestDisbursementsFile = dayjs(dateString).format(dateFormat);
  const today = dayjs().format(dateFormat);

  return dateOfLatestDisbursementsFile === today;
};

export { isDateTodaysDate };
