import { FC } from 'react';

import { Loader, Text } from '@mantine/core';

import { BizPayLoaderProps } from './BizPayLoader.types';

const BizPayLoader: FC<BizPayLoaderProps> = ({ message }) => {
  return (
    <>
      <Loader mt="md" variant="dots" />
      <Text mt="md">{message}</Text>
    </>
  );
};

export { BizPayLoader };
