import { FC } from 'react';

import { BizPayBadge } from '../BizPayBadge';

import { LOAN_STATUS_TO_COLOR_MAP } from './LoanStatusBadge.constants';
import { LoanStatusBadgeProps } from './LoanStatusBadge.types';

const LoanStatusBadge: FC<LoanStatusBadgeProps> = ({ status }) => {
  return (
    <BizPayBadge
      sx={({ black }) => ({
        backgroundColor: LOAN_STATUS_TO_COLOR_MAP[status],
        color: black,
        fontWeight: 'normal',
      })}
    >
      {status}
    </BizPayBadge>
  );
};

export { LoanStatusBadge };
