import { useCookie } from '../useCookie';
import { useJwtDecoder } from '../useJwtDecoder';

import { LoggedInUserData, UseAuthentication } from './useAuthentication.types';

const useAuthentication = (): UseAuthentication => {
  const { getAccessTokenCookie } = useCookie();

  const { getDecodedJwt, hasJwtExpired } = useJwtDecoder<LoggedInUserData>();

  const getLoggedInUserData = () => {
    const accessToken = getAccessTokenCookie();

    if (!accessToken) {
      return undefined;
    }

    let loggedInUserData: LoggedInUserData;

    try {
      loggedInUserData = getDecodedJwt(accessToken);
    } catch {
      // Invalid token
      return undefined;
    }

    if (hasJwtExpired(loggedInUserData)) {
      // TODO: Use the refresh token to get a new tokens
      return undefined;
    }

    return loggedInUserData;
  };

  const getIsAuthenticated = () => {
    return !!getLoggedInUserData();
  };

  const getStaffMemberDisplayName = (staffMemberFullName: string, staffMemberId: string) => {
    const loggedInUserData = getLoggedInUserData();

    return `${staffMemberFullName}${loggedInUserData?.userId === staffMemberId ? ' (Me)' : ''}`;
  };

  return {
    getIsAuthenticated,
    getStaffMemberDisplayName,
    getLoggedInUserData,
  };
};

export { useAuthentication };
