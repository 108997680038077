import { FC } from 'react';

import { BizPayPrimaryButton, ErrorMessage, Grid, Group, Stack, TextInput, TextInputRightSection, useForm } from 'ui';

import { supplierBankAccountFormZodResolver } from './SupplierBankAccountForm.helpers';
import { SupplierBankAccountFormData, SupplierBankAccountFormProps } from './SupplierBankAccountForm.types';

const SupplierBankAccountForm: FC<SupplierBankAccountFormProps<SupplierBankAccountFormData>> = ({ onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<SupplierBankAccountFormData>({
    mode: 'onChange',
    resolver: supplierBankAccountFormZodResolver(),
  });

  const { accountName: accountNameError, accountNumber: accountNumberError, bsb: bsbError } = errors;

  return (
    <form
      id="supplier-bank-account-form"
      style={{
        height: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack h="100%" justify="center" w="100%">
        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              {...register('accountName')}
              error={accountNameError ? <ErrorMessage message={accountNameError.message} /> : null}
              label="Account name"
              rightSection={<TextInputRightSection error={accountNameError} value={watch('accountName')} />}
              size="md"
            />
          </Grid.Col>
        </Grid>

        <Grid gutter="sm">
          <Grid.Col span="auto">
            <TextInput
              {...register('bsb')}
              error={bsbError ? <ErrorMessage message={bsbError.message} /> : null}
              label="BSB"
              rightSection={<TextInputRightSection error={bsbError} value={watch('bsb')} />}
              size="md"
            />
          </Grid.Col>

          <Grid.Col span="auto">
            <TextInput
              {...register('accountNumber')}
              error={accountNumberError ? <ErrorMessage message={accountNumberError.message} /> : null}
              label="Account number"
              rightSection={<TextInputRightSection error={accountNumberError} value={watch('accountNumber')} />}
              size="md"
            />
          </Grid.Col>
        </Grid>

        <Group mt="md" position="right" w="100%">
          <BizPayPrimaryButton disabled={!isValid} type="submit">
            Create
          </BizPayPrimaryButton>
        </Group>
      </Stack>
    </form>
  );
};

export { SupplierBankAccountForm };
