import { FC } from 'react';

import { Flex, Footer, Group, Text } from '@mantine/core';
import { dayjs } from 'common';

import { BizPayFooterProps } from './BizPayFooter.types';

const BizPayFooter: FC<BizPayFooterProps> = ({ appVersion, height = 60 }) => {
  return (
    <Footer
      height={height}
      id="bizpay-footer"
      p="md"
      sx={({ colors }) => ({
        backgroundColor: colors['bizpay-dark-blue-gray'][7],
      })}
    >
      <Group align="center" h="100%" position="center" w="100%" noWrap>
        <Flex align="center" justify="flex-start" w="100%" />
        <Flex w="100%">
          <Text
            sx={({ white }) => ({
              color: white,
              whiteSpace: 'nowrap',
            })}
          >
            &copy; {dayjs().toDate().getFullYear()} Copyright BizPay Group Limited. ACN 633 797 627. All rights reserved.
          </Text>
        </Flex>
        <Flex align="center" justify="flex-end" w="100%">
          {appVersion && (
            <Text
              sx={({ white }) => ({
                color: white,
                fontSize: '0.5rem',
              })}
            >
              Version: {appVersion}
            </Text>
          )}
        </Flex>
      </Group>
    </Footer>
  );
};

export { BizPayFooter };
