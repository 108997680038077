import { FC } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayAdminPortalDynamicNextJsTitle, Flex, FullHeightContainer, FullPageLayout, PageHeading } from 'ui';

import { StaffMembersTable } from '../StaffMembersTable';

import { useNavigationRoutes } from '../../hooks';

const StaffMembersPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <FullPageLayout
        mainComponent={
          <FullHeightContainer>
            <StaffMembersTable
              paginationOptions={{
                isPaginationEnabled: true,
              }}
            />
          </FullHeightContainer>
        }
        pageHeadingComponent={
          <Flex w="100%">
            <PageHeading heading={title} size="h4" />
          </Flex>
        }
      />
    </>
  );
};

export { StaffMembersPage };
