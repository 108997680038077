import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import { useRetrieveUserForEntityPageUserInfoTabLazyQuery, useUpdateUserForEntityMutation } from 'graphql-library';
import { BizPayLoader, Stack, useBizPayNotification, UserProfileForm } from 'ui';

import { EntityUserInfoFormProps, EntityUserInfoUser } from './EntityUserInfoForm.types';

import { useSignOut } from '../../hooks';

const EntityUserInfoForm: FC<EntityUserInfoFormProps> = ({ entityId }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [user, setUser] = useState<EntityUserInfoUser>();

  const [executeRetrieveUserForEntityPageUserInfoTabQuery, { loading: isRetrieveUserForEntityPageUserInfoTabLazyQueryLoading }] =
    useRetrieveUserForEntityPageUserInfoTabLazyQuery({
      onCompleted: ({ retrieveUserForEntity: returnedUser }) => {
        setUser(returnedUser);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve user',
        });
      },
    });

  const [executeUpdateUserMutation, { loading: isUpdateUserLoading }] = useUpdateUserForEntityMutation({
    onCompleted: ({ updateUserForStaffMember: returnedUser }) => {
      setUser(returnedUser);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update user',
      });
    },
  });

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrieveUserForEntityPageUserInfoTabLazyQueryLoading || isUpdateUserLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveUserForEntityPageUserInfoTabQuery({
      variables: {
        entityId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      {isLoading ? (
        <Stack align="center" h="100%" justify="center">
          {isRetrieveUserForEntityPageUserInfoTabLazyQueryLoading && <BizPayLoader message="Retrieving user info, please wait..." />}

          {isUpdateUserLoading && <BizPayLoader message="Updating user, please wait..." />}
        </Stack>
      ) : (
        <>
          {user && (
            <UserProfileForm
              initialDefaultValues={{
                email: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                mobileNumber: user.mobileNumber ?? '',
              }}
              isLoading={isUpdateUserLoading}
              onSubmit={({ firstName, lastName, mobileNumber }) => {
                if (!getIsAuthenticated) {
                  signOut();
                  return;
                }

                executeUpdateUserMutation({
                  variables: {
                    id: user.id,
                    input: {
                      firstName,
                      lastName,
                      mobileNumber,
                    },
                  },
                });
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export { EntityUserInfoForm };
