import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication } from 'common';
import { useRetrieveNotesForEntityLazyQuery } from 'graphql-library';
import { BizPayLoader, MessageBox, ScrollArea, Stack, useBizPayNotification } from 'ui';

import { EntityNote, EntityNotesProps } from './EntityNotes.types';

import { useSignOut } from '../../hooks';

const EntityNotes: FC<EntityNotesProps> = ({ entityId, refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData } }) => {
  const { displayErrorNotification } = useBizPayNotification();
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();

  const [notesForEntity, setNotesForEntity] = useState<EntityNote[]>([]);

  const [executeRetrieveNotesForEntityQuery, { loading: isRetrieveNotesForEntityLoading, refetch }] = useRetrieveNotesForEntityLazyQuery({
    onCompleted: ({ retrieveNotesForEntity: returnNoteForEntity }) => {
      setNotesForEntity(returnNoteForEntity);
      if (onRefetchDataCompleted) {
        onRefetchDataCompleted();
      }
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve the notes for this loan application',
      });
    },
  });

  const isAuthenticated = getIsAuthenticated();
  const totalNotesForEntity = notesForEntity.length;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveNotesForEntityQuery({
      variables: {
        entityId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  return (
    <>
      {isRetrieveNotesForEntityLoading && (
        <Stack align="center" h="100%" justify="center">
          <BizPayLoader message="Retrieving the notes for this loan application" />
        </Stack>
      )}

      <ScrollArea h={205} mt="xl">
        {notesForEntity.map(({ createdAtUtc, id, message, submittedByStaffMember: { fullName } }, index) => {
          return (
            <MessageBox
              key={`note-${id}`}
              author={fullName}
              cardProps={{
                mb: index !== totalNotesForEntity - 1 ? 'md' : undefined,
              }}
              text={message}
              timestamp={dayjs(createdAtUtc).toDate().toLocaleString()}
            />
          );
        })}
      </ScrollArea>
    </>
  );
};

export { EntityNotes };
