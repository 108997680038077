import { LoanApplicationAdminStatus } from 'graphql-library';

const ALL_LOAN_APPLICATION_ADMIN_STATUSES = Object.keys(LoanApplicationAdminStatus) as LoanApplicationAdminStatus[];

const ALL_SELECTABLE_LOAN_APPLICATION_ADMIN_STATUSES = ALL_LOAN_APPLICATION_ADMIN_STATUSES.filter(
  (loanApplicationAdminStatus) =>
    loanApplicationAdminStatus !== LoanApplicationAdminStatus.AwaitingDisbursement &&
    loanApplicationAdminStatus !== LoanApplicationAdminStatus.Disbursed,
);

const LOAN_APPLICATION_ADMIN_STATUS_FORM_CONSTANTS = Object.freeze({
  selectedLoanApplicationAdminStatusToLoanApplicationAdminStatusesMap: Object.freeze<
    Record<LoanApplicationAdminStatus, LoanApplicationAdminStatus[]>
  >({
    Approved: ALL_SELECTABLE_LOAN_APPLICATION_ADMIN_STATUSES,
    AwaitingDisbursement: [LoanApplicationAdminStatus.AwaitingDisbursement],
    AwaitingInfoFromClient: ALL_SELECTABLE_LOAN_APPLICATION_ADMIN_STATUSES,
    Declined: [LoanApplicationAdminStatus.Declined],
    Disbursed: [],
    ReadyForReview: [
      LoanApplicationAdminStatus.Declined,
      LoanApplicationAdminStatus.ReadyForReview,
      LoanApplicationAdminStatus.UnderReview,
      LoanApplicationAdminStatus.Withdrawn,
    ],
    UnderReview: ALL_SELECTABLE_LOAN_APPLICATION_ADMIN_STATUSES,
    Withdrawn: [LoanApplicationAdminStatus.ReadyForReview, LoanApplicationAdminStatus.Withdrawn],
  }),
});

const LOAN_APPLICATION_ADMIN_STATUS_TO_COLOR_MAP = Object.freeze<Record<LoanApplicationAdminStatus, string>>({
  Approved: '#c7ffd3',
  AwaitingDisbursement: '#c7ffd3',
  AwaitingInfoFromClient: '#f3d7ff',
  Declined: '#ffc7c7',
  Disbursed: '#c7ffd3',
  ReadyForReview: '#fff9c0',
  UnderReview: '#c1fffc',
  Withdrawn: '#d5d1d1',
});

export { LOAN_APPLICATION_ADMIN_STATUS_FORM_CONSTANTS, LOAN_APPLICATION_ADMIN_STATUS_TO_COLOR_MAP };
