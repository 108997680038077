import { FC } from 'react';

import { AppShell } from '@mantine/core';
import { AnimatePresence, motion } from 'framer-motion';

import { BizPayFooter } from '../BizPayFooter';
import { BizPayHeader } from '../BizPayHeader';

import { BizPayAppLayoutProps } from './BizPayAppLayout.types';

const BizPayAppLayout: FC<BizPayAppLayoutProps> = ({
  appVersion,
  children,
  headerLogoUrl,
  headerRightSectionComponent,
  logoBadgeComponent,
  navBarComponent,
  refreshAppButtonComponent,
}: BizPayAppLayoutProps) => {
  return (
    <AppShell
      footer={<BizPayFooter appVersion={appVersion} />}
      header={
        <BizPayHeader
          logoBadgeComponent={logoBadgeComponent}
          logoUrl={headerLogoUrl}
          refreshAppButtonComponent={refreshAppButtonComponent}
          rightSectionComponent={headerRightSectionComponent}
        />
      }
      navbar={navBarComponent}
    >
      <AnimatePresence mode="wait" onExitComplete={() => window.scrollTo(0, 0)}>
        <motion.div
          animate="animate"
          exit="exit"
          id="motion-div-component"
          initial="initial"
          style={{
            height: '100%',
          }}
          transition={{
            ease: 'anticipate',
            type: 'keyframes',
          }}
          variants={{
            animate: {
              opacity: 1,
              x: 0,
            },
            exit: {
              opacity: 0,
              x: 0,
            },
            initial: {
              opacity: 0,
              x: -200,
            },
          }}
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </AppShell>
  );
};

export { BizPayAppLayout };
