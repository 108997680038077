import { FC } from 'react';

import { Grid, TextInput } from 'ui';

import { LoanInfoAdditionalDetailsFormData } from './LoanInfoAdditionalDetailsForm.types';

const LoanInfoAdditionalDetailsForm: FC<LoanInfoAdditionalDetailsFormData> = ({ funder, funderException, isDoubtfulDebt, provision }) => {
  return (
    <form id="loan-info-additional-details-form">
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput label="Doubtful debt" size="md" value={isDoubtfulDebt ? 'Yes' : 'No'} readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput label="Provision" size="md" value={provision ?? '-'} readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput label="Funder" size="md" value={funder ?? '-'} readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput label="Funder exception" size="md" value={funderException ?? '-'} readOnly />
        </Grid.Col>
      </Grid>
    </form>
  );
};

export { LoanInfoAdditionalDetailsForm };
