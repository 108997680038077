import { FC } from 'react';

import { Anchor, Flex, Group, Header } from '@mantine/core';

import { BizPayLogo } from '../BizPayLogo';

import { BIZPAY_HEADER_CONSTANTS } from './BizPayHeader.constants';
import { BizPayHeaderProps } from './BizPayHeader.types';

const {
  defaults: { heightInPixels, marginInPixels },
  logo,
} = BIZPAY_HEADER_CONSTANTS;

const BizPayHeader: FC<BizPayHeaderProps> = ({
  heightInPx = heightInPixels,
  logoBadgeComponent,
  logoUrl,
  refreshAppButtonComponent,
  rightSectionComponent,
}) => {
  const logoComponent = <BizPayLogo width={logo.width} />;

  return (
    <Header height={heightInPx} id="bizpay-header">
      <Group align="center" h="100%" w="100%" noWrap>
        <Flex ml={marginInPixels.left} mt={10} w="100%">
          {logoUrl ? <Anchor href={logoUrl}>{logoComponent}</Anchor> : logoComponent}
          {logoBadgeComponent}
        </Flex>

        {refreshAppButtonComponent && (
          <Flex justify="center" w="100%">
            {refreshAppButtonComponent}
          </Flex>
        )}

        {rightSectionComponent && (
          <Flex justify="flex-end" mr={marginInPixels.right} w="100%">
            {rightSectionComponent}
          </Flex>
        )}
      </Group>
    </Header>
  );
};

export { BizPayHeader };
