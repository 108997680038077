import { FC } from 'react';

import { Tooltip } from '@mantine/core';

import { BizPayTooltipProps } from './BizPayTooltip.types';

const BizPayTooltip: FC<BizPayTooltipProps> = ({ children, position = 'left', ...otherProps }) => {
  return (
    <Tooltip
      arrowSize={8}
      openDelay={200}
      position={position}
      radius="sm"
      transitionProps={{
        duration: 300,
        transition: 'fade',
      }}
      keepMounted
      withArrow
      {...otherProps}
    >
      {children}
    </Tooltip>
  );
};

export { BizPayTooltip };
