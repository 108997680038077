const BIZPAY_COMMON_CONSTANTS = Object.freeze({
  appName: {
    adminPortal: 'Admin Portal',
    customerPortal: 'Customer Portal',
  },
  companyName: 'BizPay',
  dateFormat: 'DD/MM/YYYY',
});

export { BIZPAY_COMMON_CONSTANTS };
