import { FC } from 'react';

import { Button } from '@mantine/core';

import { BizPayOutlinedButtonProps } from './BizPayOutlinedButton.types';

const BizPayOutlinedButton: FC<BizPayOutlinedButtonProps> = ({ children, ...otherProps }) => {
  return (
    <Button
      size="md"
      styles={({ colors }) => ({
        root: {
          border: `0.075rem solid ${colors['bizpay-light-gray'][7]}`,
          color: colors['bizpay-light-gray'][7],
          fontWeight: 'bold',

          '&:hover': {
            backgroundColor: colors['bizpay-light-gray'][1],
          },
        },
      })}
      type="button"
      variant="outline"
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export { BizPayOutlinedButton };
