import { FC } from 'react';

import { NavLink, Tooltip } from '@mantine/core';

import { BizPayNavigationLinkProps } from './BizPayNavigationLink.types';

const BizPayNavigationLink: FC<BizPayNavigationLinkProps> = ({
  hoverBackgroundColor = 'bizpay-dark-blue-gray.0',
  icon,
  isActive = false,
  label,
  linkOptions: { href, onClick, target },
}) => {
  return (
    <Tooltip key={label} label={label} disabled>
      <NavLink
        active={isActive}
        component={href ? 'a' : 'button'}
        href={href ? href : undefined}
        icon={icon}
        label={label}
        sx={({ colors }) => {
          const [color, colorIndex] = hoverBackgroundColor.split('.');

          const actualHoverBackgroundColor = colorIndex ? colors[color][Number(colorIndex)] : colors[color];

          return {
            borderColor: 'transparent',
            borderStyle: 'solid',
            borderWidth: 1,

            '&[data-active]': {
              backgroundColor: colors['bizpay-dark-blue-gray'][1],
              textDecoration: 'underline',
            },

            '&[data-active]:hover': {
              backgroundColor: colors['bizpay-dark-blue-gray'][1],
              borderColor: colors['bizpay-dark-blue-gray'][2],
            },

            '&:hover': {
              backgroundColor: actualHoverBackgroundColor,
              borderColor: colors['bizpay-light-gray'][2],
            },
          };
        }}
        target={target}
        onClick={onClick}
      />
    </Tooltip>
  );
};

export { BizPayNavigationLink };
