import { FC } from 'react';

import { Grid, TextInput, useForm } from 'ui';

import { LoanApplicationDetailsFormData, LoanApplicationDetailsFormProps } from './LoanApplicationDetailsForm.types';

const LoanApplicationDetailsForm: FC<LoanApplicationDetailsFormProps<LoanApplicationDetailsFormData>> = ({
  entityActionIcon,
  initialDefaultValues,
}) => {
  const { register } = useForm<LoanApplicationDetailsFormData>({
    defaultValues: initialDefaultValues,
  });

  return (
    <form id="loan-application-details-form">
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('entityName')} label="Entity name" rightSection={entityActionIcon} size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('dateCreated')} label="Date created" size="md" readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('createdByUser')} label="Created by" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('dateSubmitted')} label="Date submitted" size="md" readOnly />
        </Grid.Col>
      </Grid>
    </form>
  );
};

export { LoanApplicationDetailsForm };
