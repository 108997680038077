import { FC, useEffect, useState } from 'react';

import { currency, dayjs, kebabCase, sentenceCase, useAuthentication, useNumberFormatter } from 'common';
import {
  LoanApplicationAdminStatus,
  ProductFeeType,
  useCreateNoteForLoanApplicationMutation,
  useProductHelpers,
  useRetrieveLoanApplicationForLoanApplicationPageLazyQuery,
  useUpdateInvoiceDetailsForLoanApplicationByStaffMemberMutation,
  useUpdateLoanApplicationSupplierBankAccountIdMutation,
} from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  Accordion,
  Anchor,
  BaseContainer,
  BizPayAdminPortalDynamicNextJsTitle,
  BizPayLoader,
  BizPayPrimaryButton,
  Breadcrumbs,
  CreateNoteForm,
  DefaultPageLayout,
  Divider,
  EntityActionIcon,
  FullHeightContainer,
  Group,
  PageHeading,
  Stack,
  Tabs,
  TabsValue,
  Text,
  useBizPayNotification,
} from 'ui';

import { generateEntityPageRoute } from '../EntityPage';
import { LoanApplicationActions } from '../LoanApplicationActions';
import { LoanApplicationAuditLogsTable } from '../LoanApplicationAuditLogsTable';
import { LoanApplicationDetailsForm } from '../LoanApplicationDetailsForm';
import { LoanApplicationDirectDebitAccountForm } from '../LoanApplicationDirectDebitAccountForm';
import {
  LoanApplicationInvoiceDetailsForm,
  LoanApplicationInvoiceDetailsFormData,
  LoanApplicationInvoiceDetailsFormProps,
} from '../LoanApplicationInvoiceDetailsForm';
import { LoanApplicationNotes } from '../LoanApplicationNotes';
import { LoanApplicationProductForm } from '../LoanApplicationProductForm';

import { LOAN_APPLICATION_PAGE_CONSTANTS } from './LoanApplicationPage.constants';
import {
  LoanApplicationPageLoanApplication,
  LoanApplicationPageLoanApplicationInvoice,
  LoanApplicationPageLoanApplicationInvoiceSupplierBankAccount,
  LoanApplicationPageProps,
} from './LoanApplicationPage.types';

import { ProtectedRoute, useNavigationRoutes, useSignOut } from '../../hooks';

const { tabs } = LOAN_APPLICATION_PAGE_CONSTANTS;

const LoanApplicationPage: FC<LoanApplicationPageProps> = ({ id }) => {
  const { getIsAuthenticated, getLoggedInUserData } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { findPageByRoute } = useNavigationRoutes();
  const { formatCurrency, formatPercentage } = useNumberFormatter();
  const { calculateFeeAmountInCents, calculateLoanRepaymentAmountInCents } = useProductHelpers();
  const {
    push,
    query: { returnTab, tab },
    route,
  } = useRouter();
  const { signOut } = useSignOut();

  const [invoice, setInvoice] = useState<LoanApplicationPageLoanApplicationInvoice>();
  const [loanApplication, setLoanApplication] = useState<LoanApplicationPageLoanApplication>();
  const [openAccordionItemValues, setOpenAccordionItemValues] = useState<string[]>(['actions', 'notes']);
  const [shouldRefetchAuditLogs, setShouldRefetchAuditLogs] = useState<boolean>(false);
  const [shouldRefetchNotes, setShouldRefetchNotes] = useState<boolean>(false);
  const [supplierBankAccount, setSupplierBankAccount] = useState<LoanApplicationPageLoanApplicationInvoiceSupplierBankAccount>();
  const [supplierBankAccountId, setSupplierBankAccountId] = useState<LoanApplicationPageLoanApplication['supplierBankAccountId']>();
  const [tabIdValue, setTabIdValue] = useState<TabsValue>(tabs.config[0].id);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const [executeCreateNoteForLoanApplicationMutation, { loading: isCreateNoteForLoanApplicationLoading }] =
    useCreateNoteForLoanApplicationMutation({
      onCompleted: () => {
        setShouldRefetchNotes(true);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to create a note for this loan application',
        });
      },
    });

  const [executeRetrieveLoanApplicationForLoanApplicationPageQuery, { loading: isRetrieveLoanApplicationLoading }] =
    useRetrieveLoanApplicationForLoanApplicationPageLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrieveLoanApplication: returnedLoanApplication }) => {
        const { invoice, supplierBankAccountId } = returnedLoanApplication;

        // Using the as assertion here because we know that an invoice needs to exist
        const actualInvoice = invoice as LoanApplicationPageLoanApplicationInvoice;

        const actualSupplierBankAccount =
          actualInvoice.supplier?.supplierBankAccounts?.find(({ id }) => id === supplierBankAccountId) ??
          actualInvoice.supplier?.supplierBankAccounts?.[0];

        setInvoice(actualInvoice);

        setLoanApplication(returnedLoanApplication);
        setSupplierBankAccount(actualSupplierBankAccount);
        setSupplierBankAccountId(actualSupplierBankAccount?.id);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve loan application',
        });
      },
    });

  const [
    executeUpdateInvoiceDetailsForLoanApplicationByStaffMemberMutation,
    { loading: isUpdateInvoiceDetailsForLoanApplicationByStaffMemberLoading },
  ] = useUpdateInvoiceDetailsForLoanApplicationByStaffMemberMutation({
    onCompleted: ({ updateInvoiceDetailsForLoanApplicationByStaffMember: returnedInvoice }) => {
      setInvoice(returnedInvoice);
      setShouldRefetchAuditLogs(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the invoice details',
      });
    },
  });

  const [executeUpdateLoanApplicationSupplierBankAccountIdMutation, { loading: isUpdateLoanApplicationSupplierBankAccountIdLoading }] =
    useUpdateLoanApplicationSupplierBankAccountIdMutation({
      onCompleted: ({ updateLoanApplicationSupplierBankAccountId: returnedSupplierBankAccount }) => {
        setSupplierBankAccountId(returnedSupplierBankAccount.supplierBankAccountId);
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message,
        });
        setSupplierBankAccountId(supplierBankAccount?.id);
      },
    });

  const handleAccordionItemOpenClose = (accordionItemValue: string) => {
    setOpenAccordionItemValues((previousOpenAccordionItemValues) => {
      return previousOpenAccordionItemValues.includes(accordionItemValue)
        ? previousOpenAccordionItemValues.filter((previousOpenAccordionItemValue) => previousOpenAccordionItemValue !== accordionItemValue)
        : [...previousOpenAccordionItemValues, accordionItemValue];
    });
  };

  const handleBreadcrumbItemClick = (route: string) => {
    push(route);
  };

  const handleInvoiceAmountChange = (invoiceAmount: string) => {
    setInvoice((previousInvoice) => {
      if (!previousInvoice) {
        return;
      }

      return {
        ...previousInvoice,
        totalAmountInCents: currency(invoiceAmount).intValue,
      };
    });
  };

  const handleLoanApplicationAdminStatusUpdated = (
    adminStatus: LoanApplicationPageLoanApplication['adminStatus'],
    assignedToStaffMemberId: LoanApplicationPageLoanApplication['assignedToStaffMemberId'],
  ) => {
    setLoanApplication((previousLoanApplication) => {
      if (!previousLoanApplication) {
        return;
      }

      return {
        ...previousLoanApplication,
        adminStatus,
        assignedToStaffMemberId,
      };
    });

    setShouldRefetchAuditLogs(true);
  };

  const handleLoanApplicationAssignedToStaffMemberUpdated = (
    assignedToStaffMemberId: LoanApplicationPageLoanApplication['assignedToStaffMemberId'],
  ) => {
    setLoanApplication((previousLoanApplication) => {
      if (!previousLoanApplication) {
        return;
      }

      return {
        ...previousLoanApplication,
        assignedToStaffMemberId,
      };
    });

    setShouldRefetchAuditLogs(true);
  };

  const handleRefetchDataCompleted = () => {
    setShouldRefetchAuditLogs(false);
    setShouldRefetchNotes(false);
  };

  const handleSupplierBankAccountChange: LoanApplicationInvoiceDetailsFormProps<LoanApplicationInvoiceDetailsFormData>['onSupplierBankAccountChange'] =
    (supplierBankAccountId) => {
      setSupplierBankAccountId(supplierBankAccountId);
    };

  const handleTabChange = (value: TabsValue) => {
    setTabIdValue(value);
    setTabIndex(tabs.config.findIndex(({ id }) => id === value));

    push(`${route.replace('[id]', id)}?tab=${value}&returnTab=${returnTab}`, undefined, {
      shallow: true,
    });
  };

  const isAuthenticated = getIsAuthenticated();
  const { title } = findPageByRoute(route);

  useEffect(() => {
    if (!tab) {
      handleTabChange(tabs.config[0].id);
      return;
    }

    handleTabChange(String(tab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLoanApplicationForLoanApplicationPageQuery({
      variables: {
        id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const loggedInUserData = getLoggedInUserData();

  const isReadyForAdmin = !!loanApplication?.adminStatus;

  const isFormReadOnly =
    loanApplication?.adminStatus !== LoanApplicationAdminStatus.UnderReview &&
    loanApplication?.assignedToStaffMemberId !== loggedInUserData?.userId;

  const isLoading =
    isCreateNoteForLoanApplicationLoading ||
    isRetrieveLoanApplicationLoading ||
    isUpdateInvoiceDetailsForLoanApplicationByStaffMemberLoading ||
    isUpdateLoanApplicationSupplierBankAccountIdLoading;

  const leftAccordionContainerPercentageWidth = isReadyForAdmin ? 70 : 100;
  const rightAccordionContainerPercentageWidth = isReadyForAdmin ? 100 - leftAccordionContainerPercentageWidth : undefined;

  return (
    <>
      {isLoading ? (
        <Stack align="center" h="100%" justify="center">
          {isCreateNoteForLoanApplicationLoading && <BizPayLoader message="Creating a note for this loan application, please wait..." />}

          {isRetrieveLoanApplicationLoading && <BizPayLoader message="Retrieving your loan application, please wait..." />}

          {isUpdateInvoiceDetailsForLoanApplicationByStaffMemberLoading && (
            <BizPayLoader message="Updating invoice details for this loan application, please wait..." />
          )}

          {isUpdateLoanApplicationSupplierBankAccountIdLoading && (
            <BizPayLoader message="Updating supplier bank account for this loan application, please wait..." />
          )}
        </Stack>
      ) : (
        <>
          {loanApplication && (
            <>
              <BizPayAdminPortalDynamicNextJsTitle
                headComponent={Head}
                pageTitle={`${title} - Loan application id: ${loanApplication.niceId}`}
              />

              <DefaultPageLayout
                mainComponent={
                  <FullHeightContainer>
                    <Tabs
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                      }}
                      value={tabIdValue}
                      onTabChange={handleTabChange}
                    >
                      <Tabs.List>
                        {tabs.config.map(({ id, title }) => {
                          return (
                            <Tabs.Tab key={`tab-${id}`} value={id}>
                              {title}
                            </Tabs.Tab>
                          );
                        })}
                      </Tabs.List>

                      <Tabs.Panel
                        pt="xl"
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                        value={tabs.config[tabIndex].id}
                      >
                        {tabs.config[tabIndex].id === tabs.ids.loanApplication && (
                          <Stack>
                            <Group align="flex-start" noWrap>
                              <Stack w={`${leftAccordionContainerPercentageWidth}%`}>
                                <BaseContainer>
                                  <Stack>
                                    <Group noWrap>
                                      <Text w="100%" weight="bold">
                                        Customer details
                                      </Text>

                                      {invoice && (
                                        <Anchor href={invoice.document.signedUrl} target="_blank">
                                          <BizPayPrimaryButton text-align="right">Open invoice</BizPayPrimaryButton>
                                        </Anchor>
                                      )}
                                    </Group>

                                    <Divider mb="md" mt="0.25rem" />

                                    <LoanApplicationDetailsForm
                                      entityActionIcon={
                                        <EntityActionIcon
                                          onClick={() => {
                                            window.open(generateEntityPageRoute(loanApplication.entity.id));
                                          }}
                                        />
                                      }
                                      initialDefaultValues={{
                                        createdByUser: loanApplication.createdByUser.fullName,
                                        dateCreated: dayjs(loanApplication.createdAtUtc).toDate().toLocaleString(),
                                        dateSubmitted: loanApplication.submittedAtUtc
                                          ? dayjs(loanApplication.submittedAtUtc).toDate().toLocaleString()
                                          : '-',
                                        entityName: loanApplication.entity.name,
                                      }}
                                    />
                                  </Stack>

                                  <Stack mt="md">
                                    <Text weight="bold">Supplier details</Text>

                                    <Divider mb="md" mt="0.25rem" />

                                    {invoice && invoice.supplier && isReadyForAdmin ? (
                                      <LoanApplicationInvoiceDetailsForm
                                        hasInvoiceSupplierBankAccountChanged={supplierBankAccount?.id !== supplierBankAccountId}
                                        isFormReadOnly={isFormReadOnly}
                                        loanApplication={loanApplication}
                                        supplierId={invoice.supplier.id}
                                        values={{
                                          invoiceAmount: invoice.totalAmountInCents
                                            ? currency(invoice.totalAmountInCents / 100).format({
                                                symbol: '',
                                              })
                                            : '',
                                          invoiceDateDueAtUtc: invoice.dateDueAtUtc ? dayjs(invoice.dateDueAtUtc).toDate() : '',
                                          invoiceDateIssuedAtUtc: invoice.dateIssuedAtUtc ? dayjs(invoice.dateIssuedAtUtc).toDate() : '',
                                          invoiceNumber: invoice.number ?? '',
                                          invoicePaymentReference: invoice.paymentReference ?? '',
                                          supplierAbn: invoice.supplier.abn ?? '',
                                          supplierBankAccountId: supplierBankAccountId ?? '',
                                          supplierEmail: invoice.supplier.email ?? '',
                                          supplierPhone: invoice.supplier.phone ?? '',
                                          supplierName: invoice.supplier.name ?? '',
                                          uploadByUserFullName: invoice.uploadedByUser.fullName,
                                        }}
                                        onInvoiceAmountChange={handleInvoiceAmountChange}
                                        onSubmit={({
                                          invoiceAmount,
                                          invoiceDateDueAtUtc,
                                          invoiceDateIssuedAtUtc,
                                          invoiceNumber,
                                          invoicePaymentReference,
                                          supplierAbn,
                                          supplierBankAccountId: formSupplierBankAccountId,
                                          supplierEmail,
                                          supplierName,
                                          supplierPhone,
                                        }) => {
                                          if (!getIsAuthenticated()) {
                                            signOut();
                                            return;
                                          }

                                          const { supplier } = invoice;

                                          if (!supplier) {
                                            return;
                                          }

                                          const updatedInvoiceTotalAmountInCents = currency(invoiceAmount).intValue;
                                          const updatedInvoiceDateDueAtUtc = dayjs(invoiceDateDueAtUtc).toISOString();
                                          const updatedInvoiceDateIssuedAtUtc = dayjs(invoiceDateIssuedAtUtc).toISOString();

                                          const hasInvoiceDetailsChanged =
                                            invoice.dateDueAtUtc !== updatedInvoiceDateDueAtUtc ||
                                            invoice.dateIssuedAtUtc !== updatedInvoiceDateIssuedAtUtc ||
                                            invoice.number !== invoiceNumber ||
                                            invoice.paymentReference !== invoicePaymentReference ||
                                            invoice.totalAmountInCents !== updatedInvoiceTotalAmountInCents ||
                                            supplier.abn !== supplierAbn ||
                                            supplier.email !== supplierEmail ||
                                            supplier.name !== supplierName ||
                                            supplier.phone !== supplierPhone;

                                          if (hasInvoiceDetailsChanged) {
                                            executeUpdateInvoiceDetailsForLoanApplicationByStaffMemberMutation({
                                              variables: {
                                                invoiceDateDueAtUtc: updatedInvoiceDateDueAtUtc,
                                                invoiceDateIssuedAtUtc: updatedInvoiceDateIssuedAtUtc,
                                                invoiceId: invoice.id,
                                                invoiceNumber,
                                                invoicePaymentReference,
                                                invoiceTotalAmountInCents: updatedInvoiceTotalAmountInCents,
                                                loanApplicationId: loanApplication.id,
                                                supplierAbn,
                                                supplierEmail,
                                                supplierName,
                                                supplierPhone,
                                              },
                                            });
                                          }

                                          if (!supplierBankAccount) {
                                            return;
                                          }

                                          const hasSupplierBankAccountChanged = formSupplierBankAccountId !== supplierBankAccount.id;

                                          if (hasSupplierBankAccountChanged) {
                                            executeUpdateLoanApplicationSupplierBankAccountIdMutation({
                                              variables: {
                                                id: loanApplication.id,
                                                supplierBankAccountId: formSupplierBankAccountId,
                                              },
                                            });
                                          }
                                        }}
                                        onSupplierBankAccountChange={handleSupplierBankAccountChange}
                                      >
                                        <Stack mt="md">
                                          <Text weight="bold">Direct debit account details</Text>

                                          <Divider mb="md" mt="0.25rem" />

                                          {loanApplication.directDebitAccount ? (
                                            <LoanApplicationDirectDebitAccountForm
                                              initialDefaultValues={{
                                                accountName: loanApplication.directDebitAccount.accountName,
                                                accountNumber: loanApplication.directDebitAccount.accountNumber,
                                                bsb: loanApplication.directDebitAccount.bsb,
                                                financialInstitutionName: loanApplication.directDebitAccount.financialInstitutionName,
                                                status: sentenceCase(loanApplication.directDebitAccount.status),
                                              }}
                                            />
                                          ) : (
                                            <Text>No direct debit account selected</Text>
                                          )}
                                        </Stack>
                                        <Stack mt="md">
                                          <Text weight="bold">Product</Text>
                                          <Divider mb="md" mt="0.25rem" />
                                          {invoice && invoice.totalAmountInCents && loanApplication.product ? (
                                            <LoanApplicationProductForm
                                              initialDefaultValues={{
                                                fee:
                                                  loanApplication.product.feeType === ProductFeeType.Percentage
                                                    ? formatPercentage(loanApplication.product.feeValue)
                                                    : formatCurrency(loanApplication.product.feeValue),
                                                feeAmountInDollars: formatCurrency(
                                                  calculateFeeAmountInCents({
                                                    product: loanApplication.product,
                                                    totalInvoiceAmountInCents: invoice.totalAmountInCents,
                                                  }),
                                                ),
                                                frequencyType: loanApplication.product.frequencyType,
                                                frequencyValue: loanApplication.product.frequencyValue,
                                                name: loanApplication.product.displayName ?? loanApplication.product.name,
                                                repaymentAmountInDollars: formatCurrency(
                                                  calculateLoanRepaymentAmountInCents({
                                                    product: loanApplication.product,
                                                    totalInvoiceAmountInCents: invoice.totalAmountInCents,
                                                  }),
                                                ),
                                                invoiceAmount: invoice.totalAmountInCents,
                                                totalLoanAmountInDollars: formatCurrency(
                                                  calculateLoanRepaymentAmountInCents({
                                                    product: loanApplication.product,
                                                    totalInvoiceAmountInCents: invoice.totalAmountInCents,
                                                  }) * loanApplication.product.frequencyValue,
                                                ),
                                              }}
                                            />
                                          ) : (
                                            <Text>No product selected</Text>
                                          )}
                                        </Stack>
                                      </LoanApplicationInvoiceDetailsForm>
                                    ) : (
                                      <Text>No Loan application details selected</Text>
                                    )}
                                  </Stack>
                                </BaseContainer>
                              </Stack>

                              {isReadyForAdmin && (
                                <Stack
                                  ml="xs"
                                  w={
                                    rightAccordionContainerPercentageWidth
                                      ? `${rightAccordionContainerPercentageWidth}%`
                                      : rightAccordionContainerPercentageWidth
                                  }
                                >
                                  <Accordion defaultValue={openAccordionItemValues} variant="separated" w="100%" multiple>
                                    <Accordion.Item value="actions">
                                      <Accordion.Control onClick={() => handleAccordionItemOpenClose('actions')}>
                                        <Text weight="bold">Actions</Text>
                                      </Accordion.Control>

                                      <Accordion.Panel>
                                        <LoanApplicationActions
                                          loanApplication={loanApplication}
                                          onLoanApplicationAdminStatusUpdated={handleLoanApplicationAdminStatusUpdated}
                                          onLoanApplicationAssignedToStaffMemberUpdated={handleLoanApplicationAssignedToStaffMemberUpdated}
                                        />
                                      </Accordion.Panel>
                                    </Accordion.Item>

                                    <Accordion.Item value="notes">
                                      <Accordion.Control onClick={() => handleAccordionItemOpenClose('notes')}>
                                        <Text weight="bold">Notes</Text>
                                      </Accordion.Control>

                                      <Accordion.Panel>
                                        <CreateNoteForm
                                          onSubmit={({ message }) => {
                                            if (!getIsAuthenticated()) {
                                              signOut();
                                              return;
                                            }

                                            executeCreateNoteForLoanApplicationMutation({
                                              variables: {
                                                loanApplicationId: loanApplication.id,
                                                message,
                                              },
                                            });
                                          }}
                                        />

                                        <LoanApplicationNotes
                                          loanApplicationId={loanApplication.id}
                                          refetchDataOptions={{
                                            onRefetchDataCompleted: handleRefetchDataCompleted,
                                            shouldRefetchData: shouldRefetchNotes,
                                          }}
                                        />
                                      </Accordion.Panel>
                                    </Accordion.Item>
                                  </Accordion>
                                </Stack>
                              )}
                            </Group>
                          </Stack>
                        )}

                        {tabs.config[tabIndex].id === tabs.ids.logs && (
                          <LoanApplicationAuditLogsTable
                            loanApplicationId={id}
                            paginationOptions={{
                              isPaginationEnabled: true,
                            }}
                            refetchDataOptions={{
                              onRefetchDataCompleted: handleRefetchDataCompleted,
                              shouldRefetchData: shouldRefetchAuditLogs,
                            }}
                          />
                        )}
                      </Tabs.Panel>
                    </Tabs>
                  </FullHeightContainer>
                }
                pageHeadingComponent={
                  <Group align="center" mb="md" position="center" w="100%">
                    <Stack justify="center" w="100%">
                      <PageHeading
                        flexContainerProps={{
                          mb: 0,
                        }}
                        heading={title}
                        size="h4"
                      />

                      <Group align="center" ml={1} mt={5}>
                        <Breadcrumbs separator=">">
                          {[
                            {
                              label: 'Loan applications',
                              route: `${ProtectedRoute.LoanApplications}?tab=${returnTab}`,
                            },
                            {
                              label: (
                                <Group noWrap>
                                  <Text weight="bold">Loan application ID:</Text>
                                  <Text> {loanApplication.niceId}</Text>
                                </Group>
                              ),
                            },
                          ].map(({ label, route: breadcrumbRoute }) => {
                            const key = kebabCase(breadcrumbRoute);

                            return breadcrumbRoute ? (
                              <Anchor key={key} weight="bold" onClick={() => handleBreadcrumbItemClick(breadcrumbRoute)}>
                                {label}
                              </Anchor>
                            ) : (
                              <Text key={key}>{label}</Text>
                            );
                          })}
                        </Breadcrumbs>
                      </Group>
                    </Stack>
                  </Group>
                }
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export { LoanApplicationPage };
