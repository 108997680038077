import { BizPayNavigationLinkProps, Page } from 'ui';

export enum ProtectedRoute {
  Entities = '/entities',
  Loans = '/loans',
  LoanApplications = '/loan-applications',
  Partners = '/partners',
  Products = '/products',
  StaffMembers = '/staff-members',
  Users = '/users',
}

export enum PublicRoute {
  SignIn = '/sign-in',
}

export const PROTECTED_SIDE_MENU_NAVIGATION_ROUTES = [
  ProtectedRoute.Entities,
  ProtectedRoute.Loans,
  ProtectedRoute.LoanApplications,
  ProtectedRoute.Partners,
  ProtectedRoute.Products,
  ProtectedRoute.StaffMembers,
  ProtectedRoute.Users,
] as const;

export type ProtectedSideMenuNavigationRoutes = (typeof PROTECTED_SIDE_MENU_NAVIGATION_ROUTES)[number];

export type ProtectedSideMenuNavigationRoutesPage = Record<ProtectedSideMenuNavigationRoutes, Page>;

export interface UseNavigationRoutes {
  buildNavigationBarLinks: (currentRoute: string) => BizPayNavigationLinkProps[];
  findPageByRoute: (route: string) => Page;
}
