import { UseDomain } from './useDomain.types';

const useDomain = (): UseDomain => {
  const getDomain: UseDomain['getDomain'] = () => {
    const { hostname: domain } = new URL(window.location.href);
    return domain;
  };

  return {
    getDomain,
  };
};

export { useDomain };
