import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication } from 'common';
import { useRetrievePaginatedAuditLogsForEntityLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  RefreshDataControl,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { EntityAuditLogsTableProps, PaginatedAuditLogsForEntity } from './EntityAuditLogsTable.types';

import { useSignOut } from '../../hooks';

const EntityAuditLogsTable: FC<EntityAuditLogsTableProps> = ({
  entityId,
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
  refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [auditLogs, setAuditLogs] = useState<PaginatedAuditLogsForEntity[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [shouldRefetchDataInternal, setShouldRefetchDataInternal] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedAuditLogsForEntityQuery, { loading: isRetrievePaginatedEntityAuditLogsLoading, refetch }] =
    useRetrievePaginatedAuditLogsForEntityLazyQuery({
      nextFetchPolicy: 'cache-and-network',
      onCompleted: ({ retrievePaginatedAuditLogs: { count, data } }) => {
        setAuditLogs(data);
        setHasRetrievedData(true);
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setShouldRefetchDataInternal(false);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);

        if (onRefetchDataCompleted) {
          onRefetchDataCompleted();
        }

        if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
          push(`${route.replace('[id]', entityId)}?tab=logs&pageNumber=${currentPageNumber}`, undefined, {
            shallow: true,
          });
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve entity audit logs',
        });
      },
    });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<PaginatedAuditLogsForEntity>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('description', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Message',
        minSize: 0,
        size: 0,
      }),
      columnHelper.accessor('staffMember', {
        cell: ({ getValue }) => {
          const staffMember = getValue();
          return staffMember ? staffMember.fullName : '-';
        },
        enableSorting: false,
        header: 'Staff member',
        minSize: 0,
        size: 150,
      }),
      columnHelper.accessor('user', {
        cell: ({ getValue }) => {
          const user = getValue();
          return user ? user.fullName : '-';
        },
        enableSorting: false,
        header: 'User',
        minSize: 0,
        size: 150,
      }),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date created',
        size: 200,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="audit-log"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          minSize: 10,
          size: 10,
        },
      ),
    ];
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchDataInternal(true);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<PaginatedAuditLogsForEntity>({
    columns: getTableColumns(totalRecords),
    data: auditLogs,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrievePaginatedEntityAuditLogsLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedAuditLogsForEntityQuery({
      variables: {
        entityId,
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!(shouldRefetchData || shouldRefetchDataInternal)) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData, shouldRefetchDataInternal]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <TablePageLayout
      loadingMessage={isLoading ? 'Retrieving logs...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<PaginatedAuditLogsForEntity>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No logs found for this entity"
          rowModel={getRowModel()}
        />
      }
    />
  );
};

export { EntityAuditLogsTable };
