import { FC } from 'react';

import { Flex, Group, Stack, Text, Title } from '@mantine/core';

import { PageHeadingProps } from './PageHeading.types';

const PageHeading: FC<PageHeadingProps> = ({
  description,
  flexContainerProps,
  heading,
  isCentered = false,
  leftIcon,
  rightIcon,
  rightSectionComponent,
  ...otherTitleProps
}) => {
  return (
    <Group align="center" mb="md" position={isCentered ? 'center' : 'left'} w="100%" noWrap {...flexContainerProps}>
      <Group w="100%">
        {leftIcon && (
          <Flex align="center" mr="xs">
            {leftIcon}
          </Flex>
        )}

        <Stack justify="flex-start">
          <Title align={isCentered ? 'center' : 'left'} {...otherTitleProps}>
            {heading}
          </Title>

          {description && (
            <Text align={isCentered ? 'center' : 'left'} color={otherTitleProps.color ? otherTitleProps.color : 'dimmed'} weight="normal">
              {description}
            </Text>
          )}
        </Stack>

        {rightIcon && (
          <Flex align="center" ml="xs">
            {rightIcon}
          </Flex>
        )}
      </Group>

      <Group position="right">{rightSectionComponent}</Group>
    </Group>
  );
};

export { PageHeading };
