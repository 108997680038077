import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedAuditLogsForEntityQuery: DocumentNode = gql`
  query retrievePaginatedAuditLogsForEntity($entityId: String!, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedAuditLogs(entityId: $entityId, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        description
        id
        staffMember {
          fullName
          id
        }
        user {
          fullName
          id
        }
      }
    }
  }
`;

export { retrievePaginatedAuditLogsForEntityQuery };
