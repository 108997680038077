import { zodResolver, z } from 'ui';

const partnerFormZodResolver = () => {
  return zodResolver(
    z.object({
      name: z.string().min(1, {
        message: 'Business name is required',
      }),
    }),
  );
};

export { partnerFormZodResolver };
