import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication, useUnitConversion } from 'common';
import {
  EftsureVerificationStatus,
  LoanApplicationAdminStatus,
  useAskEftsureToVerifySupplierBankAccountForLoanApplicationMutation,
  useDeleteSupplierBankAccountForLoanApplicationMutation,
  useRetrieveSupplierBankAccountsForSupplierLazyQuery,
  useVerifySupplierBankAccountForLoanApplicationMutation,
} from 'graphql-library';
import {
  BizPayConfirmationModal,
  BizPayLoader,
  BizPayPrimaryButton,
  BizPayQuestionMarkIcon,
  BizPayTable,
  BizPayTooltip,
  Box,
  createColumnHelper,
  Flex,
  FullHeightContainer,
  getCoreRowModel,
  IconEftsureGreenThumbsLeft,
  IconEftsureGreenThumbsUp,
  IconEftsureRedThumbsDown,
  IconEftsureYellowThumbsLeft,
  IconInfoCircle,
  Radio,
  RefreshDataControl,
  Stack,
  TableActionDropdownMenu,
  Text,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { EftsureSelfCertifyModal } from '../EftsureSelfCertifyModal';
import { SupplierBankAccountModal } from '../SupplierBankAccountModal';

import {
  GREEN_THUMBS_LEFT_ICON_STATUS,
  GREEN_THUMBS_UP_ICON_STATUS,
  RED_THUMBS_UP_ICON_STATUS,
  YELLOW_THUMBS_LEFT_ICON_STATUS,
} from './SupplierBankAccountsTable.constants';
import { getEftsureVerificationStatusColor, getEftsureVerificationStatusDescription } from './SupplierBankAccountsTable.helpers';
import { SupplierBankAccount, SupplierBankAccountsTableProps } from './SupplierBankAccountsTable.types';

import { useSignOut } from '../../hooks';

// TODO
// - Fix issue with the table (seemingly) re-rendering unnecessarily
//   Notes:
//   - Hover over the refresh data icon and notice the timer
//   - Click on the actions button and select an action that opens up a modal
//   - Close the modal
//   - Hover over the refresh data icon and notice that the timer has been reset

const REFRESH_SUPPLIER_BANK_ACCOUNTS_INTERVAL_IN_MINUTES = 5;

const SupplierBankAccountsTable: FC<SupplierBankAccountsTableProps> = ({
  loanApplicationAdminStatus,
  loanApplicationId,
  onSupplierBankAccountChange,
  supplierBankAccountId,
  supplierId,
}) => {
  const areTableActionsDisabled = loanApplicationAdminStatus !== LoanApplicationAdminStatus.UnderReview;

  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { signOut } = useSignOut();
  const { getMillisecondsFromMinutes } = useUnitConversion();

  const [
    checkSupplierBankAccountsWithPendingEftsureVerificationStatusTrigger,
    setCheckSupplierBankAccountsWithPendingEftsureVerificationStatusTrigger,
  ] = useState<string>();
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isAskEftsureToVerifySupplierBankAccountModalOpen, setIsAskEftsureToVerifySupplierBankAccountModalOpen] = useState<boolean>(false);
  const [isConfirmationDeleteSupplierBankAccountModalOpened, setIsConfirmationDeleteSupplierBankAccountModalOpened] =
    useState<boolean>(false);
  const [isCreateSupplierBankAccountModalOpen, setIsCreateSupplierBankAccountModalOpen] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [isSelfCertifyModalOpen, setIsSelfCertifyModalOpen] = useState<boolean>(false);
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [selectedSupplierBankAccountId, setSelectedSupplierBankAccountId] = useState<string>();
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);
  const [supplierBankAccounts, setSupplierBankAccounts] = useState<SupplierBankAccount[]>([]);

  const [
    executeAskEftsureToVerifySupplierBankAccountForLoanApplicationMutation,
    { loading: isAskEftsureToVerifySupplierBankAccountForLoanApplicationLoading },
  ] = useAskEftsureToVerifySupplierBankAccountForLoanApplicationMutation({
    onCompleted: () => {
      setIsAskEftsureToVerifySupplierBankAccountModalOpen(false);
      setShouldRefetchData(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to ask Eftsure to verify supplier account',
      });
    },
  });

  const [executeDeleteSupplierBankAccountForLoanApplicationMutation, { loading: isDeleteSupplierBankAccountLoading }] =
    useDeleteSupplierBankAccountForLoanApplicationMutation({
      onCompleted: () => {
        setIsConfirmationDeleteSupplierBankAccountModalOpened(false);
        setShouldRefetchData(true);

        displaySuccessNotification({
          message: 'Successfully removed your supplier bank account',
        });
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message,
          title: 'Unable to delete your supplier bank account',
        });
      },
    });

  const [executeRetrieveSupplierBankAccountsForSupplierLazyQuery, { refetch: refetchSupplierBankAccounts }] =
    useRetrieveSupplierBankAccountsForSupplierLazyQuery({
      nextFetchPolicy: 'cache-and-network',
      onCompleted: ({ retrieveSupplierBankAccountsBySupplierId: returnedSupplierBankAccounts }) => {
        const hasSupplierBankAccountsWithPendingEftsureVerificationStatus = returnedSupplierBankAccounts.some(
          ({ eftsureVerificationStatus }) => eftsureVerificationStatus === EftsureVerificationStatus.Pending,
        );

        setCheckSupplierBankAccountsWithPendingEftsureVerificationStatusTrigger(
          hasSupplierBankAccountsWithPendingEftsureVerificationStatus ? dayjs().toISOString() : undefined,
        );
        setHasRetrievedData(true);
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setShouldRefetchData(false);
        setSupplierBankAccounts(returnedSupplierBankAccounts);
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message,
          title: 'Unable to delete your supplier bank account',
        });
      },
    });

  const [executeVerifySupplierBankAccountForLoanApplicationMutation, { loading: isVerifySupplierBankAccountForLoanApplicationLoading }] =
    useVerifySupplierBankAccountForLoanApplicationMutation({
      onCompleted: () => {
        setShouldRefetchData(true);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to verify supplier bank account',
        });
      },
    });

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<SupplierBankAccount>();

    return [
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: ({ getValue }) => {
            const { id } = getValue();

            return (
              <Radio
                checked={supplierBankAccountId === id}
                disabled={areTableActionsDisabled}
                size="xs"
                onChange={() => onSupplierBankAccountChange(id)}
              />
            );
          },
          enableSorting: false,
          header: undefined,
          id: 'select-supplier-bank-account',
          size: 50,
        },
      ),
      columnHelper.accessor('bsb', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'BSB',
        size: 100,
      }),
      columnHelper.accessor('accountNumber', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Account number',
        size: 150,
      }),
      columnHelper.accessor('accountName', {
        cell: ({ getValue }) => {
          const name = getValue();
          return name ? name : '-';
        },
        enableSorting: false,
        header: 'Account name',
        size: 200,
      }),
      columnHelper.accessor('eftsureVerificationStatus', {
        cell: ({ getValue }) => {
          const eftsureVerificationStatus = getValue();

          if (!eftsureVerificationStatus) {
            return <BizPayQuestionMarkIcon />;
          }

          if (GREEN_THUMBS_LEFT_ICON_STATUS.includes(eftsureVerificationStatus)) {
            return <IconEftsureGreenThumbsLeft />;
          }

          if (GREEN_THUMBS_UP_ICON_STATUS.includes(eftsureVerificationStatus)) {
            return <IconEftsureGreenThumbsUp />;
          }

          if (RED_THUMBS_UP_ICON_STATUS.includes(eftsureVerificationStatus)) {
            return <IconEftsureRedThumbsDown />;
          }

          if (YELLOW_THUMBS_LEFT_ICON_STATUS.includes(eftsureVerificationStatus)) {
            return <IconEftsureYellowThumbsLeft />;
          }
        },
        enableSorting: false,
        header: 'Status',
        meta: {
          align: 'center',
        },
        size: 75,
      }),
      columnHelper.accessor(
        ({ eftsureVerificationReason, eftsureVerificationStatus, eftsureVerifiedAtUtc }) => ({
          eftsureVerificationReason,
          eftsureVerificationStatus,
          eftsureVerifiedAtUtc,
        }),
        {
          cell: ({ getValue }) => {
            const { eftsureVerificationReason, eftsureVerificationStatus, eftsureVerifiedAtUtc } = getValue();

            if (!eftsureVerificationStatus) {
              return '-';
            }

            const eftsureVerificationStatusDescription = getEftsureVerificationStatusDescription(eftsureVerificationStatus);

            return (
              <BizPayTooltip
                label={
                  <>
                    {eftsureVerificationStatusDescription && (
                      <Text sx={{ color: getEftsureVerificationStatusColor(eftsureVerificationStatus) }} weight="bold">
                        {eftsureVerificationStatusDescription}
                      </Text>
                    )}
                    {eftsureVerificationReason && <Text>{eftsureVerificationReason}</Text>}
                    {eftsureVerifiedAtUtc && <Text>{dayjs(eftsureVerifiedAtUtc).toDate().toLocaleString()}</Text>}
                  </>
                }
              >
                <IconInfoCircle size={20} />
              </BizPayTooltip>
            );
          },
          enableSorting: false,
          header: 'Info',
          id: 'verification-information',
          meta: {
            align: 'center',
          },
          size: 50,
        },
      ),
      columnHelper.accessor(
        ({ accountName, accountNumber, bsb, eftsureVerificationStatus, id }) => ({
          accountName,
          accountNumber,
          bsb,
          eftsureVerificationStatus,
          id,
        }),
        {
          cell: ({ getValue }) => {
            const { accountName, accountNumber, bsb, eftsureVerificationStatus, id } = getValue();

            if (areTableActionsDisabled) {
              return undefined;
            }

            return (
              <TableActionDropdownMenu
                menuItems={[
                  {
                    disabled: eftsureVerificationStatus !== EftsureVerificationStatus.NotVerified && eftsureVerificationStatus !== null,
                    id: 'verify-account',
                    label: eftsureVerificationStatus === EftsureVerificationStatus.NotVerified ? 'Ask eftsure to verify' : 'Verify account',
                    onClick: () => {
                      setSelectedSupplierBankAccountId(id);

                      if (!eftsureVerificationStatus) {
                        if (!getIsAuthenticated()) {
                          signOut();
                          return;
                        }

                        executeVerifySupplierBankAccountForLoanApplicationMutation({
                          variables: {
                            id,
                            input: {
                              accountName,
                              accountNumber,
                              bsb,
                              supplierId,
                            },
                            loanApplicationId,
                          },
                        });

                        return;
                      }

                      setIsAskEftsureToVerifySupplierBankAccountModalOpen(true);
                    },
                  },
                  {
                    disabled: !(
                      eftsureVerificationStatus === EftsureVerificationStatus.NotVerified ||
                      eftsureVerificationStatus === EftsureVerificationStatus.Pending
                    ),
                    id: 'self-certify-account',
                    label: 'Self certify account',
                    onClick: () => {
                      setIsSelfCertifyModalOpen(true);
                      setSelectedSupplierBankAccountId(id);
                    },
                  },
                  {
                    disabled: supplierBankAccountId === id,
                    id: 'delete-account',
                    label: 'Delete account',
                    onClick: () => {
                      setIsConfirmationDeleteSupplierBankAccountModalOpened(true);
                      setSelectedSupplierBankAccountId(id);
                    },
                  },
                ]}
              />
            );
          },
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="supplier-bank-account"
              isDisabled={areTableActionsDisabled}
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              refreshDataIntervalInMilliseconds={getMillisecondsFromMinutes(REFRESH_SUPPLIER_BANK_ACCOUNTS_INTERVAL_IN_MINUTES)}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'actions',
          meta: {
            align: 'center',
          },
          size: 50,
        },
      ),
    ];
  };

  const handleAddSupplierBankAccount = () => {
    setIsCreateSupplierBankAccountModalOpen(true);
  };

  const handleAskEftsureToVerifySupplierBankAccountModalClose = () => {
    setIsAskEftsureToVerifySupplierBankAccountModalOpen(false);
  };

  const handleCreateSupplierBankAccountModalClose = () => {
    setIsCreateSupplierBankAccountModalOpen(false);
  };

  const handleCreateSupplierBankAccountModalCompleted = () => {
    setIsCreateSupplierBankAccountModalOpen(false);
    setShouldRefetchData(true);
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmationDeleteSupplierBankAccountModalOpened(false);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchData(true);
  };

  const handleSelfCertifyModalClose = () => {
    setIsSelfCertifyModalOpen(false);
  };

  const handleSelfCertifyModalCompleted = () => {
    setIsSelfCertifyModalOpen(false);
    setShouldRefetchData(true);
  };

  const { getHeaderGroups, getRowModel } = useReactTable<SupplierBankAccount>({
    columns: getTableColumns(),
    data: supplierBankAccounts,
    getCoreRowModel: getCoreRowModel(),
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveSupplierBankAccountsForSupplierLazyQuery({
      variables: {
        supplierId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetchSupplierBankAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  useEffect(() => {
    if (!checkSupplierBankAccountsWithPendingEftsureVerificationStatusTrigger) {
      return;
    }

    const timerId = setTimeout(() => {
      refetchSupplierBankAccounts();
    }, getMillisecondsFromMinutes(REFRESH_SUPPLIER_BANK_ACCOUNTS_INTERVAL_IN_MINUTES));

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSupplierBankAccountsWithPendingEftsureVerificationStatusTrigger]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, getMillisecondsFromMinutes(REFRESH_SUPPLIER_BANK_ACCOUNTS_INTERVAL_IN_MINUTES));

    return () => {
      clearTimeout(timerId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRefreshDataIconButtonDisabled]);

  const isLoading =
    isAskEftsureToVerifySupplierBankAccountForLoanApplicationLoading ||
    isDeleteSupplierBankAccountLoading ||
    isVerifySupplierBankAccountForLoanApplicationLoading;

  return (
    <>
      <SupplierBankAccountModal
        isOpen={isCreateSupplierBankAccountModalOpen}
        loanApplicationId={loanApplicationId}
        supplierId={supplierId}
        onClose={handleCreateSupplierBankAccountModalClose}
        onCompleted={handleCreateSupplierBankAccountModalCompleted}
      />

      {selectedSupplierBankAccountId && (
        <>
          <BizPayConfirmationModal
            closeOnClickOutside={!isDeleteSupplierBankAccountLoading}
            closeOnEscape={!isDeleteSupplierBankAccountLoading}
            opened={isConfirmationDeleteSupplierBankAccountModalOpened}
            title="Delete supplier bank account"
            onClose={handleConfirmationModalClose}
            onConfirm={() => {
              if (!getIsAuthenticated()) {
                signOut();
                return;
              }

              executeDeleteSupplierBankAccountForLoanApplicationMutation({
                variables: {
                  id: selectedSupplierBankAccountId,
                },
              });
            }}
          >
            Are you sure want to delete supplier bank account?
          </BizPayConfirmationModal>

          <BizPayConfirmationModal
            opened={isAskEftsureToVerifySupplierBankAccountModalOpen}
            size={650}
            title="Verify supplier bank account"
            onClose={handleAskEftsureToVerifySupplierBankAccountModalClose}
            onConfirm={() => {
              if (!getIsAuthenticated()) {
                signOut();
                return;
              }

              executeAskEftsureToVerifySupplierBankAccountForLoanApplicationMutation({
                variables: {
                  id: selectedSupplierBankAccountId,
                  loanApplicationId,
                },
              });
            }}
          >
            Are you sure you want Eftsure to start the verification process?
          </BizPayConfirmationModal>

          <EftsureSelfCertifyModal
            isOpen={isSelfCertifyModalOpen}
            loanApplicationId={loanApplicationId}
            supplierBankAccountId={selectedSupplierBankAccountId}
            onClose={handleSelfCertifyModalClose}
            onCompleted={handleSelfCertifyModalCompleted}
          />
        </>
      )}

      <FullHeightContainer>
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isAskEftsureToVerifySupplierBankAccountForLoanApplicationLoading && (
              <BizPayLoader message="Asking Eftsure to verify the supplier bank account for this loan application, please wait..." />
            )}

            {isDeleteSupplierBankAccountLoading && (
              <BizPayLoader message="Deleting supplier bank account for this loan application, please wait..." />
            )}

            {isVerifySupplierBankAccountForLoanApplicationLoading && (
              <BizPayLoader message="Verifying supplier bank account for this loan application, please wait..." />
            )}
          </Stack>
        ) : (
          <>
            <Box
              sx={{
                overflow: 'scroll',
                overflowY: 'hidden',
                '&::-webkit-scrollbar': {
                  height: '0.5rem',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: '#999999',
                  borderRadius: '0.75rem',
                  color: 'red',
                },
              }}
            >
              <BizPayTable<SupplierBankAccount>
                hasRetrievedData={hasRetrievedData}
                headerGroups={getHeaderGroups()}
                rowModel={getRowModel()}
              />
            </Box>

            {!areTableActionsDisabled && (
              <Flex justify="end" mt="xl">
                <BizPayPrimaryButton onClick={handleAddSupplierBankAccount}>Add supplier bank account</BizPayPrimaryButton>
              </Flex>
            )}
          </>
        )}
      </FullHeightContainer>
    </>
  );
};

export { SupplierBankAccountsTable };
