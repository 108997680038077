import { FC } from 'react';

import { Select } from '@mantine/core';
import { useForm } from 'react-hook-form';

import { AssignToStaffMemberFormData, AssignToStaffMemberFormProps } from './AssignToStaffMemberForm.types';

const AssignToStaffMemberForm: FC<AssignToStaffMemberFormProps> = ({
  dropdownListOptions,
  isReadOnly,
  label = 'Assigned to',
  onChange,
  selectedAssignedToStaffMemberId,
}) => {
  const { register } = useForm<AssignToStaffMemberFormData>();

  return (
    <form id="assign-to-staff-member-form">
      <Select
        {...register('assignedToStaffMemberId')}
        data={dropdownListOptions}
        label={label}
        nothingFound="No staff members found"
        placeholder="Assign a staff member"
        readOnly={isReadOnly}
        size="md"
        value={selectedAssignedToStaffMemberId}
        w="100%"
        searchable
        onChange={(value) => {
          if (!value) {
            return;
          }

          onChange(value);
        }}
      />
    </form>
  );
};

export { AssignToStaffMemberForm };
