import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import { useCreateDisbursementsFileMutation } from 'graphql-library';
import { BizPayConfirmationModal, ErrorAlert, Stack, Text } from 'ui';

import { ConfirmDownloadDisbursementsFileModalProps } from './ConfirmDownloadDisbursementsFileModal.types';

import { useSignOut } from '../../hooks';

const ConfirmDownloadDisbursementsFileModal: FC<ConfirmDownloadDisbursementsFileModalProps> = ({ isOpen, onClose, onCompleted }) => {
  const { getIsAuthenticated } = useAuthentication();

  const { signOut } = useSignOut();

  const [errorMessage, setErrorMessage] = useState<string>();

  const [executeCreateDisbursementsFileMutation, { loading: isCreateDisbursementsFileLoading }] = useCreateDisbursementsFileMutation({
    onCompleted: ({ createDisbursementsFile: returnedDisbursementsFile }) => {
      setErrorMessage(undefined);

      onClose();
      onCompleted(returnedDisbursementsFile);
    },
    onError: ({ message }) => {
      setErrorMessage(message);
    },
  });

  const handleClose = () => {
    onClose();
    setErrorMessage(undefined);
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <BizPayConfirmationModal
      closeOnClickOutside={!isCreateDisbursementsFileLoading}
      h={errorMessage ? 400 : 250}
      loadingMessage={isCreateDisbursementsFileLoading ? 'Creating disbursements, please wait...' : undefined}
      opened={isOpen}
      size={650}
      title="Generate disbursements"
      withCloseButton={!isCreateDisbursementsFileLoading}
      onClose={handleClose}
      onConfirm={() => {
        if (!getIsAuthenticated()) {
          signOut();
          return;
        }

        executeCreateDisbursementsFileMutation();
      }}
    >
      <>
        {!isCreateDisbursementsFileLoading && (
          <Stack align="center" justify="center">
            <Text>Are you sure you want to generate the disbursements?</Text>

            <Text
              mt="md"
              sx={({ colors }) => ({
                color: colors['bizpay-red'][7],
              })}
              weight="bold"
            >
              The disbursements can only be generated once a day
            </Text>
          </Stack>
        )}

        {errorMessage && (
          <ErrorAlert contentScrollAreaHeight={100} h={200} mt="md" title="Generate disbursements error" w="100%">
            {errorMessage}
          </ErrorAlert>
        )}
      </>
    </BizPayConfirmationModal>
  );
};

export { ConfirmDownloadDisbursementsFileModal };
