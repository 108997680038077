import { FC } from 'react';

import { BizPayBadge } from '../BizPayBadge';

import { ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP, ENTITY_STATUS_TO_BORDER_COLOR_MAP } from './EntityStatusBadge.constants';
import { EntityStatusBadgeProps } from './EntityStatusBadge.types';

const EntityStatusBadge: FC<EntityStatusBadgeProps> = ({ status }) => {
  return (
    <BizPayBadge
      sx={({ black }) => ({
        backgroundColor: ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP[status],
        border: `0.075rem solid ${ENTITY_STATUS_TO_BORDER_COLOR_MAP[status]}`,
        color: black,
        fontWeight: 'normal',
      })}
    >
      {status}
    </BizPayBadge>
  );
};

export { EntityStatusBadge };
