import { FC } from 'react';

import { Flex, Select, Stack, Text, TextInput } from '@mantine/core';
import { sentenceCase } from 'common';
import { DocumentType } from 'graphql-library';
import { useForm } from 'react-hook-form';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';
import { ErrorMessage } from '../ErrorMessage';
import { FileUpload, FileUploadProps } from '../FileUpload';

import { uploadEntityDocumentFormZodResolver } from './UploadEntityDocumentForm.helpers';
import { UploadEntityDocumentFormData, UploadEntityDocumentFormProps } from './UploadEntityDocumentForm.types';

const UploadEntityDocumentForm: FC<UploadEntityDocumentFormProps> = ({ signedUrl, onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
  } = useForm<UploadEntityDocumentFormData>({
    resolver: uploadEntityDocumentFormZodResolver(),
  });

  const handleFileUpload: FileUploadProps['onDrop'] = async (acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      return;
    }

    setValue('document', acceptedFiles[0]);
  };

  const handleDocumentTypeChanges = (documentType: DocumentType) => {
    setValue('documentType', documentType);
  };

  const { document: documentError, documentType: documentTypeError } = errors;

  return (
    <form id="entity-upload-document" onSubmit={handleSubmit(onSubmit)}>
      <Stack h={500} justify="center" mt="md">
        <Select
          {...(register('documentType'), { placeholder: 'Other', onChange: handleDocumentTypeChanges })}
          data={Object.keys(DocumentType).map((documentType) => ({
            label: sentenceCase(documentType),
            value: documentType,
          }))}
          error={documentTypeError ? <ErrorMessage message={documentTypeError.message} /> : null}
          label="Select document type"
          size="md"
          w="100%"
        />

        <Stack align="center" h="100%" justify="center" w="100%">
          <FileUpload
            {...(register('document'),
            {
              onDrop: handleFileUpload,
            })}
            errorContent={documentError ? <ErrorMessage message={documentError.message} /> : null}
            instructionText={`Drag and drop your document ${signedUrl ? 'replacement' : ''}here`}
            maxFileSizeInMegabytes={30}
            selectFileButtonText={`Select a document ${signedUrl ? 'replacement' : ''}from your files`}
            shouldDisplayAcceptedFiles
          />

          {signedUrl && (
            <>
              <Text mt="md">or</Text>
              <Text mt="md">Click on the &quot;Continue&quot; button to skip the document replacement</Text>
            </>
          )}
        </Stack>

        <TextInput {...register('description')} label="Add description" size="md" />

        <Flex justify="flex-end" mt={20}>
          <BizPayPrimaryButton disabled={!isValid} type="submit">
            Upload document
          </BizPayPrimaryButton>
        </Flex>
      </Stack>
    </form>
  );
};

export { UploadEntityDocumentForm };
