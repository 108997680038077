import { DocumentNode, gql } from 'graphql-library';

const retrieveEntityStakeholderShareholdersForEntityQuery: DocumentNode = gql`
  query retrieveEntityStakeholderShareholdersForEntity($entityId: String!) {
    retrieveEntityStakeholderShareholdersForEntity(entityId: $entityId) {
      abn
      dateOfBirth
      email
      id
      isBeneficiallyHeld
      name
      phoneNumber
      shareholderType
    }
  }
`;

export { retrieveEntityStakeholderShareholdersForEntityQuery };
