import { FC } from 'react';

import { Badge } from '@mantine/core';

import { BizPayBadgeProps } from './BizPayBadge.types';

const BizPayBadge: FC<BizPayBadgeProps> = ({ children, ...otherProps }) => {
  return (
    <Badge radius="sm" size="lg" {...otherProps}>
      {children}
    </Badge>
  );
};

export { BizPayBadge };
