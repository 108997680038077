import { FC } from 'react';

import { IconCheck } from '@tabler/icons-react';

import { BizPayTickIconProps } from './BizPayTickIcon.types';

import { USE_BIZPAY_UI_THEME_CONSTANTS } from '../../hooks';

const { colors } = USE_BIZPAY_UI_THEME_CONSTANTS;

const BizPayTickIcon: FC<BizPayTickIconProps> = (props) => {
  return <IconCheck color={colors.teal} size={20} {...props} />;
};

export { BizPayTickIcon };
