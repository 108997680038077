import { LoanStatus } from 'graphql-library';

const LOAN_STATUS_TO_COLOR_MAP = Object.freeze<Record<LoanStatus, string>>({
  Arrears: '#ffcaca',
  Active: '#c1fffc',
  BadDebt: '#d5d1d1',
  Completed: '#e0edf4',
  Withdrawn: '#ffc7c7',
});

export { LOAN_STATUS_TO_COLOR_MAP };
