import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedDocumentsForLoanQuery: DocumentNode = gql`
  query retrievePaginatedDocumentsForLoan($loanId: ID!, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedDocuments(loanId: $loanId, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        description
        id
        originalFileName
        signedUrl
        type
        uploadedByStaffMember {
          fullName
          id
        }
        uploadedByUser {
          fullName
          id
        }
      }
    }
  }
`;

export { retrievePaginatedDocumentsForLoanQuery };
