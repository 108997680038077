import { DocumentNode, gql } from 'graphql-library';

const createProductMutation: DocumentNode = gql`
  mutation createProduct($input: CreateAndUpdateProductInput!) {
    createProduct(input: $input) {
      id
    }
  }
`;

const updateProductMutation: DocumentNode = gql`
  mutation updateProduct($id: ID!, $input: CreateAndUpdateProductInput!) {
    updateProduct(id: $id, input: $input) {
      id
    }
  }
`;

export { createProductMutation, updateProductMutation };
