import {
  getByteSize,
  getBytesInKiloByte,
  getGigabytesFromBytes,
  getMegabytesFromBytes,
  getMillisecondsFromDays,
  getMillisecondsFromHours,
  getMillisecondsFromMinutes,
  getMillisecondsFromSeconds,
} from './useUnitConversion.helpers';
import { UseUnitConversion } from './useUnitConversion.types';

const useUnitConversion = (): UseUnitConversion => {
  return {
    getByteSize,
    getBytesInKiloByte,
    getGigabytesFromBytes,
    getMegabytesFromBytes,
    getMillisecondsFromDays,
    getMillisecondsFromHours,
    getMillisecondsFromMinutes,
    getMillisecondsFromSeconds,
  };
};

export { useUnitConversion };
