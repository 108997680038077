import { JwtPayload } from 'jwt-decode';

import { getDecodedJwt, getJwtExpiryDate, hasJwtExpired } from './useJwtDecoder.helpers';
import { UseJwtDecoder } from './useJwtDecoder.types';

const useJwtDecoder = <T extends JwtPayload>(): UseJwtDecoder<T> => {
  const getJwtClaim: UseJwtDecoder<T>['getJwtClaim'] = (decodedToken, key) => {
    return decodedToken[key];
  };

  return {
    getDecodedJwt,
    getJwtClaim,
    getJwtExpiryDate,
    hasJwtExpired,
  };
};

export { useJwtDecoder };
