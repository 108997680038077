import { formatCurrency, formatPercentage, formatWithThousandsSeparator } from './useNumberFormatter.helpers';
import { UseNumberFormatter } from './useNumberFormatter.types';

const useNumberFormatter = (): UseNumberFormatter => {
  return {
    formatCurrency,
    formatPercentage,
    formatWithThousandsSeparator,
  };
};

export { useNumberFormatter };
