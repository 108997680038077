import { FC } from 'react';

import { Box } from '@mantine/core';

const BizPayQuestionMarkIcon: FC = () => {
  const svgData = `
  <svg width="20" height="20" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32C11.7565 32 7.68687 30.3143 4.68629 27.3137C1.68571 24.3131 0 20.2435 0 16C0 11.7565 1.68571 7.68687 4.68629 4.68629C7.68687 1.68571 11.7565 0 16 0C20.2435 0 24.3131 1.68571 27.3137 4.68629C30.3143 7.68687 32 11.7565 32 16V16ZM16 10C15.6486 9.99966 15.3033 10.0919 14.9989 10.2675C14.6945 10.443 14.4417 10.6957 14.266 11C14.1391 11.2358 13.9662 11.4436 13.7575 11.6113C13.5488 11.779 13.3086 11.9031 13.051 11.9762C12.7935 12.0493 12.5239 12.07 12.2582 12.037C11.9925 12.004 11.7362 11.9179 11.5044 11.784C11.2726 11.6501 11.07 11.471 10.9087 11.2573C10.7473 11.0437 10.6306 10.7998 10.5653 10.5402C10.4999 10.2805 10.4874 10.0104 10.5284 9.74587C10.5694 9.48131 10.6631 9.22767 10.804 9C11.4644 7.85622 12.4838 6.96232 13.7041 6.45692C14.9243 5.95153 16.2772 5.86287 17.5529 6.20471C18.8287 6.54656 19.956 7.29979 20.76 8.3476C21.5641 9.39541 21.9999 10.6792 22 12C22.0004 13.2412 21.6159 14.4521 20.8995 15.4657C20.1832 16.4794 19.1701 17.246 18 17.66V18C18 18.5304 17.7893 19.0391 17.4142 19.4142C17.0391 19.7893 16.5304 20 16 20C15.4696 20 14.9609 19.7893 14.5858 19.4142C14.2107 19.0391 14 18.5304 14 18V16C14 15.4696 14.2107 14.9609 14.5858 14.5858C14.9609 14.2107 15.4696 14 16 14C16.5304 14 17.0391 13.7893 17.4142 13.4142C17.7893 13.0391 18 12.5304 18 12C18 11.4696 17.7893 10.9609 17.4142 10.5858C17.0391 10.2107 16.5304 10 16 10ZM16 26C16.5304 26 17.0391 25.7893 17.4142 25.4142C17.7893 25.0391 18 24.5304 18 24C18 23.4696 17.7893 22.9609 17.4142 22.5858C17.0391 22.2107 16.5304 22 16 22C15.4696 22 14.9609 22.2107 14.5858 22.5858C14.2107 22.9609 14 23.4696 14 24C14 24.5304 14.2107 25.0391 14.5858 25.4142C14.9609 25.7893 15.4696 26 16 26V26Z" fill="#97B5BE"/>
  </svg>
`;

  return <Box dangerouslySetInnerHTML={{ __html: svgData }} display="flex" style={{ alignItems: 'center', justifyContent: 'center' }} />;
};

export { BizPayQuestionMarkIcon };
