import { FC, useEffect, useState } from 'react';

import { dayjs, saveAs, sentenceCase, useAuthentication } from 'common';
import { useRetrievePaginatedDocumentsForLoanLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  Anchor,
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LoanDocument, LoanDocumentsTableProps } from './LoanDocumentsTable.types';

import { useSignOut } from '../../hooks';

const LoanDocumentsTable: FC<LoanDocumentsTableProps> = ({ loanId, paginationOptions: { isPaginationEnabled = false, maxPageSize } }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination({
    pageSize: 5,
  });
  const { query } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [documents, setDocuments] = useState<LoanDocument[]>([]);
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedDocumentsForLoanQuery, { loading: isLoading }] = useRetrievePaginatedDocumentsForLoanLazyQuery({
    onCompleted: ({ retrievePaginatedDocuments: { count, data } }) => {
      setDocuments(data);
      setHasRetrievedData(true);
      setTotalPages(calculateTotalPages(currentPageSize, count));
      setTotalRecords(count);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve loan documents',
      });
    },
  });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<LoanDocument>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor(
        ({ originalFileName, signedUrl }) => ({
          originalFileName,
          signedUrl,
        }),
        {
          cell: ({ getValue }) => {
            const { originalFileName, signedUrl } = getValue();

            return signedUrl ? (
              <Anchor onClick={() => handleDownloadInvoice(originalFileName, signedUrl)}>{originalFileName}</Anchor>
            ) : (
              originalFileName
            );
          },
          enableSorting: isSortingEnabled,
          header: 'File name',
          minSize: 0,
          size: 0,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor('description', {
        cell: ({ getValue }) => {
          const description = getValue();
          return description ? sentenceCase(description) : '-';
        },
        enableSorting: isSortingEnabled,
        header: 'Description',
        size: 0,
        minSize: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('type', {
        cell: ({ getValue }) => sentenceCase(getValue()),
        enableSorting: isSortingEnabled,
        header: 'Type',
        size: 250,
        sortDescFirst: false,
      }),
      columnHelper.accessor(
        ({ uploadedByStaffMember, uploadedByUser }) => ({
          uploadedByStaffMember,
          uploadedByUser,
        }),
        {
          cell: ({ getValue }) => {
            const { uploadedByStaffMember, uploadedByUser } = getValue();

            const uploadedBy = (uploadedByStaffMember ?? uploadedByUser)?.fullName;

            return uploadedBy ? uploadedBy : 'System';
          },
          enableSorting: isSortingEnabled,
          header: 'Uploaded by',
          size: 150,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date uploaded',
        size: 200,
        sortDescFirst: false,
      }),
    ];
  };

  const handleDownloadInvoice = (originalFileName: string, url: string) => {
    saveAs(url, originalFileName);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<LoanDocument>({
    columns: getTableColumns(totalRecords),
    data: documents,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedDocumentsForLoanQuery({
      variables: {
        loanId,
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  return (
    <TablePageLayout
      loadingMessage={isLoading ? 'Retrieving loan documents...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<LoanDocument>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No documents found for this loan"
          rowModel={getRowModel()}
        />
      }
    />
  );
};

export { LoanDocumentsTable };
