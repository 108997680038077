import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication } from 'common';
import { useRetrievePaginatedNotesForLoanApplicationLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LoanApplicationNotesTableProps, PaginatedNotesForLoanApplication } from './LoanApplicationNotesTable.types';

import { useSignOut } from '../../hooks';

const LoanApplicationNotesTable: FC<LoanApplicationNotesTableProps> = ({
  loanApplicationId,
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
  refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [notes, setNotes] = useState<PaginatedNotesForLoanApplication[]>([]);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedNotesForLoanApplicationQuery, { loading: isRetrievePaginatedNotesForLoanApplicationLoading, refetch }] =
    useRetrievePaginatedNotesForLoanApplicationLazyQuery({
      onCompleted: ({ retrievePaginatedNotesForLoanApplication: { count, data } }) => {
        setHasRetrievedData(true);
        setNotes(data);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);

        if (onRefetchDataCompleted) {
          onRefetchDataCompleted();
        }

        if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
          push(`${route.replace('[id]', loanApplicationId)}?pageNumber=${currentPageNumber}`, undefined, {
            shallow: true,
          });
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve the notes for this loan application',
        });
      },
    });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<PaginatedNotesForLoanApplication>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('message', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Message',
        size: 200,
      }),
      columnHelper.accessor('submittedByStaffMember.fullName', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Staff member',
        size: 100,
      }),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date created',
        size: 100,
      }),
    ];
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<PaginatedNotesForLoanApplication>({
    columns: getTableColumns(totalRecords),
    data: notes,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrievePaginatedNotesForLoanApplicationLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedNotesForLoanApplicationQuery({
      variables: {
        loanApplicationId,
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  return (
    <TablePageLayout
      loadingMessage={isLoading ? 'Retrieving notes for the loan application...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<PaginatedNotesForLoanApplication>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No notes found for this loan application"
          rowModel={getRowModel()}
        />
      }
    />
  );
};

export { LoanApplicationNotesTable };
