import { FC, useEffect, useState } from 'react';

import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { currency, dayjs, sentenceCase, useAuthentication } from 'common';
import { useRetrieveLoanRepaymentsForLoanLazyQuery } from 'graphql-library';

import { BizPayTable } from '../BizPayTable';
import { RefreshDataControl } from '../RefreshDataControl';
import { TablePageLayout } from '../TablePageLayout';

import { LoanRepaymentForLoanRepaymentsTable, LoanRepaymentsTableProps } from './LoanRepaymentsTable.types';

import { useBizPayNotification } from '../../hooks';

const LoanRepaymentsTable: FC<LoanRepaymentsTableProps> = ({ filterByStatuses, loanId, onLoadTableDataCompleted, onSignOut }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();

  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [loanRepayments, setLoanRepayments] = useState<LoanRepaymentForLoanRepaymentsTable[]>([]);
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);

  const [executeRetrieveLoanRepaymentsForLoanQuery, { refetch }] = useRetrieveLoanRepaymentsForLoanLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanRepaymentsForLoan: returnedLoanRepayments }) => {
      setHasRetrievedData(true);
      setIsRefreshDataIconButtonDisabled(true);
      setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
      setLoanRepayments(returnedLoanRepayments);
      setShouldRefetchData(false);

      onLoadTableDataCompleted?.(returnedLoanRepayments.length, returnedLoanRepayments);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve loan repayments',
      });
    },
  });

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchData(true);
  };

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<LoanRepaymentForLoanRepaymentsTable>();

    return [
      columnHelper.accessor('number', {
        cell: ({ getValue }) => getValue().toString(),
        enableSorting: false,
        header: 'Repayment #',
        size: 110,
      }),
      columnHelper.accessor('status', {
        cell: ({ getValue }) => sentenceCase(getValue()),
        enableSorting: false,
        header: 'Status',
        meta: {
          align: 'center',
        },
        minSize: 0,
        size: 0,
      }),
      columnHelper.accessor('dateDueAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleDateString(),
        enableSorting: false,
        header: 'Due date',
        size: 120,
      }),
      columnHelper.accessor('processedAtUtc', {
        cell: ({ getValue }) => {
          const processedAtUtc = getValue();

          return processedAtUtc ? dayjs(processedAtUtc).toDate().toLocaleDateString() : '-';
        },
        enableSorting: false,
        header: 'Date processed',
        size: 170,
      }),
      columnHelper.accessor('totalAmountInCents', {
        cell: ({ getValue }) => currency(getValue() / 100).format(),
        enableSorting: false,
        header: 'Total amount',
        meta: {
          align: 'right',
        },
        size: 140,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="loan-repayment"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          size: 50,
        },
      ),
    ];
  };

  const { getHeaderGroups, getRowModel } = useReactTable<LoanRepaymentForLoanRepaymentsTable>({
    columns: getTableColumns(),
    data: loanRepayments,
    getCoreRowModel: getCoreRowModel(),
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      onSignOut();
      return;
    }

    executeRetrieveLoanRepaymentsForLoanQuery({
      variables: {
        loanId,
        statuses: filterByStatuses,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      onSignOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <TablePageLayout
      tableComponent={
        <BizPayTable<LoanRepaymentForLoanRepaymentsTable>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No loan repayments for this loan"
          rowModel={getRowModel()}
        />
      }
    />
  );
};

export { LoanRepaymentsTable };
