/* eslint-disable prefer-destructuring */
import { FC, useEffect } from 'react';

import { BIZPAY_COMMON_CONSTANTS, dayjs } from 'common';
import {
  BizPayPrimaryButton,
  DatePickerInput,
  Divider,
  ErrorMessage,
  Grid,
  Group,
  IconCalendar,
  Loader,
  Text,
  TextInput,
  TextInputLabelWarningSection,
  TextInputRightSection,
  useForm,
} from 'ui';

import { SupplierBankAccountsTable } from '../SupplierBankAccountsTable/SupplierBankAccountsTable';

import { loanApplicationInvoiceDetailsFormZodResolver } from './LoanApplicationInvoiceDetailsForm.helpers';
import { LoanApplicationInvoiceDetailsFormData, LoanApplicationInvoiceDetailsFormProps } from './LoanApplicationInvoiceDetailsForm.types';

const { dateFormat } = BIZPAY_COMMON_CONSTANTS;

const LoanApplicationInvoiceDetailsForm: FC<LoanApplicationInvoiceDetailsFormProps<LoanApplicationInvoiceDetailsFormData>> = ({
  children,
  hasInvoiceSupplierBankAccountChanged,
  isFormReadOnly = true,
  isLoading = false,
  loanApplication: { adminStatus, customerEditedFields, id },
  onInvoiceAmountChange,
  onSubmit,
  onSupplierBankAccountChange,
  supplierId,
  values,
}) => {
  const {
    formState: { errors, isDirty, isValid },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<LoanApplicationInvoiceDetailsFormData>({
    mode: 'onChange',
    resolver: loanApplicationInvoiceDetailsFormZodResolver(),
    values,
  });

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    invoiceAmount: invoiceAmountError,
    invoiceDateDueAtUtc: invoiceDateDueAtUtcError,
    invoiceDateIssuedAtUtc: invoiceDateIssuedAtUtcError,
    invoiceNumber: invoiceNumberError,
    invoicePaymentReference: invoicePaymentReferenceError,
    supplierAbn: supplierAbnError,
    supplierEmail: supplierEmailError,
    supplierName: supplierNameError,
    supplierPhone: supplierPhoneError,
  } = errors;

  const invoiceDateDueAtUtcValue = watch('invoiceDateDueAtUtc');
  const invoiceDateIssuedAtUtcValue = watch('invoiceDateIssuedAtUtc');

  return (
    <form id="loan-application-invoice-details-form" onSubmit={handleSubmit(onSubmit)}>
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('supplierName')}
            error={supplierNameError ? <ErrorMessage message={supplierNameError.message} /> : null}
            label="Name"
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={supplierNameError} value={watch('supplierName')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('supplierAbn')}
            error={supplierAbnError ? <ErrorMessage message={supplierAbnError.message} /> : null}
            label={
              <TextInputLabelWarningSection
                isFieldEdited={customerEditedFields?.hasSupplierAbnChanged}
                label="ABN"
                shouldTextInputLabelUseSentenceCase={false}
              />
            }
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={supplierAbnError} value={watch('supplierAbn')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('supplierEmail')}
            error={supplierEmailError ? <ErrorMessage message={supplierEmailError.message} /> : null}
            label={<TextInputLabelWarningSection isFieldEdited={customerEditedFields?.hasSupplierEmailChanged} label="Email" />}
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={supplierEmailError} value={watch('supplierEmail')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('supplierPhone')}
            error={supplierPhoneError ? <ErrorMessage message={supplierPhoneError.message} /> : null}
            label="Phone"
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={supplierPhoneError} value={watch('supplierPhone')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Text mt="md" w="100%" weight="bold">
        Supplier bank account details
      </Text>

      <Divider mb="md" mt="0.25rem" />

      <SupplierBankAccountsTable
        loanApplicationAdminStatus={adminStatus}
        loanApplicationId={id}
        supplierBankAccountId={watch('supplierBankAccountId')}
        supplierId={supplierId}
        onSupplierBankAccountChange={(supplierBankAccountId) => {
          onSupplierBankAccountChange(supplierBankAccountId);
          trigger();
        }}
      />

      <Text mt="md" w="100%" weight="bold">
        Invoice details
      </Text>

      <Divider mb="md" mt="0.25rem" />

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('invoiceNumber')}
            error={invoiceNumberError ? <ErrorMessage message={invoiceNumberError.message} /> : null}
            label="Invoice number"
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={invoiceNumberError} value={watch('invoiceNumber')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('invoicePaymentReference')}
            error={invoicePaymentReferenceError ? <ErrorMessage message={invoicePaymentReferenceError.message} /> : null}
            label="Payment reference"
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={invoicePaymentReferenceError} value={watch('invoicePaymentReference')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <DatePickerInput
            {...register('invoiceDateDueAtUtc')}
            error={invoiceDateDueAtUtcError ? <ErrorMessage message={invoiceDateDueAtUtcError.message?.toString()} /> : null}
            icon={<IconCalendar size={20} stroke={1} />}
            label={<TextInputLabelWarningSection isFieldEdited={customerEditedFields?.hasInvoiceDueDateChanged} label="Date due" />}
            minDate={dayjs()
              .startOf('day')
              .add(Number(process.env.NEXT_PUBLIC_INVOICE_VALIDATION_MAX_DAYS_PRIOR_TO_INVOICE_DUE_DATE), 'day')
              .toDate()}
            placeholder="Please select a date"
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={invoiceDateDueAtUtcError} value={watch('invoiceDateDueAtUtc')} />}
            size="md"
            value={invoiceDateDueAtUtcValue}
            valueFormat={invoiceDateDueAtUtcValue ? 'DD/MM/YYYY' : undefined}
            allowDeselect
            onChange={(value) => {
              setValue('invoiceDateDueAtUtc', value ? dayjs(value).toDate() : undefined, {
                shouldDirty: true,
              });
              trigger('invoiceDateDueAtUtc');
            }}
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <DatePickerInput
            {...register('invoiceDateIssuedAtUtc')}
            error={invoiceDateIssuedAtUtcError ? <ErrorMessage message={invoiceDateIssuedAtUtcError.message?.toString()} /> : null}
            icon={<IconCalendar size={20} stroke={1} />}
            label="Date issued"
            placeholder="Please select a date"
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={invoiceDateIssuedAtUtcError} value={watch('invoiceDateIssuedAtUtc')} />}
            size="md"
            value={invoiceDateIssuedAtUtcValue}
            valueFormat={invoiceDateIssuedAtUtcValue ? dateFormat : undefined}
            allowDeselect
            onChange={(value) => {
              setValue('invoiceDateIssuedAtUtc', value ? dayjs(value).toDate() : undefined, {
                shouldDirty: true,
              });
              trigger('invoiceDateIssuedAtUtc');
            }}
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('invoiceAmount')}
            error={invoiceAmountError ? <ErrorMessage message={invoiceAmountError.message} /> : null}
            icon="$"
            label={<TextInputLabelWarningSection isFieldEdited={customerEditedFields?.hasInvoiceAmountChanged} label="Invoice amount" />}
            readOnly={isFormReadOnly}
            rightSection={<TextInputRightSection error={invoiceAmountError} value={watch('invoiceAmount')} />}
            size="md"
            onBlur={({ target: { value } }) => onInvoiceAmountChange(value)}
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('uploadByUserFullName')} label="Uploaded by" size="md" readOnly />
        </Grid.Col>
      </Grid>

      {children}

      {!isFormReadOnly && (
        <Group align="center" mt="xl" position="right">
          <BizPayPrimaryButton disabled={(isLoading || !isDirty || !isValid) && !hasInvoiceSupplierBankAccountChanged} type="submit">
            {isLoading ? <Loader size="xs" /> : 'Save changes'}
          </BizPayPrimaryButton>
        </Group>
      )}
    </form>
  );
};

export { LoanApplicationInvoiceDetailsForm };
