import { FC, useEffect, useState } from 'react';

import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { currency, dayjs, sentenceCase, useAuthentication } from 'common';
import { useRetrieveLoanTransactionsForLoanLazyQuery } from 'graphql-library';

import { BizPayTable } from '../BizPayTable';
import { RefreshDataControl } from '../RefreshDataControl';
import { TablePageLayout } from '../TablePageLayout';

import { LoanTransactionForLoanTransactionsTable, LoanTransactionsTableProps } from './LoanTransactionsTable.types';

import { useBizPayNotification } from '../../hooks';

const LoanTransactionsTable: FC<LoanTransactionsTableProps> = ({ loanId, onSignOut }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();

  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [loanTransactions, setLoanTransactions] = useState<LoanTransactionForLoanTransactionsTable[]>([]);
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);

  const [executeRetrieveLoanTransactionsForLoanQuery, { refetch }] = useRetrieveLoanTransactionsForLoanLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanTransactionsForLoan: returnedLoanTransactions }) => {
      setHasRetrievedData(true);
      setIsRefreshDataIconButtonDisabled(true);
      setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
      setLoanTransactions(returnedLoanTransactions);
      setShouldRefetchData(false);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve loan transactions',
      });
    },
  });

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchData(true);
  };

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<LoanTransactionForLoanTransactionsTable>();

    return [
      columnHelper.accessor('type', {
        cell: ({ getValue }) => sentenceCase(getValue()),
        enableSorting: false,
        header: 'Type',
        minSize: 0,
        size: 0,
      }),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: false,
        header: 'Date',
        size: 170,
      }),
      columnHelper.accessor('totalAmountInCents', {
        cell: ({ getValue }) => currency(getValue() / 100).format(),
        enableSorting: false,
        header: 'Total amount',
        meta: {
          align: 'right',
        },
        size: 140,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="loan-transaction"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          size: 50,
        },
      ),
    ];
  };

  const { getHeaderGroups, getRowModel } = useReactTable<LoanTransactionForLoanTransactionsTable>({
    columns: getTableColumns(),
    data: loanTransactions,
    getCoreRowModel: getCoreRowModel(),
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      onSignOut();
      return;
    }

    executeRetrieveLoanTransactionsForLoanQuery({
      variables: {
        loanId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      onSignOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <TablePageLayout
      tableComponent={
        <BizPayTable<LoanTransactionForLoanTransactionsTable>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No transactions for this loan"
          rowModel={getRowModel()}
        />
      }
    />
  );
};

export { LoanTransactionsTable };
