import { DocumentNode, gql } from 'graphql-library';

const assignLoanApplicationToStaffMemberMutation: DocumentNode = gql`
  mutation assignLoanApplicationToStaffMember($assignedToStaffMemberId: String!, $id: ID!) {
    assignLoanApplicationToStaffMember(assignedToStaffMemberId: $assignedToStaffMemberId, id: $id) {
      adminStatus
      assignedToStaffMemberId
      id
    }
  }
`;

const retrieveStaffMembersQuery: DocumentNode = gql`
  query retrieveStaffMembers {
    retrieveStaffMembers {
      fullName
      id
      roles
    }
  }
`;

const updateLoanApplicationAdminStatusMutation: DocumentNode = gql`
  mutation updateLoanApplicationAdminStatus($loanApplicationAdminStatus: LoanApplicationAdminStatus!, $id: ID!) {
    updateLoanApplicationAdminStatus(loanApplicationAdminStatus: $loanApplicationAdminStatus, id: $id) {
      adminStatus
      assignedToStaffMemberId
      id
    }
  }
`;

export { assignLoanApplicationToStaffMemberMutation, retrieveStaffMembersQuery, updateLoanApplicationAdminStatusMutation };
