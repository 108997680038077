import { EntityStatus } from 'graphql-library';

import { USE_BIZPAY_UI_THEME_CONSTANTS } from '../../hooks';

const {
  colors: { lightGrayPalette, redPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP = Object.freeze<Record<EntityStatus, string>>({
  Approved: '#c7ffd3',
  Archived: lightGrayPalette[3],
  Suspended: redPalette[2],
});

const ENTITY_STATUS_TO_BORDER_COLOR_MAP = Object.freeze<Record<EntityStatus, string>>({
  Approved: '#66ff87',
  Archived: lightGrayPalette[4],
  Suspended: redPalette[3],
});

export { ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP, ENTITY_STATUS_TO_BORDER_COLOR_MAP };
