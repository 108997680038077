import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import {
  useCreateSupplierBankAccountForLoanApplicationMutation,
  useVerifySupplierBankAccountForLoanApplicationMutation,
} from 'graphql-library';
import { BizPayLoader, BizPayModal, Stack, useBizPayNotification } from 'ui';

import { SupplierBankAccountForm } from '../SupplierBankAccountForm/SupplierBankAccountForm';

import { SupplierBankAccountModalProps } from './SupplierBankAccountModal.types';

import { useSignOut } from '../../hooks';

const SupplierBankAccountModal: FC<SupplierBankAccountModalProps> = ({ isOpen, loanApplicationId, onClose, onCompleted, supplierId }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [executeVerifySupplierBankAccountForLoanApplicationMutation, { loading: isVerifySupplierBankAccountForLoanApplicationLoading }] =
    useVerifySupplierBankAccountForLoanApplicationMutation({
      onCompleted: () => {
        displaySuccessNotification({
          message: 'Supplier bank account has been added and verified successfully',
        });
        onCompleted();
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to verify supplier bank account',
        });
      },
    });

  const [executeCreateSupplierBankAccountMutation, { loading: isCreateSupplierBankAccountLoading }] =
    useCreateSupplierBankAccountForLoanApplicationMutation({
      onCompleted: ({ createSupplierBankAccountForLoanApplication: returnedSupplierBankAccount }) => {
        const { id, accountName, accountNumber, bsb, supplierId } = returnedSupplierBankAccount;

        executeVerifySupplierBankAccountForLoanApplicationMutation({
          variables: {
            id,
            input: {
              accountName,
              accountNumber,
              bsb,
              supplierId,
            },
            loanApplicationId,
          },
        });
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to add supplier bank account',
        });
      },
    });

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isLoading = isCreateSupplierBankAccountLoading || isVerifySupplierBankAccountForLoanApplicationLoading;

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      opened={isOpen}
      size={650}
      title="Add supplier bank account"
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      <Stack h={250}>
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isCreateSupplierBankAccountLoading && <BizPayLoader message="Creating supplier bank account, please wait..." />}

            {isVerifySupplierBankAccountForLoanApplicationLoading && (
              <BizPayLoader message="Verifying supplier bank account, please wait..." />
            )}
          </Stack>
        ) : (
          <SupplierBankAccountForm
            onSubmit={({ accountName, accountNumber, bsb }) => {
              if (!isAuthenticated) {
                signOut();
                return;
              }

              executeCreateSupplierBankAccountMutation({
                variables: {
                  input: {
                    accountName,
                    accountNumber,
                    bsb,
                    supplierId,
                  },
                  loanApplicationId,
                },
              });
            }}
          />
        )}
      </Stack>
    </BizPayModal>
  );
};

export { SupplierBankAccountModal };
