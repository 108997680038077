import { FC } from 'react';

import { useNumberFormatter } from 'common';
import { ProductFeeType } from 'graphql-library';
import {
  Anchor,
  BaseContainer,
  BizPayPrimaryButton,
  Divider,
  FullHeightContainer,
  Group,
  LoanRepaymentsTable,
  LoanProductForm,
  Stack,
  Text,
  LoanTransactionsTable,
} from 'ui';

import { LoanInfoAdditionalDetailsForm } from '../LoanInfoAdditionalDetailsForm';

import { LoanInfoTabProps } from './LoanInfoTab.types';

import { useSignOut } from '../../hooks';

const LoanInfoTab: FC<LoanInfoTabProps> = ({
  loan: {
    funder,
    funderException,
    invoiceDocument,
    isDoubtfulDebt,
    id,
    paymentAgreementDocument,
    product: { code, feeType, feeValue, frequencyType, frequencyValue },
    provision,
  },
}) => {
  const { formatCurrency, formatPercentage } = useNumberFormatter();
  const { signOut } = useSignOut();

  return (
    <Stack>
      <BaseContainer>
        <Stack>
          <Group noWrap>
            <Text w="100%" weight="bold">
              Loan repayment schedule
            </Text>

            <Anchor href={paymentAgreementDocument.signedUrl} target="_blank">
              <BizPayPrimaryButton>Download payment agreement</BizPayPrimaryButton>
            </Anchor>

            <Anchor href={invoiceDocument.signedUrl} ml="xs" target="_blank">
              <BizPayPrimaryButton text-align="right">Open invoice</BizPayPrimaryButton>
            </Anchor>
          </Group>

          <Divider mb="md" mt="0.25rem" />

          <FullHeightContainer>
            <LoanRepaymentsTable loanId={id} onSignOut={signOut} />
          </FullHeightContainer>

          <Text mt="md" w="100%" weight="bold">
            Transactions
          </Text>

          <Divider mb="md" mt="0.25rem" />

          <FullHeightContainer>
            <LoanTransactionsTable loanId={id} onSignOut={signOut} />
          </FullHeightContainer>
        </Stack>

        <Stack mt="md">
          <Text weight="bold">Product</Text>

          <Divider mb="md" mt="0.25rem" />

          <LoanProductForm
            code={code}
            feeValue={feeType === ProductFeeType.Percentage ? formatPercentage(feeValue) : formatCurrency(feeValue)}
            frequencyType={frequencyType}
            frequencyValue={String(frequencyValue)}
          />
        </Stack>

        <Stack mt="md">
          <Text weight="bold">Additional Details</Text>

          <Divider mb="md" mt="0.25rem" />

          <LoanInfoAdditionalDetailsForm
            funder={funder}
            funderException={funderException}
            isDoubtfulDebt={isDoubtfulDebt}
            provision={provision}
          />
        </Stack>
      </BaseContainer>
    </Stack>
  );
};

export { LoanInfoTab };
