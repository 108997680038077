import { pluralize } from 'common';

import { FILE_UPLOAD_CONSTANTS } from './FileUpload.constants';

const generateFileRejectionErrorMessage = () => {
  const acceptedFileTypes = Object.keys(FILE_UPLOAD_CONSTANTS.fileTypeToMimeTypeMap);
  const totalAcceptedFileTypes = acceptedFileTypes.length;

  return `Only ${acceptedFileTypes.map((fileType) => `.${fileType}`).join(', ')} ${pluralize('format', totalAcceptedFileTypes)} ${pluralize(
    'is',
    totalAcceptedFileTypes,
  )} supported`;
};

export { generateFileRejectionErrorMessage };
