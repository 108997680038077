import { FC, ReactElement, useEffect, useState } from 'react';

import { useAuthentication, useCookie } from 'common';
import { useRetrieveLoggedInStaffMemberDetailsForUserDropdownMenuLazyQuery } from 'graphql-library';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import {
  BizPayAdminPortalBadge,
  BizPayAppLayout,
  BizPayNavigationBar,
  BizPayNavigationLink,
  BizPayNavigationLinkProps,
  BizPayRefreshAppButton,
  IconLogout,
  useBizPayNotification,
  UserDropdownMenu,
} from 'ui';

import { AppLayoutProps } from './AppLayout.types';

import { PublicRoute, useNavigationRoutes, useSignOut } from '../../hooks';

const AppLayout: FC<AppLayoutProps> = ({ children }) => {
  const {
    publicRuntimeConfig: { version: appVersion },
  } = getConfig();

  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { createAppVersionCookie, getAppVersionCookie } = useCookie();
  const { buildNavigationBarLinks } = useNavigationRoutes();
  const { route } = useRouter();
  const { signOut } = useSignOut();

  const [canRefreshApp, setCanRefreshApp] = useState<boolean>(false);
  const [hasMounted, setHasMounted] = useState<boolean>(false);
  const [headerRightSectionComponent, setHeaderRightSectionComponent] = useState<ReactElement>();
  const [navigationBarLinks, setNavigationBarLinks] = useState<BizPayNavigationLinkProps[]>();
  const [shouldSignOut, setShouldSignOut] = useState<boolean>(false);

  const [
    executeRetrieveLoggedInStaffMemberDetailsForUserDropdownMenuQuery,
    { called: hasRetrieveLoggedInStaffMemberDetailsForUserDropdownMenuQueryBeenCalled },
  ] = useRetrieveLoggedInStaffMemberDetailsForUserDropdownMenuLazyQuery({
    onCompleted: ({ retrieveLoggedInStaffMember: { email, firstName, lastName } }) => {
      setHeaderRightSectionComponent(
        <UserDropdownMenu
          email={email}
          fullName={`${firstName} ${lastName}`}
          onSignOut={() => {
            handleSignOut();
          }}
        />,
      );
    },
    onError: () => {
      if (!getIsAuthenticated()) {
        return;
      }

      displayErrorNotification({
        message: 'Unable to retrieve user details',
      });
    },
  });

  const handleRefreshApp = () => {
    createAppVersionCookie({
      optionalCookieAttributesInput: {
        path: '/',
      },
      value: appVersion,
    });

    location.reload();
  };

  const handleSignOut = () => {
    setHeaderRightSectionComponent(undefined);
    setNavigationBarLinks(undefined);
    setShouldSignOut(true);
  };

  const isAuthenticated = getIsAuthenticated();
  const currentAppVersion = getAppVersionCookie();

  useEffect(() => {
    setHasMounted(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!currentAppVersion) {
      createAppVersionCookie({
        optionalCookieAttributesInput: {
          path: '/',
        },
        value: appVersion,
      });

      return;
    }

    setCanRefreshApp(appVersion !== currentAppVersion);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appVersion, currentAppVersion]);

  useEffect(() => {
    if (!isAuthenticated) {
      handleSignOut();
      return;
    }

    setNavigationBarLinks(buildNavigationBarLinks(route));

    if (hasRetrieveLoggedInStaffMemberDetailsForUserDropdownMenuQueryBeenCalled) {
      return;
    }

    // TODO: Work out why this query is being called during the logout process
    executeRetrieveLoggedInStaffMemberDetailsForUserDropdownMenuQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, route]);

  useEffect(() => {
    if (!shouldSignOut) {
      return;
    }

    signOut();
    setShouldSignOut(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldSignOut]);

  if (!hasMounted) {
    return null;
  }

  return (
    <BizPayAppLayout
      appVersion={currentAppVersion}
      headerRightSectionComponent={headerRightSectionComponent}
      logoBadgeComponent={<BizPayAdminPortalBadge ml="sm" mt={5} />}
      navBarComponent={
        <>
          {navigationBarLinks && (
            <BizPayNavigationBar
              backgroundColor="bizpay-light-gray.3"
              dividerColor="white"
              navigationLinks={navigationBarLinks}
              signOutNavigationLinkComponent={
                <BizPayNavigationLink
                  hoverBackgroundColor="bizpay-light-gray.2"
                  icon={<IconLogout size={20} />}
                  label="Sign out"
                  linkOptions={{
                    onClick: handleSignOut,
                  }}
                />
              }
            />
          )}
        </>
      }
      refreshAppButtonComponent={canRefreshApp && isAuthenticated ? <BizPayRefreshAppButton onClick={() => handleRefreshApp()} /> : <></>}
    >
      {(route === PublicRoute.SignIn || isAuthenticated) && children}
    </BizPayAppLayout>
  );
};

export { AppLayout };
