import { DocumentNode, gql } from 'graphql-library';

const supplierBankAccountFragment: DocumentNode = gql`
  fragment supplierBankAccountFragment on SupplierBankAccount {
    accountName
    accountNumber
    bsb
    eftsureVerificationReason
    eftsureVerificationStatus
    eftsureVerifiedAtUtc
    id
    isEftsureVerified
    supplierId
  }
`;

const askEftsureToVerifySupplierBankAccountForLoanApplicationMutation: DocumentNode = gql`
  mutation askEftsureToVerifySupplierBankAccountForLoanApplication($id: ID!, $loanApplicationId: String!) {
    askEftsureToVerifySupplierBankAccountForLoanApplication(id: $id, loanApplicationId: $loanApplicationId) {
      id
    }
  }
`;

const createSupplierBankAccountForLoanApplicationMutation: DocumentNode = gql`
  ${supplierBankAccountFragment}

  mutation createSupplierBankAccountForLoanApplication($input: CreateSupplierBankAccountInput!, $loanApplicationId: String!) {
    createSupplierBankAccountForLoanApplication(input: $input, loanApplicationId: $loanApplicationId) {
      ...supplierBankAccountFragment
    }
  }
`;

const deleteSupplierBankAccountForLoanApplicationMutation: DocumentNode = gql`
  mutation deleteSupplierBankAccountForLoanApplication($id: ID!) {
    deleteSupplierBankAccountForLoanApplication(id: $id) {
      id
    }
  }
`;

const retrieveSupplierBankAccountsForSupplierQuery: DocumentNode = gql`
  ${supplierBankAccountFragment}

  query retrieveSupplierBankAccountsForSupplier($supplierId: String!) {
    retrieveSupplierBankAccountsBySupplierId(supplierId: $supplierId) {
      ...supplierBankAccountFragment
    }
  }
`;

const selfCertifySupplierBankAccountForLoanApplicationMutation: DocumentNode = gql`
  ${supplierBankAccountFragment}

  mutation selfCertifySupplierBankAccountForLoanApplication($id: ID!, $loanApplicationId: String!, $reason: String!) {
    selfCertifySupplierBankAccountWithEftsureForLoanApplication(id: $id, loanApplicationId: $loanApplicationId, reason: $reason) {
      ...supplierBankAccountFragment
    }
  }
`;

const verifySupplierBankAccountForLoanApplicationMutation: DocumentNode = gql`
  mutation verifySupplierBankAccountForLoanApplication(
    $id: ID!
    $input: VerifySupplierBankAccountWithEftsureInput!
    $loanApplicationId: String!
  ) {
    verifySupplierBankAccountWithEftsureForLoanApplication(id: $id, input: $input, loanApplicationId: $loanApplicationId) {
      id
    }
  }
`;

export {
  askEftsureToVerifySupplierBankAccountForLoanApplicationMutation,
  createSupplierBankAccountForLoanApplicationMutation,
  deleteSupplierBankAccountForLoanApplicationMutation,
  retrieveSupplierBankAccountsForSupplierQuery,
  selfCertifySupplierBankAccountForLoanApplicationMutation,
  verifySupplierBankAccountForLoanApplicationMutation,
};
