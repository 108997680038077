import { FC } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayAdminPortalDynamicNextJsTitle, FullHeightContainer, FullPageLayout, PageHeading } from 'ui';

import { InvoicesTable } from '../InvoicesTable';

import { useNavigationRoutes } from '../../hooks';

const InvoicesPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <FullPageLayout
        mainComponent={
          <FullHeightContainer>
            <InvoicesTable
              paginationOptions={{
                isPaginationEnabled: true,
              }}
            />
          </FullHeightContainer>
        }
        pageHeadingComponent={<PageHeading heading={title} size="h4" />}
      />
    </>
  );
};

export { InvoicesPage };
