import { zodResolver, z, accountNumberZodEffect, bsbZodEffect } from 'ui';

const supplierBankAccountFormZodResolver = () => {
  return zodResolver(
    z.object({
      accountName: z
        .string()
        .min(1, {
          message: 'Please enter an account name',
        })
        .refine((value) => value !== '-', {
          message: 'Please enter an account name',
        }),
      accountNumber: accountNumberZodEffect,
      bsb: bsbZodEffect,
    }),
  );
};

export { supplierBankAccountFormZodResolver };
