import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication } from 'common';
import { useRetrieveNotesForLoanApplicationLazyQuery } from 'graphql-library';
import { BizPayLoader, MessageBox, ScrollArea, Text, useBizPayNotification } from 'ui';

import { LoanApplicationNote, LoanApplicationNotesProps } from './LoanApplicationNotes.types';

import { useSignOut } from '../../hooks';

const LoanApplicationNotes: FC<LoanApplicationNotesProps> = ({
  loanApplicationId,
  refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData },
  shouldDisplayNoLoanApplicationNotesMessage = false,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [notesForLoanApplication, setNotesForLoanApplication] = useState<LoanApplicationNote[]>([]);

  const [executeRetrieveNotesForLoanApplicationQuery, { loading: isRetrieveNotesForLoanApplicationLoading, refetch }] =
    useRetrieveNotesForLoanApplicationLazyQuery({
      onCompleted: ({ retrieveNotesForLoanApplication: returnNoteForLoanApplications }) => {
        setNotesForLoanApplication(returnNoteForLoanApplications);

        if (onRefetchDataCompleted) {
          onRefetchDataCompleted();
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve the notes for this loan application',
        });
      },
    });

  const isAuthenticated = getIsAuthenticated();
  const totalNotesForLoanApplication = notesForLoanApplication.length;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveNotesForLoanApplicationQuery({
      variables: {
        loanApplicationId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  return (
    <>
      {isRetrieveNotesForLoanApplicationLoading && <BizPayLoader message="Retrieving the notes for this loan application" />}

      {totalNotesForLoanApplication ? (
        <ScrollArea h={370} mt="xl">
          {notesForLoanApplication.map(({ createdAtUtc, id, message, submittedByStaffMember: { fullName } }, index) => {
            return (
              <MessageBox
                key={`note-${id}`}
                author={fullName}
                cardProps={{
                  mb: index !== totalNotesForLoanApplication - 1 ? 'md' : undefined,
                }}
                text={message}
                timestamp={dayjs(createdAtUtc).toDate().toLocaleString()}
              />
            );
          })}
        </ScrollArea>
      ) : (
        <>{shouldDisplayNoLoanApplicationNotesMessage && <Text>No loan application notes</Text>}</>
      )}
    </>
  );
};

export { LoanApplicationNotes };
