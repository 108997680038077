import { FC } from 'react';

import { Flex, Group, Pagination, Text } from '@mantine/core';
import { useTablePagination } from 'graphql-library';

import { BizPayPaginationProps } from './BizPayPagination.types';

const BizPayPagination: FC<BizPayPaginationProps> = ({
  description,
  descriptionFontSize = 14,
  hasRetrievedData,
  isEnabled,
  onChange,
  pageSize,
  totalPages,
  totalRecords,
  value,
  ...otherProps
}) => {
  const { canPaginate } = useTablePagination();

  const isPaginationComponentVisible = canPaginate(pageSize, totalRecords) && isEnabled;

  return (
    <>
      {hasRetrievedData && (
        <Group position="right" spacing="sm" w="100%">
          {description && (
            <Flex align="center" mb={!isPaginationComponentVisible ? 3 : undefined} mr={isPaginationComponentVisible ? 10 : undefined}>
              <Text size={descriptionFontSize}>{description}</Text>
            </Flex>
          )}

          {isPaginationComponentVisible && (
            <Flex align="center">
              <Pagination siblings={2} total={totalPages} value={value} withEdges onChange={onChange} {...otherProps} />
            </Flex>
          )}
        </Group>
      )}
    </>
  );
};

export { BizPayPagination };
