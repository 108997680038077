import { isNil, pluralize } from 'common';

import { DEFAULT_TABLE_PAGE_SIZE } from './useTablePagination.constants';
import { UseTablePagination, UseTablePaginationOptions } from './useTablePagination.types';

const useTablePagination = (options?: UseTablePaginationOptions): UseTablePagination => {
  const { pageSize } = options ?? {};

  const calculateRecordsToSkip: UseTablePagination['calculateRecordsToSkip'] = (isPaginationEnabled, pageNumber, pageSize) => {
    if (!isPaginationEnabled) {
      return undefined;
    }

    return pageNumber === 1 ? 0 : (pageNumber - 1) * pageSize;
  };

  const calculateTotalPages: UseTablePagination['calculateTotalPages'] = (pageSize, totalRecords) => {
    const minimumPages = Math.floor(totalRecords / pageSize);
    const remainder = totalRecords % pageSize;

    return remainder === 0 ? minimumPages : minimumPages + 1;
  };

  const canPaginate: UseTablePagination['canPaginate'] = (pageSize, totalRecords) => {
    return pageSize < totalRecords;
  };

  const generatePaginationResultsDescription: UseTablePagination['generatePaginationResultsDescription'] = (
    isPaginationEnabled,
    pageNumber,
    pageSize,
    recordNumber,
    totalRecords,
  ) => {
    if (!isPaginationEnabled || totalRecords === 0) {
      return undefined;
    }

    const maxPaginationEnd = pageSize * pageNumber;
    const paginationEnd = maxPaginationEnd > totalRecords ? totalRecords : maxPaginationEnd;
    const paginationStart = isNil(recordNumber) ? 1 : recordNumber + 1;

    return `Showing ${paginationStart}-${paginationEnd} of ${totalRecords} ${pluralize('record', totalRecords)}`;
  };

  const getInitialPageSize: UseTablePagination['getInitialPageSize'] = () => {
    return pageSize ?? DEFAULT_TABLE_PAGE_SIZE;
  };

  const getPageNumberFromQuerystring: UseTablePagination['getPageNumberFromQuerystring'] = (querystring) => {
    if (!querystring) {
      return 1;
    }

    const querystringAsNumber = Number(querystring);

    return isNaN(querystringAsNumber) ? 1 : querystringAsNumber;
  };

  return {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  };
};

export { useTablePagination };
