import { FC } from 'react';

import { BaseContainer } from '../BaseContainer';

import { FullHeightContainerProps } from './FullHeightContainer.types';

const FullHeightContainer: FC<FullHeightContainerProps> = ({ boxShadowCss, children, ...otherProps }) => {
  return (
    <BaseContainer boxShadowCss={boxShadowCss} h="100%" {...otherProps}>
      {children}
    </BaseContainer>
  );
};

export { FullHeightContainer };
