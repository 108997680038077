import { sentenceCase } from 'common';
import { EftsureVerificationStatus } from 'graphql-library';

const EFTSURE_VERIFICATION_STATUS_TO_DESCRIPTION_MAP: Record<EftsureVerificationStatus, string> = {
  [EftsureVerificationStatus.CrossMatch]: 'Eftsure verified',
  [EftsureVerificationStatus.IncorrectBankAccountName]: 'Bank account name is incorrect',
  [EftsureVerificationStatus.IncorrectBankDetails]: 'Account details are incorrect',
  [EftsureVerificationStatus.NotVerified]: sentenceCase(EftsureVerificationStatus.NotVerified),
  [EftsureVerificationStatus.Pending]: 'Pending verification',
  [EftsureVerificationStatus.SelfCertified]: sentenceCase(EftsureVerificationStatus.SelfCertified),
  [EftsureVerificationStatus.Verified]: 'Eftsure verified',
};

const GREEN_THUMBS_LEFT_ICON_STATUS = [EftsureVerificationStatus.SelfCertified];

const GREEN_THUMBS_UP_ICON_STATUS = [EftsureVerificationStatus.Verified, EftsureVerificationStatus.CrossMatch];

const RED_THUMBS_UP_ICON_STATUS = [EftsureVerificationStatus.IncorrectBankAccountName, EftsureVerificationStatus.IncorrectBankDetails];

const YELLOW_THUMBS_LEFT_ICON_STATUS = [EftsureVerificationStatus.NotVerified, EftsureVerificationStatus.Pending];

export {
  EFTSURE_VERIFICATION_STATUS_TO_DESCRIPTION_MAP,
  GREEN_THUMBS_LEFT_ICON_STATUS,
  GREEN_THUMBS_UP_ICON_STATUS,
  RED_THUMBS_UP_ICON_STATUS,
  YELLOW_THUMBS_LEFT_ICON_STATUS,
};
