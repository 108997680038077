import { FC, useEffect, useState } from 'react';

import { dayjs, useAuthentication } from 'common';
import { useRetrievePaginatedAuditLogsForLoanApplicationLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LoanApplicationAuditLogsTableProps, PaginatedAuditLogsForLoanApplication } from './LoanApplicationAuditLogsTable.types';

import { useSignOut } from '../../hooks';

const LoanApplicationAuditLogsTable: FC<LoanApplicationAuditLogsTableProps> = ({
  loanApplicationId,
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
  refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [auditLogs, setAuditLogs] = useState<PaginatedAuditLogsForLoanApplication[]>([]);
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [
    executeRetrievePaginatedAuditLogsForLoanApplicationQuery,
    { loading: isRetrievePaginatedLoanApplicationAuditLogsLoading, refetch },
  ] = useRetrievePaginatedAuditLogsForLoanApplicationLazyQuery({
    onCompleted: ({ retrievePaginatedAuditLogs: { count, data } }) => {
      setHasRetrievedData(true);
      setAuditLogs(data);
      setTotalPages(calculateTotalPages(currentPageSize, count));
      setTotalRecords(count);

      if (onRefetchDataCompleted) {
        onRefetchDataCompleted();
      }

      if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
        push(
          `${route.replace('[id]', loanApplicationId)}?tab=logs&returnTab=${query.returnTab}&pageNumber=${currentPageNumber}`,
          undefined,
          {
            shallow: true,
          },
        );
      }
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve audit logs for this loan application',
      });
    },
  });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<PaginatedAuditLogsForLoanApplication>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('description', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Message',
        minSize: 0,
        size: 0,
      }),
      columnHelper.accessor('staffMember', {
        cell: ({ getValue }) => {
          const staffMember = getValue();
          return staffMember ? staffMember.fullName : '-';
        },
        enableSorting: false,
        header: 'Staff member',
        minSize: 0,
        size: 150,
      }),
      columnHelper.accessor('user.fullName', {
        cell: ({ getValue }) => getValue() ?? '-',
        enableSorting: false,
        header: 'User',
        minSize: 0,
        size: 150,
      }),
      columnHelper.accessor('createdAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: isSortingEnabled,
        header: 'Date created',
        size: 200,
      }),
    ];
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<PaginatedAuditLogsForLoanApplication>({
    columns: getTableColumns(totalRecords),
    data: auditLogs,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrievePaginatedLoanApplicationAuditLogsLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedAuditLogsForLoanApplicationQuery({
      variables: {
        loanApplicationId,
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  return (
    <TablePageLayout
      loadingMessage={isLoading ? 'Retrieving logs for the loan application...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<PaginatedAuditLogsForLoanApplication>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No logs found for this loan application"
          rowModel={getRowModel()}
        />
      }
    />
  );
};

export { LoanApplicationAuditLogsTable };
