import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { useCreatePartnerMutation } from 'graphql-library';
import { BizPayLoader, BizPayModal, Stack, Text, useBizPayNotification } from 'ui';

import { PartnerForm } from '../PartnerForm';

import { PartnerModalProps } from './PartnerModal.types';

import { useSignOut } from '../../hooks';

const PartnerModal: FC<PartnerModalProps> = ({ isOpen, onClose, onCompleted }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [executeCreatePartnerMutation, { loading: isCreatePartnerLoading }] = useCreatePartnerMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Partner has been added successfully',
      });

      onCompleted();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to add the partner',
      });
    },
  });

  const handleClose = () => {
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <BizPayModal
      closeOnClickOutside={!isCreatePartnerLoading}
      opened={isOpen}
      size={650}
      title="Create partner"
      withCloseButton={!isCreatePartnerLoading}
      onClose={handleClose}
    >
      <Stack h={250}>
        {isCreatePartnerLoading ? (
          <Stack align="center" h="100%" justify="center">
            <BizPayLoader message="Creating partner, please wait..." />
          </Stack>
        ) : (
          <>
            <Text mt="md">Please ensure that the name entered is accurate and case sensitive.</Text>

            <PartnerForm
              onSubmit={({ name }) => {
                if (!isAuthenticated) {
                  signOut();
                  return;
                }

                executeCreatePartnerMutation({
                  variables: {
                    name,
                  },
                });
              }}
            />
          </>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { PartnerModal };
