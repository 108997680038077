import { FC } from 'react';

import { BIZPAY_COMMON_CONSTANTS } from 'common';

import { DynamicNextJsTitle } from '../DynamicNextJsTitle';

import { BizPayAdminPortalDynamicNextJsTitleProps } from './BizPayAdminPortalDynamicNextJsTitle.types';

const {
  appName: { adminPortal },
} = BIZPAY_COMMON_CONSTANTS;

const BizPayAdminPortalDynamicNextJsTitle: FC<BizPayAdminPortalDynamicNextJsTitleProps> = ({ headComponent, pageTitle }) => {
  return <DynamicNextJsTitle appName={adminPortal} headComponent={headComponent} pageTitle={pageTitle} />;
};

export { BizPayAdminPortalDynamicNextJsTitle };
