import { FC, useEffect, useState } from 'react';

import { get, kebabCase, useAuthentication } from 'common';
import { useRetrieveUserLazyQuery, useUpdateUserMutation } from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  Anchor,
  BizPayAdminPortalDynamicNextJsTitle,
  BizPayLoadingOverlay,
  Breadcrumbs,
  EntityActionIcon,
  FullHeightContainer,
  FullPageLayout,
  Group,
  PageHeading,
  Stack,
  Text,
  UnauthorizedAccessMessage,
  UserProfileForm,
  useBizPayNotification,
} from 'ui';

import { generateEntityPageRoute } from '../EntityPage';

import { User, UserPageProps } from './UserPage.types';

import { ProtectedRoute, useNavigationRoutes, useSignOut } from '../../hooks';

const HTTP_STATUS_CODE_FORBIDDEN = 403;

const UserPage: FC<UserPageProps> = ({ id }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { findPageByRoute } = useNavigationRoutes();
  const { route, push } = useRouter();
  const { signOut } = useSignOut();

  const [httpStatusCodes, setHttpStatusCodes] = useState<number[]>([]);
  const [user, setUser] = useState<User>();

  const [executeRetrieveUserQuery, { loading: isRetrieveUserLoading }] = useRetrieveUserLazyQuery({
    onCompleted: ({ retrieveUser: returnedUser }) => {
      setUser(returnedUser);
    },
    onError: ({ graphQLErrors }) => {
      setHttpStatusCodes(
        graphQLErrors.flatMap(({ extensions }): number[] => {
          const statusCode = get(extensions, 'response.statusCode');

          if (!statusCode) {
            return [];
          }

          return [Number(statusCode)];
        }),
      );
    },
  });

  const [executeUpdateUserMutation, { loading: isUpdateUserLoading }] = useUpdateUserMutation({
    onCompleted: ({ updateUserForStaffMember: returnedUser }) => {
      setUser(returnedUser);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update user',
      });
    },
  });

  const handleBreadcrumbItemClick = (route: string) => {
    push(route);
  };

  const { title } = findPageByRoute(route);

  const isAuthenticated = getIsAuthenticated();
  const isLoading = isRetrieveUserLoading || isUpdateUserLoading;

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveUserQuery({
      variables: {
        id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <FullPageLayout
        mainComponent={
          <>
            {httpStatusCodes.includes(HTTP_STATUS_CODE_FORBIDDEN) ? (
              <UnauthorizedAccessMessage />
            ) : (
              <FullHeightContainer style={{ position: 'relative' }}>
                {isRetrieveUserLoading && <BizPayLoadingOverlay message="Retrieving user details..." />}

                {isUpdateUserLoading && <BizPayLoadingOverlay message="Updating user..." />}

                {user && (
                  <UserProfileForm
                    entityActionIcon={
                      <EntityActionIcon
                        onClick={() => {
                          push(generateEntityPageRoute(user.entity.id));
                        }}
                      />
                    }
                    initialDefaultValues={{
                      email: user.email,
                      entityName: user.entity.name,
                      firstName: user.firstName,
                      lastName: user.lastName,
                      mobileNumber: user.mobileNumber,
                    }}
                    isLoading={isLoading}
                    onSubmit={({ firstName, lastName, mobileNumber }) => {
                      if (!getIsAuthenticated()) {
                        signOut();
                        return;
                      }

                      executeUpdateUserMutation({
                        variables: {
                          id,
                          input: {
                            firstName,
                            lastName,
                            mobileNumber,
                          },
                        },
                      });
                    }}
                  />
                )}
              </FullHeightContainer>
            )}
          </>
        }
        pageHeadingComponent={
          <Group align="center" mb="md" position="center" w="100%">
            <Stack justify="center" w="100%">
              <PageHeading
                flexContainerProps={{
                  mb: 0,
                }}
                heading={title}
                size="h4"
              />

              <Group align="center" ml={1} mt={5}>
                <Breadcrumbs separator=">">
                  {[
                    {
                      label: 'Users',
                      route: ProtectedRoute.Users,
                    },
                    {
                      label: (
                        <Group noWrap>
                          <Text weight="bold">User ID:</Text>
                          <Text> {id}</Text>
                        </Group>
                      ),
                    },
                  ].map(({ label, route: breadcrumbRoute }) => {
                    const key = kebabCase(breadcrumbRoute);

                    return breadcrumbRoute ? (
                      <Anchor key={key} weight="bold" onClick={() => handleBreadcrumbItemClick(breadcrumbRoute)}>
                        {label}
                      </Anchor>
                    ) : (
                      <Text key={key}>{label}</Text>
                    );
                  })}
                </Breadcrumbs>
              </Group>
            </Stack>
          </Group>
        }
      />
    </>
  );
};

export { UserPage };
