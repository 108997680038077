import { FC, useState } from 'react';

import { GoogleLogin } from '@react-oauth/google';
import { AsyncReturnType, axios, AxiosError, LoggedInUserData, sentenceCase, useCookie, useDomain, useJwtDecoder } from 'common';
import { Authentication } from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayAdminPortalDynamicNextJsTitle, BizPayLoadingOverlay, DefaultPageLayout, Stack, useBizPayNotification } from 'ui';

import { ProtectedRoute } from '../../hooks';

const SignInPage: FC = () => {
  const { displayErrorNotification } = useBizPayNotification();
  const { createAccessTokenCookie, createRefreshTokenCookie, isSecureCookie } = useCookie();
  const { getDomain } = useDomain();
  const { getDecodedJwt, getJwtExpiryDate } = useJwtDecoder<LoggedInUserData>();
  const { push } = useRouter();

  const [isSigningIn, setIsSigningIn] = useState<boolean>(false);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle="Sign in" />

      {isSigningIn && <BizPayLoadingOverlay message="Signing in..." />}

      <DefaultPageLayout
        mainComponent={
          <Stack align="center" h="100%" justify="center">
            <GoogleLogin
              hosted_domain="bizpay.com" // TODO: Use an environment variable
              shape="pill"
              text="signin_with"
              useOneTap
              onError={() => {
                displayErrorNotification({
                  message: 'Unable to sign in with the selected Google account',
                });

                setIsSigningIn(false);
              }}
              onSuccess={async ({ credential: idToken }) => {
                setIsSigningIn(true);

                let response: AsyncReturnType<typeof axios.post<Authentication>>;

                try {
                  response = await axios.post<Authentication>(
                    `${String(process.env.NEXT_PUBLIC_API_BASE_URL)}${String(
                      process.env.NEXT_PUBLIC_API_ENDPOINT_PATH_GOOGLE_AUTHENTICATION,
                    )}`,
                    {
                      idToken,
                    },
                  );
                } catch (error) {
                  displayErrorNotification({
                    message: sentenceCase((error as AxiosError).message),
                  });

                  setIsSigningIn(false);
                  return;
                }

                const {
                  data: { accessToken, refreshToken },
                } = response;

                const domain = getDomain();
                const secure = isSecureCookie(process.env.NEXT_PUBLIC_USE_SECURE_COOKIE);

                const accessTokenExpiryDate = getJwtExpiryDate(getDecodedJwt(accessToken));

                createAccessTokenCookie({
                  optionalCookieAttributesInput: {
                    domain,
                    expires: accessTokenExpiryDate?.toUTCString(),
                    secure,
                  },
                  value: accessToken,
                });

                if (refreshToken) {
                  const refreshTokenExpiryDate = getJwtExpiryDate(getDecodedJwt(refreshToken));

                  createRefreshTokenCookie({
                    optionalCookieAttributesInput: {
                      domain,
                      expires: refreshTokenExpiryDate?.toUTCString(),
                      secure,
                    },
                    value: refreshToken,
                  });
                }

                push(ProtectedRoute.LoanApplications);
              }}
            />
          </Stack>
        }
      />
    </>
  );
};

export { SignInPage };
