import { FC } from 'react';

import { Select } from '@mantine/core';
import { pluralize, sentenceCase } from 'common';
import { useForm } from 'react-hook-form';

import { AssignStatusFormData, AssignStatusFormProps } from './AssignStatusForm.types';

const AssignStatusForm: FC<AssignStatusFormProps> = ({
  backgroundColor,
  dropdownListOptions,
  isReadOnly,
  label,
  onChange,
  selectedStatus,
}) => {
  const { register } = useForm<AssignStatusFormData>();

  const lowerCaseLabel = label.toLowerCase();

  return (
    <form id="assign-status-form">
      <Select
        {...register('status')}
        data={dropdownListOptions}
        initiallyOpened={false}
        label={sentenceCase(label)}
        nothingFound={`No ${pluralize(lowerCaseLabel)} found`}
        placeholder={`Select a ${lowerCaseLabel}`}
        readOnly={isReadOnly}
        size="md"
        sx={
          backgroundColor
            ? () => ({
                input: {
                  backgroundColor: backgroundColor ? `${backgroundColor} !important` : 'unset',
                },
              })
            : undefined
        }
        value={selectedStatus}
        w="100%"
        searchable
        onChange={(value) => {
          if (!value) {
            return;
          }

          onChange(value);
        }}
      />
    </form>
  );
};

export { AssignStatusForm };
