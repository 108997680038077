import { HOURS_PER_DAY, MILLISECONDS_PER_SECOND, MINUTES_PER_HOUR, SECONDS_PER_MINUTE } from './useUnitConversion.constants';
import { UseUnitConversion } from './useUnitConversion.types';

const getByteSize: UseUnitConversion['getByteSize'] = (value) => {
  return new Blob([value]).size;
};

const getBytesInKiloByte: UseUnitConversion['getBytesInKiloByte'] = () => {
  return Math.pow(2, 10);
};

const getGigabytesFromBytes: UseUnitConversion['getGigabytesFromBytes'] = (value) => {
  return value / Math.pow(getBytesInKiloByte(), 3);
};

const getMegabytesFromBytes: UseUnitConversion['getMegabytesFromBytes'] = (value) => {
  return value / Math.pow(getBytesInKiloByte(), 2);
};

const getMillisecondsFromSeconds: UseUnitConversion['getMillisecondsFromSeconds'] = (value) => {
  return value * MILLISECONDS_PER_SECOND;
};

const getMillisecondsFromMinutes: UseUnitConversion['getMillisecondsFromMinutes'] = (value) => {
  return value * getMillisecondsFromSeconds(SECONDS_PER_MINUTE);
};

const getMillisecondsFromHours: UseUnitConversion['getMillisecondsFromHours'] = (value) => {
  return value * getMillisecondsFromMinutes(MINUTES_PER_HOUR);
};

const getMillisecondsFromDays: UseUnitConversion['getMillisecondsFromDays'] = (value) => {
  return value * getMillisecondsFromHours(HOURS_PER_DAY);
};

export {
  getByteSize,
  getBytesInKiloByte,
  getGigabytesFromBytes,
  getMegabytesFromBytes,
  getMillisecondsFromDays,
  getMillisecondsFromHours,
  getMillisecondsFromMinutes,
  getMillisecondsFromSeconds,
};
