import { useRouter } from 'next/router';
import { BizPayNavigationLinkProps } from 'ui';

import { USE_NAVIGATION_ROUTE_CONSTANTS } from './useNavigationRoutes.constants';
import { UseNavigationRoutes, ProtectedSideMenuNavigationRoutes } from './useNavigationRoutes.types';

const { sideMenuRoutes } = USE_NAVIGATION_ROUTE_CONSTANTS;

const useNavigationRoutes = (): UseNavigationRoutes => {
  const { push } = useRouter();

  const buildNavigationBarLinks: UseNavigationRoutes['buildNavigationBarLinks'] = (currentRoute: string) => {
    const baseCurrentRoute = extractBaseRoute(currentRoute);

    return Object.keys(sideMenuRoutes).map((sideMenuRoute): BizPayNavigationLinkProps => {
      const baseSideMenuRoute = extractBaseRoute(sideMenuRoute);

      const { menuIcon, title } = sideMenuRoutes[baseSideMenuRoute as ProtectedSideMenuNavigationRoutes];

      return {
        hoverBackgroundColor: 'bizpay-light-gray.2',
        icon: menuIcon,
        isActive: baseCurrentRoute === baseSideMenuRoute,
        label: title,
        linkOptions: {
          onClick: () => push(sideMenuRoute),
        },
      };
    });
  };

  const extractBaseRoute = (route: string) => {
    const routeParts = route.split('/');
    return routeParts.slice(0, 2).join('/');
  };

  const findPageByRoute: UseNavigationRoutes['findPageByRoute'] = (route: string) => {
    const baseRoute = extractBaseRoute(route);
    return sideMenuRoutes[baseRoute as ProtectedSideMenuNavigationRoutes];
  };

  return {
    buildNavigationBarLinks,
    findPageByRoute,
  };
};

export { useNavigationRoutes };
