import { EftsureVerificationStatus } from 'graphql-library';

import { EFTSURE_VERIFICATION_STATUS_TO_DESCRIPTION_MAP } from './SupplierBankAccountsTable.constants';

const getEftsureVerificationStatusColor = (eftsureVerificationStatus?: EftsureVerificationStatus | null) => {
  if (!eftsureVerificationStatus) {
    return;
  }

  if (
    eftsureVerificationStatus === EftsureVerificationStatus.CrossMatch ||
    eftsureVerificationStatus === EftsureVerificationStatus.Verified ||
    eftsureVerificationStatus === EftsureVerificationStatus.SelfCertified
  ) {
    return '#13a151';
  }

  if (
    eftsureVerificationStatus === EftsureVerificationStatus.NotVerified ||
    eftsureVerificationStatus === EftsureVerificationStatus.Pending
  ) {
    return '#f79421';
  }

  return '#eb2531';
};

const getEftsureVerificationStatusDescription = (eftsureVerificationStatus?: EftsureVerificationStatus | null) => {
  if (!eftsureVerificationStatus) {
    return undefined;
  }

  return EFTSURE_VERIFICATION_STATUS_TO_DESCRIPTION_MAP[eftsureVerificationStatus];
};

export { getEftsureVerificationStatusColor, getEftsureVerificationStatusDescription };
