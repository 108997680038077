import { useState } from 'react';

import axios, { AxiosError } from 'axios';

import { getUploadFileFormData } from './useFileUpload.helpers';
import { UploadFileResponse, UseFileUpload, UseFileUploadOptions } from './useFileUpload.types';

const useFileUpload = <T>({ baseApiEndpointUrl, onCompleted, onError, path, token }: UseFileUploadOptions<T>): UseFileUpload<T> => {
  const fullApiEndpointUrl = new URL(`${baseApiEndpointUrl}${path}`).toString();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uploadFile: UseFileUpload<T>['uploadFile'] = async (params) => {
    setIsLoading(true);

    try {
      const { data } = await axios.post<T>(fullApiEndpointUrl, params.formData, {
        headers: {
          authorization: token ? `Bearer ${token}` : undefined,
          'content-type': 'multipart/form-data',
        },
      });

      if (!onCompleted) {
        return data;
      }

      onCompleted(data);
    } catch (error) {
      if (!onError) {
        throw error;
      }

      onError(error as AxiosError);
    } finally {
      setIsLoading(false);
    }
  };

  const uploadFileResponse: UploadFileResponse = {
    isLoading,
  };

  return {
    getUploadFileFormData,
    uploadFile,
    uploadFileResponse,
  };
};

export { useFileUpload };
