import { FC } from 'react';

import { Box, Center, Text } from '@mantine/core';

import { ErrorMessageProps } from './ErrorMessage.types';

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  if (!message) {
    return null;
  }

  return (
    <Center id="error-message" inline>
      <Box ml={2}>
        <Text
          sx={({ colors }) => ({
            color: colors['bizpay-red'][7],
          })}
        >
          {message}
        </Text>
      </Box>
    </Center>
  );
};

export { ErrorMessage };
