import { DocumentNode, gql } from 'graphql-library';

const retrieveLatestDisbursementsFileQuery: DocumentNode = gql`
  query retrieveLatestDisbursementsFile {
    retrieveLatestDisbursementsFile {
      createdAtUtc
      id
    }
  }
`;

export { retrieveLatestDisbursementsFileQuery };
