import { FC, useEffect } from 'react';

import { AustralianState, IndustryClassifications } from 'common';
import {
  BizPayPrimaryButton,
  ErrorMessage,
  Flex,
  Grid,
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  Select,
  Text,
  TextInput,
  TextInputRightSection,
  useForm,
} from 'ui';

import { ADDRESS_FIELDS, ENTITY_FIELDS, SOCIAL_MEDIA_FIELDS } from './EntityInfoForm.constants';
import { entityInfoFormZodResolver } from './EntityInfoForm.helpers';
import { EntityInfoFormData, EntityInfoFormProps } from './EntityInfoForm.types';

const EntityInfoForm: FC<EntityInfoFormProps<EntityInfoFormData>> = ({ initialDefaultValues, onSubmit }) => {
  const {
    formState: { dirtyFields, errors, isDirty, isValid },
    handleSubmit,
    register,
    reset,
    setValue,
    trigger,
    watch,
  } = useForm<EntityInfoFormData>({
    defaultValues: initialDefaultValues,
    mode: 'onChange',
    resolver: entityInfoFormZodResolver(),
  });

  const {
    abrRegisteredState: abrRegisteredStateError,
    city: cityError,
    country: countryError,
    facebookUrl: facebookUrlError,
    industry: industryError,
    instagramUrl: instagramUrlError,
    linkedInUrl: linkedInUrlError,
    postcode: postcodeError,
    street: streetError,
    state: stateError,
    suburb: suburbError,
    twitterUrl: twitterUrlError,
    websiteUrl: websiteUrlError,
  } = errors;

  const handleStateChange = (state: AustralianState) => {
    setValue('state', state, {
      shouldDirty: true,
    });
    trigger('state');
  };

  const handleAbrRegisteredStateChange = (state: AustralianState) => {
    setValue('abrRegisteredState', state, {
      shouldDirty: true,
    });
    trigger('abrRegisteredState');
  };

  const handleIndustryClassificationChange = (classification: IndustryClassifications) => {
    setValue('industry', classification, {
      shouldDirty: true,
    });
    trigger('industry');
  };

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form
      id="entity-info-form"
      style={{
        width: '100%',
      }}
      onSubmit={handleSubmit((formData) => {
        const hasAddressFieldsChanged = Object.keys(dirtyFields).some((field) => ADDRESS_FIELDS.includes(field));
        const hasSocialMediaFieldsChanged = Object.keys(dirtyFields).some((field) => SOCIAL_MEDIA_FIELDS.includes(field));
        const hasEntityDetailsChanged = Object.keys(dirtyFields).some((field) => ENTITY_FIELDS.includes(field));

        onSubmit({
          ...formData,
          hasAddressFieldsChanged,
          hasSocialMediaFieldsChanged,
          hasEntityDetailsChanged,
        });

        reset(formData);
      })}
    >
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('name')} label="Name" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <Grid>
            <Grid.Col span="auto">
              <TextInput {...register('onboardingDate')} label="Onboarding date" size="md" readOnly />
            </Grid.Col>

            <Grid.Col span="auto">
              <TextInput {...register('equifaxBureauScore')} label="Equifax bureau score" size="md" readOnly />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <Grid>
            <Grid.Col span="auto">
              <TextInput {...register('abn')} label="ABN" size="md" readOnly />
            </Grid.Col>

            <Grid.Col span="auto">
              <TextInput {...register('acn')} label="ACN" size="md" readOnly />
            </Grid.Col>
          </Grid>
        </Grid.Col>

        <Grid.Col span="auto">
          <Grid>
            <Grid.Col span="auto">
              <Select
                {...register('industry')}
                data={Object.values(IndustryClassifications).map((classification) => ({
                  label: classification,
                  value: classification,
                }))}
                error={industryError ? <ErrorMessage message={industryError.message} /> : null}
                label="Industry classification"
                size="md"
                value={watch('industry')}
                onChange={handleIndustryClassificationChange}
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span={3}>
          <TextInput {...register('type')} label="Type" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span={3}>
          <Select
            {...register('abrRegisteredState')}
            data={Object.keys(AustralianState).map((state) => ({
              label: state,
              value: state,
            }))}
            error={abrRegisteredStateError ? <ErrorMessage message={abrRegisteredStateError.message} /> : null}
            label="ABR registered state"
            size="md"
            value={watch('abrRegisteredState')}
            onChange={handleAbrRegisteredStateChange}
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('websiteUrl')}
            error={websiteUrlError ? <ErrorMessage message={String(websiteUrlError.message)} /> : null}
            label="Website address"
            rightSection={<TextInputRightSection error={websiteUrlError} value={watch('websiteUrl')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Text mb="md" mt="xl" weight="bold">
        Address
      </Text>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('street')}
            error={streetError ? <ErrorMessage message={String(streetError.message)} /> : null}
            label="Street"
            rightSection={<TextInputRightSection error={streetError} value={watch('street')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('suburb')}
            error={suburbError ? <ErrorMessage message={String(suburbError.message)} /> : null}
            label="Suburb"
            rightSection={<TextInputRightSection error={suburbError} value={watch('suburb')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <Grid>
            <Grid.Col span="auto">
              <Select
                {...register('state')}
                data={Object.keys(AustralianState).map((state) => ({
                  label: state,
                  value: state,
                }))}
                error={stateError ? <ErrorMessage message={stateError.message} /> : null}
                label="State"
                size="md"
                value={watch('state')}
                onChange={handleStateChange}
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <TextInput
                {...register('postcode')}
                error={postcodeError ? <ErrorMessage message={String(postcodeError.message)} /> : null}
                label="Postcode"
                rightSection={<TextInputRightSection error={postcodeError} value={watch('postcode')} />}
                size="md"
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <TextInput
                {...register('city')}
                error={cityError ? <ErrorMessage message={String(cityError.message)} /> : null}
                label="City"
                rightSection={<TextInputRightSection error={cityError} value={watch('city')} />}
                size="md"
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <TextInput
                {...register('country')}
                error={countryError ? <ErrorMessage message={String(countryError.message)} /> : null}
                label="Country"
                rightSection={<TextInputRightSection error={countryError} value={watch('country')} />}
                size="md"
              />
            </Grid.Col>
          </Grid>
        </Grid.Col>
      </Grid>

      <Text mb="md" mt="xl" weight="bold">
        Social media
      </Text>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('facebookUrl')}
            error={facebookUrlError ? <ErrorMessage message={String(facebookUrlError.message)} /> : null}
            icon={<IconBrandFacebook stroke="1" />}
            label="Facebook"
            rightSection={<TextInputRightSection error={facebookUrlError} value={watch('facebookUrl')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('instagramUrl')}
            error={instagramUrlError ? <ErrorMessage message={String(instagramUrlError.message)} /> : null}
            icon={<IconBrandInstagram stroke="1" />}
            label="Instagram"
            rightSection={<TextInputRightSection error={instagramUrlError} value={watch('instagramUrl')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput
            {...register('linkedInUrl')}
            error={linkedInUrlError ? <ErrorMessage message={String(linkedInUrlError.message)} /> : null}
            icon={<IconBrandLinkedin stroke="1" />}
            label="LinkedIn"
            rightSection={<TextInputRightSection error={linkedInUrlError} value={watch('linkedInUrl')} />}
            size="md"
          />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput
            {...register('twitterUrl')}
            error={twitterUrlError ? <ErrorMessage message={String(twitterUrlError.message)} /> : null}
            icon={<IconBrandTwitter stroke="1" />}
            label="Twitter"
            rightSection={<TextInputRightSection error={twitterUrlError} value={watch('twitterUrl')} />}
            size="md"
          />
        </Grid.Col>
      </Grid>

      <Flex justify="end" mt="xl">
        <BizPayPrimaryButton disabled={!(isDirty && isValid)} type="submit">
          Save changes
        </BizPayPrimaryButton>
      </Flex>
    </form>
  );
};

export { EntityInfoForm };
