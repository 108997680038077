import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { BizPayCancelButton, BizPayPrimaryButton, ErrorMessage, Flex, Stack, TextInput, TextInputRightSection, useForm } from 'ui';

import { eftsureSelfCertifyFormZodResolver } from './EftsureSelfCertifyForm.helpers';
import { EftsureSelfCertifyFormData, EftsureSelfCertifyFormProps } from './EftsureSelfCertifyForm.types';

import { useSignOut } from '../../hooks';

const EftsureSelfCertifyForm: FC<EftsureSelfCertifyFormProps<EftsureSelfCertifyFormData>> = ({ onCancel, onSubmit }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setFocus,
    watch,
  } = useForm<EftsureSelfCertifyFormData>({
    mode: 'onChange',
    resolver: eftsureSelfCertifyFormZodResolver(),
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    setFocus('reason');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { reason: reasonError } = errors;

  return (
    <form
      id="eftsure-self-certify-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack h="100%" justify="center" w="100%">
        <TextInput
          {...register('reason')}
          error={reasonError ? <ErrorMessage message={reasonError.message} /> : null}
          label="Reason"
          placeholder="Add a reason for self-certifying this bank account"
          rightSection={<TextInputRightSection error={reasonError} value={watch('reason')} />}
          size="md"
        />
      </Stack>

      <Flex direction="row" gap="1rem" w="100%">
        <BizPayCancelButton w="100%" onClick={onCancel}>
          No, don&apos;t
        </BizPayCancelButton>

        <BizPayPrimaryButton type="submit" w="100%">
          Yes, self-certify
        </BizPayPrimaryButton>
      </Flex>
    </form>
  );
};

export { EftsureSelfCertifyForm };
