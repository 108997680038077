enum AustralianState {
  ACT = 'Australian Capital Territory',
  NSW = 'New South Wales',
  NT = 'Northern Territory',
  QLD = 'Queensland',
  SA = 'South Australia',
  TAS = 'Tasmania',
  VIC = 'Victoria',
  WA = 'Western Australia',
}

enum IndustryClassifications {
  AccommodationAndFoodServices = 'Accommodation and food services',
  AdministrativeAndSupportServices = 'Administrative and support services',
  AgricultureForestryAndFishing = 'Agriculture, forestry and fishing',
  ArtsAndRecreationServices = 'Arts and recreation services',
  Construction = 'Construction',
  EducationAndTraining = 'Education and training',
  ElectricityGasWaterAndWasteServices = 'Electricity, gas, water and waste services',
  FinancialAndInsuranceServices = 'Financial and insurance services',
  HealthCareAndSocialAssistance = 'Health care and social assistance',
  InformationMediaAndTelecommunications = 'Information media and telecommunications',
  Manufacturing = 'Manufacturing',
  Mining = 'Mining',
  ProfessionalScientificAndTechnicalServices = 'Professional, scientific and technical services',
  PublicAdministrationAndSafety = 'Public administration and safety',
  RentalHiringAndRealEstateServices = 'Rental, hiring and real estate services',
  RetailTrade = 'Retail trade',
  TransportPostalAndWarehousing = 'Transport, postal and warehousing',
  WholesaleTrade = 'Wholesale trade',
  OtherServices = 'Other services',
}

// TODO: Get this from the server
enum SyphtCustomErrorCode {
  InvoiceIssuerAbnNotFound = 'SYPHT-1001',
  InvoiceIssuerAccountNumberNotFound = 'SYPHT-1002',
  InvoiceIssuerBsbNotFound = 'SYPHT-1003',
  InvoiceIssuerNameNotFound = 'SYPHT-1004',
  InvoiceNumberNotFound = 'SYPHT-1005',
  InvoiceTotalAmountNotFound = 'SYPHT-1006',
}

export { AustralianState, IndustryClassifications, SyphtCustomErrorCode };
