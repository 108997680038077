import { EftsureVerificationStatus } from 'graphql-library';
import { abnZodEffect, zodResolver, z, dueDateZodEffect, invoiceAmountInDollarsZodEffect } from 'ui';

const getStatusBackgroundColor = (
  eftsureVerificationStatus: EftsureVerificationStatus | null | undefined,
  teal: string,
  yellow: string,
  red: string,
) => {
  if (
    eftsureVerificationStatus === EftsureVerificationStatus.Verified ||
    eftsureVerificationStatus === EftsureVerificationStatus.CrossMatch
  ) {
    return teal;
  }

  if (
    eftsureVerificationStatus === EftsureVerificationStatus.Pending ||
    eftsureVerificationStatus === EftsureVerificationStatus.NotVerified
  ) {
    return yellow;
  }

  return red;
};

const loanApplicationInvoiceDetailsFormZodResolver = () => {
  return zodResolver(
    z.object({
      invoiceAmount: invoiceAmountInDollarsZodEffect,
      invoiceDateDueAtUtc: dueDateZodEffect,
      invoiceDateIssuedAtUtc: z.date({
        required_error: 'Please enter an invoice issued date',
      }),
      invoiceNumber: z
        .string()
        .min(1, {
          message: 'Please enter an invoice number',
        })
        .refine((value) => value !== '-', {
          message: 'Please enter an invoice number',
        }),
      invoicePaymentReference: z.string().min(1, {
        message: 'Please enter an invoice payment reference',
      }),
      supplierAbn: abnZodEffect,
      supplierBankAccountId: z.string(),
      supplierEmail: z.string().email('Please enter a valid email'),
      supplierName: z
        .string()
        .min(1, {
          message: 'Please enter the supplier name',
        })
        .refine((value) => value !== '-', {
          message: 'Please enter the supplier name',
        }),
      supplierPhone: z.string().optional(),
    }),
  );
};

export { getStatusBackgroundColor, loanApplicationInvoiceDetailsFormZodResolver };
