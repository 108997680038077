import currency from 'currency.js';
import dayjs from 'dayjs';
import isCurrency from 'validator/lib/isCurrency';
import isEmail from 'validator/lib/isEmail';
import isLength from 'validator/lib/isLength';
import isNumeric from 'validator/lib/isNumeric';
import z from 'zod';

const ABN_LENGTH = 11;
const MAX_32_BIT_INTEGER_VALUE = Math.pow(2, 32) / 2;

const abnZodEffect = z
  .string()
  .min(1, {
    message: 'Please enter an ABN',
  })
  .refine((value) => value !== '-', {
    message: 'Please enter an ABN',
  })
  .refine((value) => isNumeric(value), {
    message: `ABN must be numeric`,
  })
  .refine(
    (value) =>
      isLength(value, {
        max: ABN_LENGTH,
        min: ABN_LENGTH,
      }),
    {
      message: `ABN must be at ${ABN_LENGTH} digits`,
    },
  );

const accountNumberZodEffect = z
  .string()
  .min(1, {
    message: 'Please enter an account number',
  })
  .refine((value) => isNumeric(value), {
    message: 'Account number must be numeric',
  })
  .refine(
    (value) =>
      isLength(value, {
        min: 6,
        max: 10,
      }),
    {
      message: 'Account number must be 6-10 digits',
    },
  );

const bsbZodEffect = z
  .string()
  .min(1, {
    message: 'Please enter a BSB',
  })
  .refine((value) => isNumeric(value), {
    message: 'BSB must be numeric',
  })
  .refine(
    (value) =>
      isLength(value, {
        min: 6,
        max: 6,
      }),
    {
      message: 'BSB must be 6 digits',
    },
  );

const dueDateZodEffect = z
  .date({
    required_error: 'Please enter an invoice due date',
  })
  .refine(
    (value) => {
      const minDate = dayjs()
        .startOf('day')
        .add(Number(process.env.NEXT_PUBLIC_INVOICE_VALIDATION_MAX_DAYS_PRIOR_TO_INVOICE_DUE_DATE), 'day')
        .toDate();

      return value >= minDate;
    },
    {
      message: 'Invoice due date must be today or later',
    },
  );

const emailZodEffect = z
  .string()
  .min(1, {
    message: 'Please enter an email',
  })
  .refine((value) => isEmail(value), {
    message: 'Please enter a valid email',
  });

const invoiceAmountInDollarsZodEffect = z
  .string()
  .min(1, {
    message: 'Please enter the invoice amount',
  })
  .refine((value) => !value.includes('$'), {
    message: 'Please enter an invoice amount without the $ symbol',
  })
  .refine((value) => isCurrency(value), {
    message: 'Please enter a valid invoice amount',
  })
  .refine(
    (value) => {
      const currencyValue = currency(value);

      return currencyValue.intValue <= MAX_32_BIT_INTEGER_VALUE;
    },
    {
      message: 'Please enter a valid invoice amount',
    },
  )
  .refine(
    (value) => {
      const currencyValue = currency(value);

      return currencyValue.value >= 100;
    },
    {
      message: 'Invoice amount must be at least $100.00',
    },
  );

export {
  ABN_LENGTH,
  abnZodEffect,
  accountNumberZodEffect,
  bsbZodEffect,
  dueDateZodEffect,
  emailZodEffect,
  invoiceAmountInDollarsZodEffect,
};
