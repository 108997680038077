import { FC, useEffect, useState } from 'react';

import { isNil, sentenceCase, useAuthentication, useNumberFormatter } from 'common';
import { ProductFeeType, useRetrievePaginatedProductsLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { ProductModal } from '../ProductModal';

import { Product, ProductsTableProps } from './ProductsTable.types';

import { useSignOut } from '../../hooks';

const ProductsTable: FC<ProductsTableProps> = ({
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
  refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { formatCurrency, formatPercentage } = useNumberFormatter();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isUpdateProductModalOpen, setIsUpdateProductModalOpen] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'createdAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrievePaginatedProductsQuery, { loading: isLoading, refetch }] = useRetrievePaginatedProductsLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrievePaginatedProducts: { count, data } }) => {
      setHasRetrievedData(true);
      setProducts(data);
      setTotalPages(calculateTotalPages(currentPageSize, count));
      setTotalRecords(count);

      if (onRefetchDataCompleted) {
        onRefetchDataCompleted();
      }

      if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
        push(`${route}?pageNumber=${currentPageNumber}`, undefined, {
          shallow: true,
        });
      }
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve products',
      });
    },
  });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<Product>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('code', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Code',
        sortDescFirst: false,
      }),
      columnHelper.accessor(
        ({ displayName, name }) => ({
          displayName,
          name,
        }),
        {
          cell: ({ getValue }) => {
            const { displayName, name } = getValue();
            return displayName ?? name;
          },
          enableSorting: false,
          header: 'Name',
          minSize: 0,
          size: 0,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor(
        ({ feeType, feeValue }) => ({
          feeType,
          feeValue,
        }),
        {
          cell: ({ getValue }) => {
            const { feeType, feeValue } = getValue();

            switch (feeType) {
              case ProductFeeType.Percentage: {
                return formatPercentage(feeValue);
              }

              case ProductFeeType.FixedFee: {
                return formatCurrency(feeValue);
              }
            }
          },
          enableSorting: isSortingEnabled,
          header: 'Fee value',
          id: 'feeValue',
          meta: {
            align: 'right',
          },
          size: 100,
          sortDescFirst: false,
        },
      ),
      columnHelper.accessor('feeType', {
        cell: ({ getValue }) => sentenceCase(getValue()),
        enableSorting: isSortingEnabled,
        header: 'Fee type',
        size: 100,
        sortDescFirst: false,
      }),
      columnHelper.accessor('frequencyValue', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Frequency value',
        meta: {
          align: 'right',
        },
        size: 150,
        sortDescFirst: false,
      }),
      columnHelper.accessor('frequencyType', {
        cell: ({ getValue }) => getValue(),
        enableSorting: isSortingEnabled,
        header: 'Frequency type',
        size: 150,
        sortDescFirst: false,
      }),
      columnHelper.accessor('partnerFee', {
        cell: ({ getValue }) => {
          const partnerFee = getValue();
          return isNil(partnerFee) ? '-' : formatPercentage(partnerFee);
        },
        enableSorting: isSortingEnabled,
        header: 'Partner fee %',
        size: 100,
        sortDescFirst: false,
      }),
      columnHelper.accessor('customerFee', {
        cell: ({ getValue }) => {
          const customerFee = getValue();
          return isNil(customerFee) ? '-' : formatPercentage(customerFee);
        },
        enableSorting: isSortingEnabled,
        header: 'Customer fee %',
        size: 100,
        sortDescFirst: false,
      }),
    ];
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const handleUpdateProductModalClose = () => {
    setIsUpdateProductModalOpen(false);
  };

  const handleUpdateProductModalCompleted = () => {
    refetch();
    setIsUpdateProductModalOpen(false);
  };

  const handleProductClick = (product: Product) => {
    setIsUpdateProductModalOpen(true);
    setSelectedProduct(product);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const { getHeaderGroups, getRowModel } = useReactTable<Product>({
    columns: getTableColumns(totalRecords),
    data: products,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedProductsQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  return (
    <>
      <ProductModal
        isOpen={isUpdateProductModalOpen}
        product={selectedProduct}
        onClose={handleUpdateProductModalClose}
        onCompleted={handleUpdateProductModalCompleted}
      />

      <TablePageLayout
        loadingMessage={isLoading ? 'Retrieving products...' : undefined}
        paginationComponent={
          <BizPayPagination
            description={generatePaginationResultsDescription(
              isPaginationEnabled,
              currentPageNumber,
              currentPageSize,
              calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
              totalRecords,
            )}
            hasRetrievedData={hasRetrievedData}
            isEnabled={isPaginationEnabled}
            pageSize={currentPageSize}
            totalPages={totalPages}
            totalRecords={totalRecords}
            value={currentPageNumber}
            onChange={handlePageNumberChange}
          />
        }
        tableComponent={
          <BizPayTable<Product>
            hasRetrievedData={hasRetrievedData}
            headerGroups={getHeaderGroups()}
            rowModel={getRowModel()}
            onRowClick={handleProductClick}
          />
        }
      />
    </>
  );
};

export { ProductsTable };
