import { FC } from 'react';

import { sentenceCase } from 'common';
import { ProductFeeType, ProductFrequencyType } from 'graphql-library';
import {
  BizPayPrimaryButton,
  ErrorMessage,
  Grid,
  Group,
  NumberInput,
  NumberInputProps,
  Select,
  Stack,
  TextInput,
  TextInputRightSection,
  useForm,
} from 'ui';

import { productFormZodResolver } from './ProductForm.helpers';
import { ProductFormData, ProductFormProps } from './ProductForm.types';

const ProductForm: FC<ProductFormProps<ProductFormData>> = ({ initialDefaultValues, onSubmit, submitButtonText }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setValue,
    trigger,
    watch,
  } = useForm<ProductFormData>({
    defaultValues: initialDefaultValues,
    mode: 'onChange',
    resolver: productFormZodResolver(),
  });

  const handleFrequencyTypeChange = (frequency: ProductFrequencyType) => {
    setValue('frequencyType', frequency, {
      shouldDirty: true,
    });
    trigger('frequencyType');
  };

  const handleFeeTypeChange = (feeType: ProductFeeType) => {
    setValue('feeType', feeType, {
      shouldDirty: true,
    });
    trigger('feeType');
  };

  const handleFeeValueChange = (feeValue: number) => {
    setValue('feeValue', feeValue, {
      shouldDirty: true,
    });
    trigger('feeValue');
  };

  const handleFrequencyValueChange = (frequencyValue: number) => {
    setValue('frequencyValue', frequencyValue, {
      shouldDirty: true,
    });
    trigger('frequencyValue');
  };

  const handlePartnerFeeChange: NumberInputProps['onChange'] = (partnerFee) => {
    setValue('partnerFee', partnerFee === '' ? null : partnerFee, {
      shouldDirty: true,
    });

    setValue('customerFee', partnerFee === '' ? null : 100 - partnerFee, {
      shouldDirty: true,
    });

    trigger('partnerFee');
  };

  const {
    code: codeError,
    displayName: displayNameError,
    feeType: feeTypeError,
    feeValue: feeValueError,
    frequencyType: frequencyTypeError,
    frequencyValue: frequencyValueError,
    partnerFee: partnerFeeError,
  } = errors;

  const customerFee = watch('customerFee');
  const frequencyValue = watch('frequencyValue');
  const feeValue = watch('feeValue');
  const partnerFee = watch('partnerFee');

  return (
    <form
      id="product-form"
      style={{
        height: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack h="100%" justify="center" w="100%">
        <Stack h="100%" mt="md" w="100%">
          <Grid gutter="sm">
            <Grid.Col span="auto">
              <TextInput
                {...register('code')}
                error={codeError ? <ErrorMessage message={codeError.message} /> : null}
                label="Code"
                rightSection={<TextInputRightSection error={codeError} value={watch('code')} />}
                size="md"
                sx={() => ({
                  input: {
                    textTransform: 'uppercase',
                  },
                })}
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <TextInput
                {...register('displayName')}
                error={displayNameError ? <ErrorMessage message={displayNameError.message} /> : null}
                label="Display name"
                rightSection={<TextInputRightSection error={codeError} value={watch('displayName')} />}
                size="md"
              />
            </Grid.Col>
          </Grid>

          <Grid gutter="sm">
            <Grid.Col span="auto">
              <Select
                {...register('frequencyType')}
                data={Object.values(ProductFrequencyType).map((frequency) => ({
                  label: sentenceCase(frequency),
                  value: frequency,
                }))}
                error={frequencyTypeError ? <ErrorMessage message={frequencyTypeError.message} /> : null}
                label="Frequency"
                size="md"
                value={watch('frequencyType')}
                onChange={handleFrequencyTypeChange}
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <NumberInput
                {...(register('frequencyValue'),
                {
                  type: 'number',
                })}
                error={frequencyValueError ? <ErrorMessage message={frequencyValueError.message} /> : null}
                label="Frequency value"
                min={1}
                size="md"
                value={frequencyValue}
                onChange={handleFrequencyValueChange}
              />
            </Grid.Col>
          </Grid>

          <Grid gutter="sm">
            <Grid.Col span="auto">
              <Select
                {...register('feeType')}
                data={Object.values(ProductFeeType).map((feeType) => ({
                  label: sentenceCase(feeType),
                  value: feeType,
                }))}
                error={feeTypeError ? <ErrorMessage message={feeTypeError.message} /> : null}
                label="Fee type"
                size="md"
                value={watch('feeType')}
                onChange={handleFeeTypeChange}
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <NumberInput
                {...(register('feeValue'),
                {
                  type: 'number',
                })}
                error={feeValueError ? <ErrorMessage message={feeValueError.message} /> : null}
                label={`Fee value${watch('feeType') === ProductFeeType.FixedFee ? ' (in dollars)' : ''}`}
                min={0}
                precision={2}
                size="md"
                step={0.01}
                value={feeValue}
                onChange={handleFeeValueChange}
              />
            </Grid.Col>
          </Grid>

          <Grid gutter="sm">
            <Grid.Col span="auto">
              <NumberInput
                {...(register('partnerFee'),
                {
                  type: 'number',
                })}
                error={partnerFeeError ? <ErrorMessage message={partnerFeeError.message} /> : null}
                label="Partner fee %"
                max={100}
                min={0}
                precision={2}
                size="md"
                step={0.01}
                value={partnerFee ?? ''}
                onChange={handlePartnerFeeChange}
              />
            </Grid.Col>

            <Grid.Col span="auto">
              <NumberInput
                {...(register('customerFee'),
                {
                  type: 'number',
                })}
                label="Customer fee %"
                max={100}
                min={0}
                precision={2}
                size="md"
                step={0.01}
                value={customerFee ?? ''}
                disabled
              />
            </Grid.Col>
          </Grid>
        </Stack>

        <Group mt="md" position="right" w="100%">
          <BizPayPrimaryButton disabled={!isValid} type="submit">
            {submitButtonText}
          </BizPayPrimaryButton>
        </Group>
      </Stack>
    </form>
  );
};

export { ProductForm };
