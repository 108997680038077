import { FC } from 'react';

import { BIZPAY_COMMON_CONSTANTS } from 'common';

import { DynamicNextJsTitleProps } from './DynamicNextJsTitle.types';

const { companyName } = BIZPAY_COMMON_CONSTANTS;

const DynamicNextJsTitle: FC<DynamicNextJsTitleProps> = ({ appName, headComponent, pageTitle }) => {
  // Note: This is needed to prevent multiple children being created. See: https://github.com/vercel/next.js/discussions/38256.
  const fullTitle = `${companyName} ${appName} | ${pageTitle}`;

  const Head = headComponent;

  return (
    <Head>
      <title>{fullTitle}</title>
      <meta key="title" content={fullTitle} property="og:title" />
    </Head>
  );
};

export { DynamicNextJsTitle };
