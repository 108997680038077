/* eslint-disable react/display-name */
import { forwardRef } from 'react';

import { Group, Stack, Text, UnstyledButton } from '@mantine/core';
import { IconUserCircle } from '@tabler/icons-react';

import { UserProfileButtonProps } from './UserProfileButton.types';

const UserProfileButton = forwardRef<HTMLButtonElement, UserProfileButtonProps>(
  ({ email, fullName, ...otherProps }: UserProfileButtonProps, ref) => (
    <UnstyledButton ref={ref} data-testid="e2e-user-profile-dropdown" {...otherProps}>
      <Group noWrap>
        <Stack align="flex-end" mr="sm">
          <Text size="md" weight={500}>
            {fullName}
          </Text>

          <Text color="dimmed" size="md">
            {email}
          </Text>
        </Stack>

        <IconUserCircle size={32} />
      </Group>
    </UnstyledButton>
  ),
);

export { UserProfileButton };
