import { DocumentNode, gql } from 'graphql-library';

const retrieveNotesForLoanApplicationQuery: DocumentNode = gql`
  query retrieveNotesForLoanApplication($loanApplicationId: String!) {
    retrieveNotesForLoanApplication(loanApplicationId: $loanApplicationId) {
      createdAtUtc
      id
      message
      submittedByStaffMember {
        fullName
        id
      }
    }
  }
`;

export { retrieveNotesForLoanApplicationQuery };
