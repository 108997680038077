import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedLoanApplicationsDeclinedOrWithdrawnQuery: DocumentNode = gql`
  query retrievePaginatedLoanApplicationsDeclinedOrWithdrawn($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedLoanApplicationsByAdminStatus(adminStatuses: [Declined, Withdrawn], pagination: $pagination, sort: $sort) {
      count
      data {
        adminStatus
        entity {
          name
          id
        }
        id
        invoice {
          id
          supplier {
            id
            name
          }
          totalAmountInCents
        }
        niceId
        updatedAtUtc
        withdrawnAtUtc
      }
    }
  }
`;

export { retrievePaginatedLoanApplicationsDeclinedOrWithdrawnQuery };
