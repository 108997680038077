import { FC } from 'react';

import { Divider, Navbar, ScrollArea } from '@mantine/core';
import { kebabCase } from 'common';

import { BizPayNavigationLink } from '../BizPayNavigationLink';

import { BizPayNavigationBarProps } from './BizPayNavigationBar.types';

const BizPayNavigationBar: FC<BizPayNavigationBarProps> = ({
  backgroundColor = 'white',
  contactUsNavigationLinkComponent,
  dividerColor = 'gray.3',
  navigationLinks,
  signOutNavigationLinkComponent,
}) => {
  if (!navigationLinks.length) {
    return null;
  }

  return (
    <Navbar
      bg={backgroundColor}
      p="md"
      width={{
        base: 250,
      }}
    >
      <Navbar.Section component={ScrollArea} mb="md" mx="-xs" px="xs" grow>
        {navigationLinks.map(({ hoverBackgroundColor, icon, isActive, label, linkOptions }) => {
          return (
            <BizPayNavigationLink
              key={kebabCase(label)}
              hoverBackgroundColor={hoverBackgroundColor}
              icon={icon}
              isActive={isActive}
              label={label}
              linkOptions={linkOptions}
            />
          );
        })}
      </Navbar.Section>

      <Divider color={dividerColor} mb="md" />

      <Navbar.Section mx="-xs" px="xs">
        {contactUsNavigationLinkComponent}

        {signOutNavigationLinkComponent}
      </Navbar.Section>
    </Navbar>
  );
};

export { BizPayNavigationBar };
