import { currency } from 'common';
import { ProductFeeType } from 'graphql-library';

const convertFeeValue = (feeType?: ProductFeeType, feeValue?: number) => {
  if (!(feeType && feeValue)) {
    return;
  }

  if (feeType === ProductFeeType.FixedFee) {
    return currency(feeValue / 100).value;
  }

  return feeValue;
};

export { convertFeeValue };
