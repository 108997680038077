import { FC, useEffect, useState } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayAdminPortalDynamicNextJsTitle, FullHeightContainer, FullPageLayout, PageHeading, Tabs, TabsValue } from 'ui';

import { LoanApplicationsDeclinedOrWithdrawnTable } from '../LoanApplicationsDeclinedOrWithdrawnTable';
import { LoanApplicationsForDisbursementTab } from '../LoanApplicationsForDisbursementTab';
import { LoanApplicationsForReviewTable } from '../LoanApplicationsForReviewTable';
import { LoanApplicationsIncompleteTable } from '../LoanApplicationsIncompleteTable';

import { LOAN_APPLICATIONS_PAGE_CONSTANTS } from './LoanApplicationsPage.constants';

import { useNavigationRoutes } from '../../hooks';

const { tabs } = LOAN_APPLICATIONS_PAGE_CONSTANTS;

const LoanApplicationsPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const {
    push,
    query: { tab },
    route,
  } = useRouter();

  const [tabIdValue, setTabIdValue] = useState<TabsValue>(tabs.config[0].id);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleTabChange = (value: TabsValue) => {
    setTabIdValue(value);
    setTabIndex(tabs.config.findIndex(({ id }) => id === value));

    push(`${route}?tab=${value}`, undefined, {
      shallow: true,
    });
  };

  const { title } = findPageByRoute(route);

  useEffect(() => {
    if (!tab) {
      handleTabChange(tabs.config[0].id);
      return;
    }

    handleTabChange(String(tab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <FullPageLayout
        mainComponent={
          <FullHeightContainer>
            <Tabs
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
              }}
              value={tabIdValue}
              onTabChange={handleTabChange}
            >
              <Tabs.List>
                {tabs.config.map(({ id, title }) => {
                  return (
                    <Tabs.Tab key={`tab-${id}`} value={id}>
                      {title}
                    </Tabs.Tab>
                  );
                })}
              </Tabs.List>

              <Tabs.Panel
                p="md"
                pb={0}
                pl={6}
                pr={0}
                pt="md"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
                value={tabs.config[tabIndex].id}
              >
                <FullHeightContainer>
                  {tabs.config[tabIndex].id === tabs.ids.loanApplicationsForDisbursement && <LoanApplicationsForDisbursementTab />}

                  {tabs.config[tabIndex].id === tabs.ids.loanApplicationsDeclinedWithdrawn && (
                    <LoanApplicationsDeclinedOrWithdrawnTable
                      paginationOptions={{
                        isPaginationEnabled: true,
                      }}
                      tabId={tabs.ids.loanApplicationsDeclinedWithdrawn}
                    />
                  )}

                  {tabs.config[tabIndex].id === tabs.ids.loanApplicationsIncomplete && (
                    <LoanApplicationsIncompleteTable
                      paginationOptions={{
                        isPaginationEnabled: true,
                      }}
                      tabId={tabs.ids.loanApplicationsIncomplete}
                    />
                  )}

                  {tabs.config[tabIndex].id === tabs.ids.loanApplicationsForReview && (
                    <LoanApplicationsForReviewTable
                      paginationOptions={{
                        isPaginationEnabled: true,
                      }}
                      tabId={tabs.ids.loanApplicationsForReview}
                    />
                  )}
                </FullHeightContainer>
              </Tabs.Panel>
            </Tabs>
          </FullHeightContainer>
        }
        pageHeadingComponent={<PageHeading heading={title} size="h4" />}
      />
    </>
  );
};

export { LoanApplicationsPage };
