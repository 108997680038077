import { FC } from 'react';

import { Box, Group, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { sentenceCase } from 'common';

import { TextInputLabelWarningSectionProps } from './TextInputLabelWarningSection.types';

import { USE_BIZPAY_UI_THEME_CONSTANTS } from '../../hooks';

const {
  colors: { yellowPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const TextInputLabelWarningSection: FC<TextInputLabelWarningSectionProps> = ({
  isFieldEdited,
  label,
  shouldTextInputLabelUseSentenceCase = true,
}) => {
  const warningMessage = 'Edited by customer';

  return (
    <Box display="flex" sx={{ alignItems: 'center', justifyContent: 'space-between' }}>
      <Text>{shouldTextInputLabelUseSentenceCase ? sentenceCase(label) : label}</Text>

      {isFieldEdited && (
        <Group
          position="center"
          sx={({ colors }) => ({
            color: colors['bizpay-yellow'][9],
          })}
        >
          <IconAlertCircle color={yellowPalette[9]} size={16} />
          <Text
            ml={3}
            sx={({ colors }) => ({
              color: colors['bizpay-yellow'][9],
            })}
          >
            {warningMessage}
          </Text>
        </Group>
      )}
    </Box>
  );
};

export { TextInputLabelWarningSection };
