import { FC } from 'react';

import { Stack } from '@mantine/core';

import { DefaultPageLayoutProps } from './DefaultPageLayout.types';

const DefaultPageLayout: FC<DefaultPageLayoutProps> = ({ mainComponent, pageHeadingComponent }) => {
  return (
    <Stack h="100%" id="default-page-layout-component" justify="center" w="100%">
      <Stack id="default-page-layout-page-heading-component" w="100%">
        {pageHeadingComponent}
      </Stack>

      <Stack align="center" h="100%" id="default-page-layout-main-component" w="100%">
        {mainComponent}
      </Stack>
    </Stack>
  );
};

export { DefaultPageLayout };
