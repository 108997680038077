import { FC, useEffect, useState } from 'react';

import { compact, currency, isNil, useAuthentication } from 'common';
import {
  StaffMemberRole,
  useRetrieveLoanFacilityForEntityLazyQuery,
  useUpdateEntityInceptFacilityLimitMutation,
  useUpdateEntityMutation,
  useUpdateLoanFacilityLimitForEntityMutation,
} from 'graphql-library';
import {
  BizPayConfirmationModal,
  BizPayLoader,
  Grid,
  Group,
  List,
  LoanFacilityUsagePieChart,
  Stack,
  Text,
  USE_BIZPAY_UI_THEME_CONSTANTS,
  useBizPayNotification,
} from 'ui';

import { EntityFinancialInfoForm, EntityFinancialInfoFormData } from '../EntityFinancialInfoForm';

import { EntityFinancialInfoTabLoanFacility, EntityFinancialInfoTabProps } from './EntityFinancialInfoTab.types';

import { useSignOut } from '../../hooks';

const {
  colors: { tealPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const NOT_SPECIFIED = '[Not specified]';

const EntityFinancialInfoTab: FC<EntityFinancialInfoTabProps> = ({ entityId, inceptFacilityLimitInCents }) => {
  const { getIsAuthenticated, getLoggedInUserData } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const staffMemberRoles = getLoggedInUserData()?.roles ?? [];
  const isAuthorisedToUpdateFacilityLimit =
    staffMemberRoles.includes(StaffMemberRole.CreditAdmin) ||
    staffMemberRoles.includes(StaffMemberRole.CSuite) ||
    staffMemberRoles.includes(StaffMemberRole.SuperAdmin);

  const [changeFacilityLimitsDSCRConfirmationModalContents, setChangeFacilityLimitsDSCRConfirmationModalContents] = useState<JSX.Element>();
  const [changeFacilityLimitsDSCRConfirmationModalTitle, setChangeFacilityLimitsDSCRConfirmationModalTitle] = useState<string>();
  const [dscr, setDscr] = useState<number | null>(null);
  const [isConfirmationLimitChangesModalOpened, setIsConfirmationLimitChangesModalOpened] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [loanFacility, setLoanFacility] = useState<EntityFinancialInfoTabLoanFacility>();
  const [provision, setProvision] = useState<number | null>(null);
  const [updatedApprovedFacilityLimitInCents, setUpdatedApprovedFacilityLimitInCents] = useState<number>();
  const [updatedCurrentFacilityLimitInCents, setUpdatedCurrentFacilityLimitInCents] = useState<number>();
  const [updatedDscr, setUpdatedDscr] = useState<number | null>();
  const [updatedProvision, setUpdatedProvision] = useState<number | null>();

  const [executeRetrieveLoanFacilityForEntityQuery, { loading: isLoading }] = useRetrieveLoanFacilityForEntityLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveLoanFacilityForEntity: returnedLoanFacility }) => {
      setIsRefreshDataIconButtonDisabled(true);
      setLoanFacility(returnedLoanFacility);

      const {
        entity: { debtServiceCoverRatio, provisionInCents },
      } = returnedLoanFacility;

      if (debtServiceCoverRatio) {
        setDscr(debtServiceCoverRatio);
      }

      if (provisionInCents) {
        setProvision(provisionInCents);
      }
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve the loan facility',
      });
    },
  });

  const [executeUpdateLoanFacilityLimitForEntityMutation] = useUpdateLoanFacilityLimitForEntityMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Current facility limit has been successfully updated',
      });

      handleChangeFacilityLimitsModalClose();
    },
    onError: ({ message }) => {
      displayErrorNotification({
        message,
      });
    },
  });

  const [executeUpdateEntityDetailsMutation] = useUpdateEntityMutation({
    onCompleted: ({ updateEntityDetails: returnedEntity }) => {
      displaySuccessNotification({
        message: 'Entity details has been successfully updated',
      });

      const { debtServiceCoverRatio, provisionInCents } = returnedEntity;

      if (debtServiceCoverRatio) {
        setDscr(debtServiceCoverRatio);
      }

      if (provisionInCents) {
        setProvision(provisionInCents);
      }

      handleChangeFacilityLimitsModalClose();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the DSCR',
      });
    },
  });

  const [executeUpdateEntityInceptFacilityLimitMutation] = useUpdateEntityInceptFacilityLimitMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Approved facility limit has been successfully updated',
      });

      if (!(loanFacility && updatedCurrentFacilityLimitInCents && updatedCurrentFacilityLimitInCents !== loanFacility.limitInCents)) {
        handleChangeFacilityLimitsModalClose();
        return;
      }

      executeUpdateLoanFacilityLimitForEntityMutation({
        variables: {
          id: loanFacility.id,
          updatedLoanFacilityLimitInCents: updatedCurrentFacilityLimitInCents,
        },
      });
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the approved facility limit for entity',
      });
    },
  });

  const calculateRemainingBalance = (loanFacility: EntityFinancialInfoTabLoanFacility) => {
    const {
      entity: { outstandingBalanceInCents },
      limitInCents,
      totalAmountInCentsOfNonDisbursedLoanApplications,
    } = loanFacility;

    const remainingBalance = limitInCents - totalAmountInCentsOfNonDisbursedLoanApplications - outstandingBalanceInCents;

    return remainingBalance < 0 ? 0 : remainingBalance;
  };

  const generateChangeFacilityLimitsDSCRConfirmationModalContents = ({
    dscr,
    inceptFacilityLimitInCents,
    loanFacility,
    updatedApprovedFacilityLimitInCents,
    updatedCurrentFacilityLimitInCents,
    updatedDscr,
    updatedProvision,
  }: {
    dscr: number | null;
    inceptFacilityLimitInCents: null | number | undefined;
    loanFacility: EntityFinancialInfoTabLoanFacility;
    updatedApprovedFacilityLimitInCents: number | undefined;
    updatedCurrentFacilityLimitInCents: number | undefined;
    updatedDscr: number | null;
    updatedProvision: number | null;
  }) => {
    const hasApprovedFacilityLimitInCentsChanged =
      !isNil(updatedApprovedFacilityLimitInCents) && updatedApprovedFacilityLimitInCents !== inceptFacilityLimitInCents;
    const hasCurrentFacilityLimitInCentsChanged =
      !isNil(updatedCurrentFacilityLimitInCents) && updatedCurrentFacilityLimitInCents !== loanFacility.limitInCents;
    const hasDscrChanged = dscr !== updatedDscr;
    const hasProvisionChanged = provision !== updatedProvision;

    const contentParts = compact([
      hasCurrentFacilityLimitInCentsChanged ? (
        <List.Item>
          Current facility limit from{' '}
          <Text weight="bold" span>
            {currency(loanFacility.limitInCents / 100).format()}
          </Text>{' '}
          to{' '}
          <Text weight="bold" span>
            {currency(updatedCurrentFacilityLimitInCents / 100).format()}
          </Text>
        </List.Item>
      ) : undefined,
      hasApprovedFacilityLimitInCentsChanged ? (
        <List.Item>
          Approved facility limit from{' '}
          <Text weight="bold" span>
            {!isNil(inceptFacilityLimitInCents) ? currency(inceptFacilityLimitInCents / 100).format() : NOT_SPECIFIED}
          </Text>{' '}
          to{' '}
          <Text weight="bold" span>
            {!isNil(updatedApprovedFacilityLimitInCents) ? currency(updatedApprovedFacilityLimitInCents / 100).format() : NOT_SPECIFIED}
          </Text>
        </List.Item>
      ) : undefined,
      hasDscrChanged ? (
        <List.Item>
          DSCR from{' '}
          <Text weight="bold" span>
            {!isNil(dscr) ? dscr : NOT_SPECIFIED}
          </Text>{' '}
          to{' '}
          <Text weight="bold" span>
            {!isNil(updatedDscr) ? updatedDscr.toFixed(2) : NOT_SPECIFIED}
          </Text>
        </List.Item>
      ) : undefined,
      hasProvisionChanged ? (
        <List.Item>
          Provision from{' '}
          <Text weight="bold" span>
            {!isNil(provision) ? currency(provision / 100).format() : NOT_SPECIFIED}
          </Text>{' '}
          to{' '}
          <Text weight="bold" span>
            {!isNil(updatedProvision) ? currency(updatedProvision / 100).format() : NOT_SPECIFIED}
          </Text>
        </List.Item>
      ) : undefined,
    ]);

    const titleParts = compact([
      hasApprovedFacilityLimitInCentsChanged || hasCurrentFacilityLimitInCentsChanged ? 'facility limits' : undefined,
      hasDscrChanged ? 'DSCR' : undefined,
      hasProvisionChanged ? 'provision' : undefined,
    ]);

    setChangeFacilityLimitsDSCRConfirmationModalTitle(`Change the ${titleParts.join('/')}`);

    if (contentParts.length) {
      setChangeFacilityLimitsDSCRConfirmationModalContents(
        <>
          <Text>Are you sure you want to change the following?</Text>

          <List mt="md" withPadding>
            {contentParts}
          </List>
          <>
            {hasApprovedFacilityLimitInCentsChanged || hasCurrentFacilityLimitInCentsChanged ? (
              <Text mt="md">Please make sure you have the Debtor Finance Agreement before proceeding.</Text>
            ) : undefined}
          </>
        </>,
      );
    }
  };

  const handleChangeFacilityLimitsModalClose = () => {
    setIsConfirmationLimitChangesModalOpened(false);
  };

  const handleSubmit = (
    currentDscr: EntityFinancialInfoFormData['dscr'],
    currentLoanFacility: EntityFinancialInfoTabLoanFacility,
    { approvedFacilityLimit, currentFacilityLimit, dscr: returnedDscr, provision }: EntityFinancialInfoFormData,
  ) => {
    const returnedApprovedFacilityLimitInCents = !isNil(approvedFacilityLimit) ? currency(approvedFacilityLimit).intValue : undefined;
    const returnedCurrentFacilityLimitInCents = currency(currentFacilityLimit).intValue;
    const returnedProvision = provision ? currency(provision).intValue : null;

    generateChangeFacilityLimitsDSCRConfirmationModalContents({
      dscr: currentDscr,
      inceptFacilityLimitInCents,
      loanFacility: currentLoanFacility,
      updatedApprovedFacilityLimitInCents: returnedApprovedFacilityLimitInCents,
      updatedCurrentFacilityLimitInCents: returnedCurrentFacilityLimitInCents,
      updatedDscr: returnedDscr,
      updatedProvision: returnedProvision,
    });

    setIsConfirmationLimitChangesModalOpened(true);
    setUpdatedApprovedFacilityLimitInCents(returnedApprovedFacilityLimitInCents);
    setUpdatedCurrentFacilityLimitInCents(returnedCurrentFacilityLimitInCents);
    setUpdatedDscr(returnedDscr);
    setUpdatedProvision(returnedProvision);
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLoanFacilityForEntityQuery({
      variables: {
        entityId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <>
      {isLoading ? (
        <Stack align="center" h="100%" justify="center">
          <BizPayLoader message="Retrieving financial info, please wait..." />
        </Stack>
      ) : (
        <>
          {loanFacility && (
            <>
              <BizPayConfirmationModal
                opened={isConfirmationLimitChangesModalOpened}
                size={600}
                title={changeFacilityLimitsDSCRConfirmationModalTitle}
                onClose={handleChangeFacilityLimitsModalClose}
                onConfirm={() => {
                  if (updatedApprovedFacilityLimitInCents && updatedApprovedFacilityLimitInCents !== inceptFacilityLimitInCents) {
                    executeUpdateEntityInceptFacilityLimitMutation({
                      variables: {
                        id: entityId,
                        input: {
                          inceptFacilityLimitInCents: updatedApprovedFacilityLimitInCents,
                        },
                      },
                    });
                  } else if (
                    (updatedCurrentFacilityLimitInCents && updatedCurrentFacilityLimitInCents !== loanFacility.limitInCents) ||
                    (updatedApprovedFacilityLimitInCents &&
                      updatedCurrentFacilityLimitInCents &&
                      updatedApprovedFacilityLimitInCents < updatedCurrentFacilityLimitInCents)
                  ) {
                    const updatedLoanFacilityLimitInCents =
                      updatedApprovedFacilityLimitInCents && updatedApprovedFacilityLimitInCents < updatedCurrentFacilityLimitInCents
                        ? updatedApprovedFacilityLimitInCents
                        : updatedCurrentFacilityLimitInCents;

                    executeUpdateLoanFacilityLimitForEntityMutation({
                      variables: {
                        id: loanFacility.id,
                        updatedLoanFacilityLimitInCents,
                      },
                    });
                  }

                  if (dscr !== updatedDscr || provision !== updatedProvision) {
                    executeUpdateEntityDetailsMutation({
                      variables: {
                        debtServiceCoverRatio: updatedDscr,
                        id: entityId,
                        provisionInCents: updatedProvision,
                      },
                    });
                  }
                }}
              >
                <Stack h="100%" justify="flex-start" pb="md" pt="md" w="100%">
                  {changeFacilityLimitsDSCRConfirmationModalContents}
                </Stack>
              </BizPayConfirmationModal>

              <EntityFinancialInfoForm
                isReadOnly={!isAuthorisedToUpdateFacilityLimit}
                isSubmitButtonDisabled={!isAuthorisedToUpdateFacilityLimit}
                values={{
                  approvedFacilityLimit: !isNil(inceptFacilityLimitInCents)
                    ? currency(inceptFacilityLimitInCents / 100).format({
                        symbol: '',
                      })
                    : '',
                  currentFacilityLimit: currency(loanFacility.limitInCents / 100).format({
                    symbol: '',
                  }),
                  dscr,
                  provision: provision
                    ? currency(provision / 100).format({
                        symbol: '',
                      })
                    : null,
                }}
                onSubmit={(formData) => handleSubmit(dscr, loanFacility, formData)}
              />

              {!isNil(loanFacility.remainingLimitInCents) && !isNil(loanFacility.totalAmountInCentsOfNonDisbursedLoanApplications) && (
                <Group mt="md" noWrap>
                  <Stack mr="xl">
                    <LoanFacilityUsagePieChart
                      colorPalette={[tealPalette[3], tealPalette[4], tealPalette[5]]}
                      data={[
                        {
                          groupName: 'Loan facility',
                          id: '1',
                          name: 'Total exposure %',
                          value: (loanFacility.entity.outstandingBalanceInCents / loanFacility.limitInCents) * 100,
                        },
                        {
                          groupName: 'Loan facility',
                          id: '2',
                          name: 'Submitted loan applications %',
                          value: (loanFacility.totalAmountInCentsOfNonDisbursedLoanApplications / loanFacility.limitInCents) * 100,
                        },
                        {
                          groupName: 'Loan facility',
                          id: '3',
                          name: 'Remaining balance %',
                          value: (calculateRemainingBalance(loanFacility) / loanFacility.limitInCents) * 100,
                        },
                      ]}
                      height={250}
                      strokePalette={[tealPalette[4], tealPalette[5], tealPalette[6]]}
                      width={200}
                    />
                  </Stack>

                  <Stack justify="center" ml="xs" w="100%">
                    <Grid gutter="sm">
                      <Grid.Col span="auto">
                        <Text weight="bold">Total exposure</Text>
                      </Grid.Col>

                      <Grid.Col span="content">
                        <Text align="right" weight="bold">
                          {currency(loanFacility.entity.outstandingBalanceInCents / 100).format()}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    <Grid gutter="sm" mt="xs">
                      <Grid.Col span="auto">
                        <Text weight="bold">Submitted loan applications</Text>
                      </Grid.Col>

                      <Grid.Col span="content">
                        <Text align="right" weight="bold">
                          {currency(loanFacility.totalAmountInCentsOfNonDisbursedLoanApplications / 100).format()}
                        </Text>
                      </Grid.Col>
                    </Grid>

                    <Grid gutter="sm" mt="xs">
                      <Grid.Col span="auto">
                        <Text weight="bold">Remaining balance</Text>
                        <Text
                          sx={() => ({
                            fontSize: 12,
                          })}
                        >
                          Funds that can be used to finance invoices
                        </Text>
                      </Grid.Col>

                      <Grid.Col span="content">
                        <Text align="right" weight="bold">
                          {currency(calculateRemainingBalance(loanFacility) / 100).format()}
                        </Text>
                      </Grid.Col>
                    </Grid>
                  </Stack>
                </Group>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export { EntityFinancialInfoTab };
