import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedAuditLogsForLoanApplicationQuery: DocumentNode = gql`
  query retrievePaginatedAuditLogsForLoanApplication($loanApplicationId: String!, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedAuditLogs(loanApplicationId: $loanApplicationId, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        description
        id
        staffMember {
          fullName
          id
        }
        user {
          fullName
          id
        }
      }
    }
  }
`;

export { retrievePaginatedAuditLogsForLoanApplicationQuery };
