import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedUsersQuery: DocumentNode = gql`
  query retrievePaginatedUsers($filters: [FilterInput!], $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedUsers(filters: $filters, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        email
        firstName
        id
        isTfaEnabled
        lastName
        lastSignedInAtUtc
        mobileNumber
        entity {
          id
          name
        }
      }
    }
  }
`;

export { retrievePaginatedUsersQuery };
