import { FC } from 'react';

import { Group } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { noCase, pluralize, sentenceCase } from 'common';
import Countdown from 'react-countdown';

import { BizPayTooltip } from '../BizPayTooltip';
import { RefreshDataIconButton } from '../RefreshDataIconButton';

import { RefreshDataControlProps } from './RefreshDataControl.types';

const RefreshDataControl: FC<RefreshDataControlProps> = ({
  dataType,
  isDisabled = false,
  isRefreshDataIconButtonDisabled,
  lastRetrievedDataAt = 'N/A',
  onRefreshDataIconButtonClick,
  refreshDataIntervalInMilliseconds,
}) => {
  if (isDisabled) {
    return null;
  }

  return (
    <Group w={50}>
      <RefreshDataIconButton
        isButtonDisabled={isRefreshDataIconButtonDisabled}
        tooltipText={
          isRefreshDataIconButtonDisabled ? (
            <Countdown
              date={
                Date.now() +
                (refreshDataIntervalInMilliseconds ?? Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS))
              }
              renderer={({ minutes, seconds }) => {
                const timeUnitDescription =
                  minutes > 0 ? `${minutes}:${seconds.toString().padStart(2, '0')} minutes` : `${seconds} seconds`;

                return `The latest ${noCase(dataType)} updates have been retrieved. Please try again in ${timeUnitDescription}.`;
              }}
            />
          ) : (
            `Click to retrieve the latest ${noCase(dataType)} updates.`
          )
        }
        onClick={onRefreshDataIconButtonClick}
      />

      <BizPayTooltip label={`${pluralize(sentenceCase(dataType))} last retrieved at: ${lastRetrievedDataAt}.`}>
        <IconInfoCircle size={20} />
      </BizPayTooltip>
    </Group>
  );
};

export { RefreshDataControl };
