import { FC, useEffect } from 'react';

import { Grid, TextInput, useForm } from 'ui';

import { LoanApplicationProductFormData, LoanApplicationProductFormProps } from './LoanApplicationProductForm.types';

const LoanApplicationProductForm: FC<LoanApplicationProductFormProps<LoanApplicationProductFormData>> = ({ initialDefaultValues }) => {
  const { register, reset } = useForm<LoanApplicationProductFormData>({
    defaultValues: initialDefaultValues,
  });

  useEffect(() => {
    reset(initialDefaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDefaultValues]);

  return (
    <form id="loan-application-product-form">
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('name')} label="Name" size="md" readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('frequencyType')} label="Repayment frequency" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('frequencyValue')} label="Number of repayments" size="md" readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('fee')} label="Fee" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('repaymentAmountInDollars')} label="Repayment amount" size="md" readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('feeAmountInDollars')} label="Fee amount" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('totalLoanAmountInDollars')} label="Total loan amount" size="md" readOnly />
        </Grid.Col>
      </Grid>
    </form>
  );
};

export { LoanApplicationProductForm };
