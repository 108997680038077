import { FC, useEffect, useState } from 'react';

import { BIZPAY_COMMON_CONSTANTS, dayjs, useAuthentication } from 'common';
import { useRetrieveEntityStakeholderShareholdersForEntityLazyQuery } from 'graphql-library';
import {
  BizPayTable,
  createColumnHelper,
  FullHeightContainer,
  getCoreRowModel,
  TablePageLayout,
  Text,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { EntityStakeholderShareholderForEntity, EntityStakeholdersShareholderTableProps } from './EntityStakeholderShareholdersTable.types';

import { useSignOut } from '../../hooks';

const { dateFormat } = BIZPAY_COMMON_CONSTANTS;

const EntityStakeholderShareholdersTable: FC<EntityStakeholdersShareholderTableProps> = ({ entityId }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();
  const { displayErrorNotification } = useBizPayNotification();

  const [shareholders, setShareholders] = useState<EntityStakeholderShareholderForEntity[]>([]);
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);

  const isAuthenticated = getIsAuthenticated();

  const [executeRetrieveShareholdersForEntityQuery, { loading: isLoading }] = useRetrieveEntityStakeholderShareholdersForEntityLazyQuery({
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveEntityStakeholderShareholdersForEntity: stakeholdersData }) => {
      setShareholders(stakeholdersData);
      setHasRetrievedData(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve entity stakeholders',
      });
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveShareholdersForEntityQuery({
      variables: {
        entityId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<EntityStakeholderShareholderForEntity>();

    return [
      columnHelper.accessor('shareholderType', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Type',
        size: 0,
        minSize: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('name', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Name',
        size: 250,
        minSize: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('abn', {
        cell: ({ getValue }) => (getValue() ? getValue() : '-'),
        enableSorting: false,
        header: 'ABN',
        size: 0,
        minSize: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('dateOfBirth', {
        cell: ({ getValue }) => {
          return getValue() ? dayjs(getValue()).format(dateFormat) : '-';
        },
        enableSorting: false,
        header: 'Date of birth',
        size: 250,
        sortDescFirst: false,
      }),
      columnHelper.accessor('phoneNumber', {
        cell: ({ getValue }) => (getValue() ? getValue() : '-'),
        enableSorting: false,
        header: 'Phone number',
        size: 200,
        sortDescFirst: false,
      }),
      columnHelper.accessor('email', {
        cell: ({ getValue }) => (getValue() ? getValue() : '-'),
        enableSorting: false,
        header: 'Email address',
        size: 200,
        sortDescFirst: false,
      }),
      columnHelper.accessor('isBeneficiallyHeld', {
        cell: ({ getValue }) => (getValue() ? 'Yes' : 'No'),
        enableSorting: false,
        header: 'Beneficially held',
        size: 200,
        sortDescFirst: false,
      }),
    ];
  };

  const { getHeaderGroups, getRowModel } = useReactTable<EntityStakeholderShareholderForEntity>({
    columns: getTableColumns(),
    data: shareholders,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Text mb="md" ml="0.3rem" mt="md" weight="bold">
        SHAREHOLDERS
      </Text>

      <FullHeightContainer>
        <TablePageLayout
          loadingMessage={isLoading ? 'Retrieving stakeholders...' : undefined}
          tableComponent={
            <BizPayTable
              hasRetrievedData={hasRetrievedData}
              headerGroups={getHeaderGroups()}
              noRecordsMessage="No stakeholders found for this entity"
              rowModel={getRowModel()}
            />
          }
        />
      </FullHeightContainer>
    </>
  );
};

export { EntityStakeholderShareholdersTable };
