import { FC, useEffect, useState } from 'react';

import { dayjs, sentenceCase, useAuthentication, useNumberFormatter } from 'common';
import { useRetrievePaginatedLoanApplicationsForDisbursementLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  RefreshDataControl,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LOAN_APPLICATIONS_PAGE_CONSTANTS } from '../LoanApplicationsPage';

import {
  LoanApplicationsForDisbursementTableProps,
  PaginatedLoanApplicationForDisbursement,
} from './LoanApplicationsForDisbursementTable.types';

import { useSignOut } from '../../hooks';

const LoanApplicationsForDisbursementTable: FC<LoanApplicationsForDisbursementTableProps<PaginatedLoanApplicationForDisbursement>> = ({
  adminStatuses,
  onLoadTableDataCompleted,
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
  refetchDataOptions: { onRefetchDataCompleted, shouldRefetchData },
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { formatCurrency } = useNumberFormatter();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [loanApplications, setLoanApplications] = useState<PaginatedLoanApplicationForDisbursement[]>([]);
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [shouldRefetchDataInternal, setShouldRefetchDataInternal] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'approvedAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [executeRetrieveLoanApplicationsForDisbursementQuery, { loading: isRetrieveLoanApplicationsLoading, refetch }] =
    useRetrievePaginatedLoanApplicationsForDisbursementLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrievePaginatedLoanApplicationsByAdminStatus: { count, data } }) => {
        setHasRetrievedData(true);
        setIsRefreshDataIconButtonDisabled(true);
        setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
        setLoanApplications(data);
        setShouldRefetchDataInternal(false);
        setTotalPages(calculateTotalPages(currentPageSize, count));
        setTotalRecords(count);

        onLoadTableDataCompleted?.(count, data);
        onRefetchDataCompleted?.();

        if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
          push(`${route}?pageNumber=${currentPageNumber}`, undefined, {
            shallow: true,
          });
        }
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve loan applications awaiting disbursement',
        });
      },
    });

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<PaginatedLoanApplicationForDisbursement>();

    return [
      columnHelper.accessor('niceId', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Id',
        size: 150,
      }),
      columnHelper.accessor('entity.name', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Entity name',
        size: 200,
      }),
      columnHelper.accessor(
        ({ invoice }) => ({
          invoice,
        }),
        {
          cell: ({ getValue }) => {
            const { invoice } = getValue();

            return invoice?.supplier?.name ?? '-';
          },
          enableSorting: false,
          header: 'Supplier name',
          size: 300,
        },
      ),
      columnHelper.accessor('totalLoanAmountInCents', {
        cell: ({ getValue }) => formatCurrency(getValue()),
        enableSorting: false,
        header: 'Total loan amount',
        meta: {
          align: 'right',
        },
        size: 150,
      }),
      columnHelper.accessor('adminStatus', {
        cell: ({ getValue }) => {
          const adminStatus = getValue();
          return adminStatus ? sentenceCase(adminStatus) : '-';
        },
        enableSorting: false,
        header: 'Status',
        meta: {
          align: 'center',
        },
        size: 200,
      }),
      columnHelper.accessor('approvedAtUtc', {
        cell: ({ getValue }) => dayjs(getValue()).toDate().toLocaleString(),
        enableSorting: false,
        header: 'Date approved',
        meta: {
          align: 'center',
        },
        size: 200,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="loan-application"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          minSize: 10,
          size: 10,
        },
      ),
    ];
  };

  const handleLoanApplicationClick = ({ id }: PaginatedLoanApplicationForDisbursement) => {
    push(`/loan-applications/${id}?returnTab=${LOAN_APPLICATIONS_PAGE_CONSTANTS.tabs.ids.loanApplicationsForDisbursement}`);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchDataInternal(true);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const isAuthenticated = getIsAuthenticated();

  const { getHeaderGroups, getRowModel } = useReactTable<PaginatedLoanApplicationForDisbursement>({
    columns: getTableColumns(),
    data: loanApplications,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLoanApplicationsForDisbursementQuery({
      variables: {
        adminStatuses,
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!(shouldRefetchData || shouldRefetchDataInternal)) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData, shouldRefetchDataInternal]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <TablePageLayout
      loadingMessage={isRetrieveLoanApplicationsLoading ? 'Retrieving loan applications awaiting disbursement...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<PaginatedLoanApplicationForDisbursement>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No loan applications awaiting disbursement found"
          rowModel={getRowModel()}
          onRowClick={handleLoanApplicationClick}
        />
      }
    />
  );
};

export { LoanApplicationsForDisbursementTable };
