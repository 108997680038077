import dayjs from 'dayjs';
import { jwtDecode, JwtPayload } from 'jwt-decode';

import { USE_JWT_DECODER_CONSTANTS } from './useJwtDecoder.constants';

const {
  errorMessages: { isRequired },
} = USE_JWT_DECODER_CONSTANTS;

const getDecodedJwt = <T extends JwtPayload>(token: string): T => {
  if (!token) {
    throw new Error(isRequired);
  }

  return jwtDecode<T>(token);
};

const getJwtExpiryDate = <T extends JwtPayload>(decodedToken: T) => {
  const { exp } = decodedToken;

  if (!exp) {
    return undefined;
  }

  return dayjs(exp * 1000).toDate();
};

const hasJwtExpired = <T extends JwtPayload>(decodedToken: T) => {
  const tokenExpiryDate = getJwtExpiryDate(decodedToken);

  if (!tokenExpiryDate) {
    return false;
  }

  return tokenExpiryDate < dayjs().toDate();
};

export { getDecodedJwt, getJwtExpiryDate, hasJwtExpired };
