import { FC } from 'react';

import { BizPayPrimaryButton, ErrorMessage, Group, Stack, TextInput, TextInputRightSection, useForm } from 'ui';

import { partnerFormZodResolver } from './PartnerForm.helpers';
import { PartnerFormData, PartnerFormProps } from './PartnerForm.types';

const PartnerForm: FC<PartnerFormProps<PartnerFormData>> = ({ onSubmit }) => {
  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    watch,
  } = useForm<PartnerFormData>({
    mode: 'onChange',
    resolver: partnerFormZodResolver(),
  });

  const { name: nameError } = errors;

  return (
    <form
      id="partner-form"
      style={{
        height: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack h="100%" justify="center" w="100%">
        <Stack h="100%" mt="md" w="100%">
          <TextInput
            {...register('name')}
            error={nameError ? <ErrorMessage message={nameError.message} /> : null}
            label="Business name"
            rightSection={<TextInputRightSection error={nameError} value={watch('name')} />}
            size="md"
          />
        </Stack>

        <Group mt="md" position="right" w="100%">
          <BizPayPrimaryButton disabled={!isValid} type="submit">
            Create partner
          </BizPayPrimaryButton>
        </Group>
      </Stack>
    </form>
  );
};

export { PartnerForm };
