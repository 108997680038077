import { FC } from 'react';

import { Card, Stack, Text } from '@mantine/core';

import { MessageBoxProps } from './MessageBox.types';

const MessageBox: FC<MessageBoxProps> = ({ author, cardProps, text, timestamp }) => {
  return (
    <Card
      padding="sm"
      radius="md"
      sx={({ colors }) => ({
        backgroundColor: colors.gray[0],
      })}
      withBorder
      {...cardProps}
    >
      <Stack align="flex-start">
        <Text
          size={14}
          sx={({ colors }) => ({
            color: colors.gray[6],
          })}
          weight="bold"
        >
          {author}
        </Text>

        <Text
          size={14}
          sx={({ colors }) => ({
            color: colors.gray[6],
          })}
        >
          {timestamp}
        </Text>
      </Stack>

      <Text
        mt="lg"
        size={14}
        style={{
          lineHeight: 1.25,
          whiteSpace: 'pre-wrap',
          wordBreak: 'break-word',
        }}
      >
        {text}
      </Text>
    </Card>
  );
};

export { MessageBox };
