import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedLoanApplicationsIncompleteQuery: DocumentNode = gql`
  query retrievePaginatedLoanApplicationsIncomplete($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedLoanApplicationsByCustomerStatus(
      customerStatuses: [Confirm, SelectDirectDebitAccount, SelectRepaymentPlan, UploadInvoice]
      pagination: $pagination
      sort: $sort
    ) {
      count
      data {
        createdAtUtc
        customerStatus
        entity {
          name
          id
        }
        id
        invoice {
          id
          supplier {
            id
            name
          }
          totalAmountInCents
        }
        niceId
        product {
          displayName
          id
          name
        }
        updatedAtUtc
      }
    }
  }
`;

export { retrievePaginatedLoanApplicationsIncompleteQuery };
