import { FC } from 'react';

import { Loader, LoadingOverlay, Stack, Text } from '@mantine/core';

import { BizPayLoadingOverlayProps } from './BizPayLoadingOverlay.types';

const BizPayLoadingOverlay: FC<BizPayLoadingOverlayProps> = ({ message }) => {
  return (
    <LoadingOverlay
      loader={
        <Stack align="center">
          <Loader mb="md" variant="dots" />
          {message && <Text>{message}</Text>}
        </Stack>
      }
      radius="lg"
      visible
    />
  );
};

export { BizPayLoadingOverlay };
