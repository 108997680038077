import { DocumentNode, gql } from 'graphql-library';

const retrieveEntityStakeholderDirectorsForEntityQuery: DocumentNode = gql`
  query retrieveEntityStakeholderDirectorsForEntity($entityId: String!) {
    retrieveEntityStakeholderDirectorsForEntity(entityId: $entityId) {
      dateOfBirth
      email
      fullName
      id
      phoneNumber
    }
  }
`;

export { retrieveEntityStakeholderDirectorsForEntityQuery };
