import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { z } from 'zod';

const uploadEntityDocumentFormZodResolver = () => {
  return zodResolver(
    z.object({
      description: z.string(),
      document: z.any().refine(
        (value) => {
          if (!value) {
            return false;
          }

          return true;
        },
        {
          message: 'Provide the document',
        },
      ),
      documentType: z.string({
        required_error: 'Please select a document type',
      }),
    }),
  );
};

export { uploadEntityDocumentFormZodResolver };
