import { FC } from 'react';

import { Group, Stack } from '@mantine/core';

import { BizPayLoader } from '../BizPayLoader';
import { BizPayModal } from '../BizPayModal';
import { BizPayOutlinedButton } from '../BizPayOutlinedButton';

import { BizPayConfirmationModalProps } from './BizPayConfirmationModal.types';

const BizPayConfirmationModal: FC<BizPayConfirmationModalProps> = ({
  children,
  h = 250,
  loadingMessage,
  onClose,
  onConfirm,
  ...otherProps
}) => {
  const isLoading = !!loadingMessage;

  return (
    <BizPayModal onClose={onClose} {...otherProps} closeOnClickOutside={!isLoading} withCloseButton={!isLoading}>
      <Stack align="center" h={h} justify="center" w="100%">
        <Stack align="center" h="100%" justify="center" w="100%">
          {isLoading ? <BizPayLoader message={loadingMessage} /> : children}
        </Stack>

        {!isLoading && (
          <Group position="center" w="100%">
            <BizPayOutlinedButton w={150} onClick={onClose}>
              No
            </BizPayOutlinedButton>

            <BizPayOutlinedButton ml="xs" w={150} onClick={onConfirm}>
              Yes
            </BizPayOutlinedButton>
          </Group>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { BizPayConfirmationModal };
