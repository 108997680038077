import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';

import { UseBizPayNotification } from './useBizPayNotification.types';

const useBizPayNotification = (): UseBizPayNotification => {
  const displayErrorNotification: UseBizPayNotification['displayErrorNotification'] = (options) => {
    const { id, message, title = 'Error' } = options ?? {};

    showNotification({
      color: 'red.8',
      icon: <IconX size={14} />,
      id,
      message,
      title,
    });
  };

  const displayNotification: UseBizPayNotification['displayErrorNotification'] = (options) => {
    const { message } = options ?? {};

    showNotification({
      color: 'bizpay-white',
      message,
    });
  };

  const displaySuccessNotification: UseBizPayNotification['displaySuccessNotification'] = (options) => {
    const { id, message, title = 'Success' } = options ?? {};

    showNotification({
      color: 'bizpay-teal.5',
      icon: <IconCheck size={14} />,
      id,
      message,
      title,
    });
  };

  return {
    displayErrorNotification,
    displayNotification,
    displaySuccessNotification,
  };
};

export { useBizPayNotification };
