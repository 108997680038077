import { zodResolver, z } from 'ui';

const productFormZodResolver = () => {
  return zodResolver(
    z.object({
      code: z.string().min(1, {
        message: 'Code is required',
      }),
      customerFee: z.number().nullable().optional(),
      displayName: z.string(),
      frequencyValue: z.number().min(1, {
        message: 'Frequency value is required',
      }),
      feeType: z.string().min(1, {
        message: 'Fee type is required',
      }),
      feeValue: z.number().min(0, {
        message: 'Fee value is required',
      }),
      frequencyType: z.string().min(1, {
        message: 'Frequency type is required',
      }),
      partnerFee: z.number().nullable().optional(),
    }),
  );
};

export { productFormZodResolver };
