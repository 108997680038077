import { sentenceCase } from 'common';
import { IconBuildingSkyscraper, IconBox, IconFileDollar, IconFriends, IconUsers, IconFileText } from 'ui';

import { ProtectedSideMenuNavigationRoutesPage, ProtectedRoute } from './useNavigationRoutes.types';

const USE_NAVIGATION_ROUTE_CONSTANTS = Object.freeze({
  sideMenuRoutes: Object.freeze<ProtectedSideMenuNavigationRoutesPage>({
    [ProtectedRoute.Entities]: {
      menuIcon: <IconBuildingSkyscraper size={20} />,
      title: sentenceCase(ProtectedRoute.Entities),
    },
    [ProtectedRoute.LoanApplications]: {
      menuIcon: <IconFileText size={20} />,
      title: sentenceCase(ProtectedRoute.LoanApplications),
    },
    [ProtectedRoute.Loans]: {
      menuIcon: <IconFileDollar size={20} />,
      title: sentenceCase(ProtectedRoute.Loans),
    },
    [ProtectedRoute.Partners]: {
      menuIcon: <IconUsers size={20} />,
      title: sentenceCase(ProtectedRoute.Partners),
    },
    [ProtectedRoute.Products]: {
      menuIcon: <IconBox size={20} />,
      title: sentenceCase(ProtectedRoute.Products),
    },
    [ProtectedRoute.StaffMembers]: {
      menuIcon: <IconFriends size={20} />,
      title: sentenceCase(ProtectedRoute.StaffMembers),
    },
    [ProtectedRoute.Users]: {
      menuIcon: <IconUsers size={20} />,
      title: sentenceCase(ProtectedRoute.Users),
    },
  }),
});

export { USE_NAVIGATION_ROUTE_CONSTANTS };
