import { pluralize, useNumberFormatter } from 'common';

import { UseProductHelpers } from './useProductHelpers.types';

import { ProductFrequencyType } from '../../types';

const useProductHelpers = (): UseProductHelpers => {
  const { formatCurrency } = useNumberFormatter();

  const calculateFeeAmountInCents: UseProductHelpers['calculateFeeAmountInCents'] = ({
    product: { feeType, feeValue },
    totalInvoiceAmountInCents,
  }) => {
    let feeAmountInCents = 0;

    switch (feeType) {
      case 'FixedFee': {
        feeAmountInCents = feeValue;
        break;
      }

      case 'Percentage': {
        feeAmountInCents = (totalInvoiceAmountInCents * feeValue) / 100;
        break;
      }
    }

    return Math.round(feeAmountInCents);
  };

  const calculateLoanAmountInCents: UseProductHelpers['calculateLoanAmountInCents'] = ({ product, totalInvoiceAmountInCents }) => {
    const feeAmountInCents = calculateFeeAmountInCents({
      product,
      totalInvoiceAmountInCents,
    });

    return feeAmountInCents + totalInvoiceAmountInCents;
  };

  const calculateLoanRepaymentAmountInCents: UseProductHelpers['calculateLoanRepaymentAmountInCents'] = ({
    product,
    totalInvoiceAmountInCents,
  }) => {
    const loanAmountInCents = calculateLoanAmountInCents({
      product,
      totalInvoiceAmountInCents,
    });

    return Math.round(loanAmountInCents / product.frequencyValue);
  };

  const convertFrequencyTypeToAdjective: UseProductHelpers['convertFrequencyTypeToAdjective'] = (frequencyType) => {
    return `${frequencyType.toLowerCase()}ly`;
  };

  const generateProductLoanRepaymentDescription: UseProductHelpers['generateProductLoanRepaymentDescription'] = ({
    product,
    totalInvoiceAmountInCents,
  }) => {
    if (!totalInvoiceAmountInCents) {
      return;
    }

    const loanRepaymentAmountInCents = calculateLoanRepaymentAmountInCents({
      product,
      totalInvoiceAmountInCents,
    });

    const loanAmountInCents = calculateLoanAmountInCents({
      product,
      totalInvoiceAmountInCents,
    });

    const { frequencyType, frequencyValue } = product;

    return `Pay ${frequencyValue} ${convertFrequencyTypeToAdjective(frequencyType)} repayment ${pluralize(
      'amount',
      frequencyValue,
    )} of ${formatCurrency(loanRepaymentAmountInCents)}.\nTotal loan amount will be ${formatCurrency(loanAmountInCents)}.`;
  };

  return {
    calculateFeeAmountInCents,
    calculateLoanAmountInCents,
    calculateLoanRepaymentAmountInCents,
    convertFrequencyTypeToAdjective,
    generateProductLoanRepaymentDescription,
  };
};

export { useProductHelpers };
