import { FC, useState } from 'react';

import { Cell, Legend, Pie, PieChart, Sector, Tooltip } from 'recharts';

import { BizPayPieChartProps } from './BizPayPieChart.types';

import { useBizPayUITheme } from '../../hooks';

const renderActiveShape = (props: {
  cx: number;
  cy: number;
  endAngle: number;
  fill: string;
  innerRadius: number;
  midAngle: number;
  outerRadius: number;
  payload: {
    groupName: string;
    name: string;
  };
  percent: number;
  startAngle: number;
  value: unknown;
}) => {
  const { cx, cy, endAngle, fill, innerRadius, outerRadius, payload, startAngle } = props;

  return (
    <g>
      <text dy={8} fill="#000" fontSize={12} textAnchor="middle" x={cx} y={cy}>
        {payload.groupName}
      </text>
      <Sector cx={cx} cy={cy} endAngle={endAngle} fill={fill} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} />
    </g>
  );
};

const BizPayPieChart: FC<BizPayPieChartProps> = ({ colorPalette, data, height, isLegendVisible = false, strokePalette, width }) => {
  const {
    theme: { fontSizes },
  } = useBizPayUITheme();

  const [activeIndex, setActiveIndex] = useState<number>(0);

  const handlePieSegmentHover = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <PieChart height={height} layout="centric" width={width}>
      <Tooltip
        contentStyle={{
          fontSize: Number(fontSizes?.xs),
        }}
        formatter={(value) => Number(value.valueOf()).toFixed(1)}
      />

      {isLegendVisible && <Legend verticalAlign="bottom" />}

      <Pie
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        cx="50%"
        cy="50%"
        data={data}
        dataKey="value"
        innerRadius={60}
        nameKey="name"
        outerRadius={80}
        onMouseEnter={(_, index) => handlePieSegmentHover(index)}
      >
        {data.map(({ id }, index) => (
          <Cell key={`cell-${id}`} fill={colorPalette[index]} stroke={strokePalette[index]} />
        ))}
      </Pie>
    </PieChart>
  );
};

export { BizPayPieChart };
