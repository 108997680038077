import { FC } from 'react';

import { Menu } from '@mantine/core';

import { UserDropdownMenuItemProps } from './UserDropdownMenuItem.types';

const UserDropdownMenuItem: FC<UserDropdownMenuItemProps> = ({ icon, label, onClick }) => {
  return (
    <Menu.Item icon={icon} onClick={onClick}>
      {label}
    </Menu.Item>
  );
};

export { UserDropdownMenuItem };
