/* eslint-disable @typescript-eslint/indent */

import {
  createAccessTokenCookie,
  createAppVersionCookie,
  createCookie,
  createLastVisitedUrlCookie,
  createRefreshTokenCookie,
  deleteAccessTokenCookie,
  deleteCookie,
  deleteLastVisitedUrlCookie,
  deleteRefreshTokenCookie,
  deserializeCookie,
  getAccessTokenCookie,
  getAllCookies,
  getAppVersionCookie,
  getCookieValue,
  getLastVisitedUrlCookie,
  getRefreshTokenCookie,
  isSecureCookie,
  isValidCookieSize,
  serializeCookie,
  setCookie,
} from './useCookie.helpers';
import { UseCookie } from './useCookie.types';

const useCookie = (): UseCookie => {
  return {
    createAccessTokenCookie,
    createAppVersionCookie,
    createCookie,
    createLastVisitedUrlCookie,
    createRefreshTokenCookie,
    deleteAccessTokenCookie,
    deleteCookie,
    deleteLastVisitedUrlCookie,
    deleteRefreshTokenCookie,
    deserializeCookie,
    getAccessTokenCookie,
    getAppVersionCookie,
    getAllCookies,
    getCookieValue,
    getLastVisitedUrlCookie,
    getRefreshTokenCookie,
    isSecureCookie,
    isValidCookieSize,
    serializeCookie,
    setCookie,
  };
};

export { useCookie };
