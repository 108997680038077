import currency from 'currency.js';

import { UseNumberFormatter } from './useNumberFormatter.types';

const formatCurrency: UseNumberFormatter['formatCurrency'] = (amountInCents) => {
  return currency(amountInCents / 100).format();
};

const formatPercentage: UseNumberFormatter['formatPercentage'] = (value, decimalPlaces = 2) => {
  return `${value.toFixed(decimalPlaces)}%`;
};

const formatWithThousandsSeparator: UseNumberFormatter['formatWithThousandsSeparator'] = (value, decimalPlaces = 2) => {
  const numberParts = value.toFixed(decimalPlaces).toString().split('.');
  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return numberParts.join('.');
};

export { formatCurrency, formatPercentage, formatWithThousandsSeparator };
