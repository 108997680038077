import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedNotesForLoanApplicationQuery: DocumentNode = gql`
  query retrievePaginatedNotesForLoanApplication($loanApplicationId: String!, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedNotesForLoanApplication(loanApplicationId: $loanApplicationId, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        id
        message
        submittedByStaffMember {
          fullName
          id
        }
      }
    }
  }
`;

export { retrievePaginatedNotesForLoanApplicationQuery };
