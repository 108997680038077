import { sentenceCase } from 'common';

import { LoanApplicationsPageTabsConfig } from './LoanApplicationsPage.types';

const LOAN_APPLICATIONS_DECLINED_WITHDRAWN_TAB_ID = 'declined-withdrawn';
const LOAN_APPLICATIONS_FOR_DISBURSEMENT_TAB_ID = 'for-disbursement';
const LOAN_APPLICATIONS_FOR_REVIEW_TAB_ID = 'for-review';
const LOAN_APPLICATIONS_INCOMPLETE_TAB_ID = 'incomplete';

const LOAN_APPLICATIONS_PAGE_TABS_CONFIG: LoanApplicationsPageTabsConfig[] = [
  {
    id: LOAN_APPLICATIONS_FOR_REVIEW_TAB_ID,
    title: sentenceCase(LOAN_APPLICATIONS_FOR_REVIEW_TAB_ID),
  },
  {
    id: LOAN_APPLICATIONS_FOR_DISBURSEMENT_TAB_ID,
    title: sentenceCase(LOAN_APPLICATIONS_FOR_DISBURSEMENT_TAB_ID),
  },
  {
    id: LOAN_APPLICATIONS_DECLINED_WITHDRAWN_TAB_ID,
    title: 'Declined/Withdrawn',
  },
  {
    id: LOAN_APPLICATIONS_INCOMPLETE_TAB_ID,
    title: sentenceCase(LOAN_APPLICATIONS_INCOMPLETE_TAB_ID),
  },
];

const LOAN_APPLICATIONS_PAGE_CONSTANTS = Object.freeze({
  tabs: {
    config: LOAN_APPLICATIONS_PAGE_TABS_CONFIG,
    ids: {
      loanApplicationsDeclinedWithdrawn: LOAN_APPLICATIONS_DECLINED_WITHDRAWN_TAB_ID,
      loanApplicationsForDisbursement: LOAN_APPLICATIONS_FOR_DISBURSEMENT_TAB_ID,
      loanApplicationsForReview: LOAN_APPLICATIONS_FOR_REVIEW_TAB_ID,
      loanApplicationsIncomplete: LOAN_APPLICATIONS_INCOMPLETE_TAB_ID,
    },
  },
});

export { LOAN_APPLICATIONS_PAGE_CONSTANTS };
