const BIZPAY_HEADER_CONSTANTS = Object.freeze({
  defaults: {
    heightInPixels: 80,
    marginInPixels: {
      left: 30,
      right: 22,
    },
  },
  logo: {
    width: 100,
  },
});

export { BIZPAY_HEADER_CONSTANTS };
