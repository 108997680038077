import { Tuple } from '@mantine/core';

const BIZPAY_DARK_BLUE_GRAY_PALETTE_COLORS: Tuple<string, 10> = [
  '#f6f9fb',
  '#e0edf4',
  '#c7dae4',
  '#a6c1d0',
  '#839aa9',
  '#5d707c',
  '#475965',
  '#31414c',
  '#1e2c35',
  '#0d1317',
];

const BIZPAY_LIGHT_GRAY_PALETTE_COLORS: Tuple<string, 10> = [
  '#fdfdfd',
  '#e8f0f2',
  '#dde6e9',
  '#c1d4da',
  '#97b5be',
  '#658598',
  '#537386',
  '#3d5c6f',
  '#2c4a5c',
  '#1a3545',
];

const BIZPAY_RED_PALETTE_COLORS: Tuple<string, 10> = [
  '#fffafa',
  '#ffe1e1',
  '#ffcaca',
  '#ffa3a3',
  '#ff6f6f',
  '#ff4646',
  '#ff1f1f',
  '#e00f0f',
  '#ad1010',
  '#8f0a0a',
];

const BIZPAY_TEAL_PALETTE_COLORS: Tuple<string, 10> = [
  '#f6ffff',
  '#eafffd',
  '#cafffb',
  '#81fff6',
  '#37edde',
  '#25d1c3',
  '#22bdb0',
  '#1baa9e',
  '#158d83',
  '#0d736a',
];

const BIZPAY_YELLOW_PALETTE_COLORS: Tuple<string, 10> = [
  '#fffaca',
  '#fff59f',
  '#fff281',
  '#ffe974',
  '#ffdb5c',
  '#ffc531',
  '#ffb21d',
  '#ffa115',
  '#f39202',
  '#dc8402',
];

// eslint-disable-next-line prefer-destructuring
const BIZPAY_TEAL = BIZPAY_TEAL_PALETTE_COLORS[5];

const USE_BIZPAY_UI_THEME_CONSTANTS = Object.freeze({
  colors: {
    darkBlueGrayPalette: BIZPAY_DARK_BLUE_GRAY_PALETTE_COLORS,
    lightGrayPalette: BIZPAY_LIGHT_GRAY_PALETTE_COLORS,
    redPalette: BIZPAY_RED_PALETTE_COLORS,
    teal: BIZPAY_TEAL,
    tealPalette: BIZPAY_TEAL_PALETTE_COLORS,
    yellowPalette: BIZPAY_YELLOW_PALETTE_COLORS,
  },
  inputFocusStyles: Object.freeze({
    border: {
      color: BIZPAY_TEAL,
      width: '1px',
    },
  }),
});

export { USE_BIZPAY_UI_THEME_CONSTANTS };
