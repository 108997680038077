import { FC, useEffect, useState } from 'react';

import { BIZPAY_COMMON_CONSTANTS, dayjs, useAuthentication } from 'common';
import { useRetrieveEntityStakeholderDirectorsForEntityLazyQuery } from 'graphql-library';
import {
  BizPayTable,
  createColumnHelper,
  FullHeightContainer,
  getCoreRowModel,
  TablePageLayout,
  Text,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { EntityStakeholderDirectorForEntity, EntityStakeholderDirectorsTableProps } from './EntityStakeholderDirectorsTable.types';

import { useSignOut } from '../../hooks';

const { dateFormat } = BIZPAY_COMMON_CONSTANTS;

const EntityStakeholderDirectorsTable: FC<EntityStakeholderDirectorsTableProps> = ({ entityId }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();
  const { displayErrorNotification } = useBizPayNotification();

  const [directors, setDirectors] = useState<EntityStakeholderDirectorForEntity[]>([]);
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);

  const isAuthenticated = getIsAuthenticated();

  const [executeRetrieveDirectorsForEntityQuery, { loading: isLoading }] = useRetrieveEntityStakeholderDirectorsForEntityLazyQuery({
    nextFetchPolicy: 'cache-and-network',
    onCompleted: ({ retrieveEntityStakeholderDirectorsForEntity: directorsData }) => {
      setDirectors(directorsData);
      setHasRetrievedData(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve entity directors',
      });
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveDirectorsForEntityQuery({
      variables: {
        entityId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const getTableColumns = () => {
    const columnHelper = createColumnHelper<EntityStakeholderDirectorForEntity>();

    return [
      columnHelper.accessor('fullName', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Full name',
        size: 0,
        minSize: 0,
        sortDescFirst: false,
      }),
      columnHelper.accessor('dateOfBirth', {
        cell: ({ getValue }) => dayjs(getValue()).format(dateFormat),
        enableSorting: false,
        header: 'Date of birth',
        size: 250,
        sortDescFirst: false,
      }),
      columnHelper.accessor('phoneNumber', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Phone number',
        size: 200,
        sortDescFirst: false,
      }),
      columnHelper.accessor('email', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Email address',
        size: 200,
        sortDescFirst: false,
      }),
    ];
  };

  const { getHeaderGroups, getRowModel } = useReactTable<EntityStakeholderDirectorForEntity>({
    columns: getTableColumns(),
    data: directors,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <>
      <Text mb="md" ml="0.3rem" weight="bold">
        DIRECTORS
      </Text>

      <FullHeightContainer>
        <TablePageLayout
          loadingMessage={isLoading ? 'Retrieving directors...' : undefined}
          tableComponent={
            <BizPayTable
              hasRetrievedData={hasRetrievedData}
              headerGroups={getHeaderGroups()}
              noRecordsMessage="No directors found for this entity"
              rowModel={getRowModel()}
            />
          }
        />
      </FullHeightContainer>
    </>
  );
};

export { EntityStakeholderDirectorsTable };
