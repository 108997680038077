import { FC } from 'react';

import { Button } from '@mantine/core';

import { BizPayCancelButtonProps } from './BizPayCancelButton.types';

const BizPayCancelButton: FC<BizPayCancelButtonProps> = ({ children, ...otherProps }) => {
  return (
    <Button
      size="md"
      sx={({ black, colors, white }) => ({
        backgroundColor: white,
        borderColor: black,
        color: black,
        ':hover': {
          backgroundColor: colors.gray[2],
        },
      })}
      type="button"
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export { BizPayCancelButton };
