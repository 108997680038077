import { LoanApplicationAdminStatus } from 'graphql-library';

import { LOAN_APPLICATION_ADMIN_STATUS_FORM_CONSTANTS } from './LoanApplicationActions.constants';

const generateLoanApplicationStatusDropdownListOptions = (
  hasGeneratedDisbursementsFileToday: boolean,
  selectedLoanApplicationAdminStatus?: LoanApplicationAdminStatus | null,
) => {
  if (!selectedLoanApplicationAdminStatus) {
    return [];
  }

  const { selectedLoanApplicationAdminStatusToLoanApplicationAdminStatusesMap } = LOAN_APPLICATION_ADMIN_STATUS_FORM_CONSTANTS;

  return hasGeneratedDisbursementsFileToday && selectedLoanApplicationAdminStatus === LoanApplicationAdminStatus.Approved
    ? [LoanApplicationAdminStatus.Approved, LoanApplicationAdminStatus.AwaitingDisbursement]
    : selectedLoanApplicationAdminStatusToLoanApplicationAdminStatusesMap[selectedLoanApplicationAdminStatus];
};

export { generateLoanApplicationStatusDropdownListOptions };
