import { sentenceCase } from 'common';
import { EntityAccountStatus } from 'graphql-library';
import { USE_BIZPAY_UI_THEME_CONSTANTS } from 'ui';

import { EntityPageTabsConfig } from './EntityPage.types';

const {
  colors: { darkBlueGrayPalette, redPalette, yellowPalette },
} = USE_BIZPAY_UI_THEME_CONSTANTS;

const ENTITY_ACCOUNT_STATUS_TO_BACKGROUND_COLOR_MAP = Object.freeze<Record<EntityAccountStatus, string>>({
  BadDebt: redPalette[2],
  Current: '#c7ffd3',
  DoubtfulDebt: yellowPalette[1],
  PromiseToPay: darkBlueGrayPalette[1],
});

const ENTITY_ACCOUNT_STATUS_TO_BORDER_COLOR_MAP = Object.freeze<Record<EntityAccountStatus, string>>({
  BadDebt: redPalette[3],
  Current: '#66ff87',
  DoubtfulDebt: yellowPalette[4],
  PromiseToPay: darkBlueGrayPalette[3],
});

const DIRECT_DEBIT_ACCOUNTS_TAB_ID = 'direct-debit-accounts';
const DIRECTORS_TAB_ID = 'directors';
const DOCUMENTS_TAB_ID = 'documents';
const ENTITY_INFO_TAB_ID = 'entity-info';
const FINANCIAL_INFO_TAB_ID = 'financial-info';
const LOANS_TAB_ID = 'loans';
const LOGS_TAB_ID = 'logs';
const USER_INFO_TAB_ID = 'user-info';

const ENTITY_PAGE_TABS_CONFIG: EntityPageTabsConfig[] = [
  {
    id: ENTITY_INFO_TAB_ID,
    title: sentenceCase(ENTITY_INFO_TAB_ID),
  },
  {
    id: LOANS_TAB_ID,
    title: sentenceCase(LOANS_TAB_ID),
  },
  {
    id: FINANCIAL_INFO_TAB_ID,
    title: sentenceCase(FINANCIAL_INFO_TAB_ID),
  },
  {
    id: USER_INFO_TAB_ID,
    title: sentenceCase(USER_INFO_TAB_ID),
  },
  {
    id: DIRECT_DEBIT_ACCOUNTS_TAB_ID,
    title: sentenceCase(DIRECT_DEBIT_ACCOUNTS_TAB_ID),
  },
  {
    id: DIRECTORS_TAB_ID,
    title: sentenceCase(DIRECTORS_TAB_ID),
  },
  {
    id: DOCUMENTS_TAB_ID,
    title: sentenceCase(DOCUMENTS_TAB_ID),
  },
  {
    id: LOGS_TAB_ID,
    title: sentenceCase(LOGS_TAB_ID),
  },
];

const ENTITY_PAGE_CONSTANTS = Object.freeze({
  tabs: {
    config: ENTITY_PAGE_TABS_CONFIG,
    ids: {
      directDebitAccounts: DIRECT_DEBIT_ACCOUNTS_TAB_ID,
      directors: DIRECTORS_TAB_ID,
      documents: DOCUMENTS_TAB_ID,
      entityInfo: ENTITY_INFO_TAB_ID,
      financialInfo: FINANCIAL_INFO_TAB_ID,
      loans: LOANS_TAB_ID,
      logs: LOGS_TAB_ID,
      userInfo: USER_INFO_TAB_ID,
    },
  },
});

const PARTNER_NO_REFERRAL = 'No referral';

export {
  ENTITY_ACCOUNT_STATUS_TO_BACKGROUND_COLOR_MAP,
  ENTITY_ACCOUNT_STATUS_TO_BORDER_COLOR_MAP,
  ENTITY_PAGE_CONSTANTS,
  PARTNER_NO_REFERRAL,
};
