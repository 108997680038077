import { FC, useEffect, useState } from 'react';

import { dayjs, kebabCase, sentenceCase, useAuthentication } from 'common';
import {
  EntityAccountStatus,
  EntityStatus,
  StaffMemberRole,
  useAssignEntityToStaffMemberMutation,
  useCreateNoteForEntityMutation,
  useRetrieveEntityForEntityPageLazyQuery,
  useRetrievePartnersLazyQuery,
  useRetrieveStaffMembersLazyQuery,
  useUpdateEntityAccountStatusMutation,
  useUpdateEntityAddressMutation,
  useUpdateEntityMutation,
  useUpdateEntityPartnerMutation,
  useUpdateEntitySocialMediaUrlsMutation,
  useUpdateEntityStatusMutation,
} from 'graphql-library';
import Head from 'next/head';
import { useRouter } from 'next/router';
import {
  Anchor,
  AssignStatusForm,
  AssignToStaffMemberForm,
  BaseContainer,
  BizPayAdminPortalDynamicNextJsTitle,
  BizPayConfirmationModal,
  BizPayLoader,
  Breadcrumbs,
  CreateNoteForm,
  DefaultPageLayout,
  ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP,
  ENTITY_STATUS_TO_BORDER_COLOR_MAP,
  FullHeightContainer,
  Group,
  PageHeading,
  Stack,
  Tabs,
  TabsValue,
  Text,
  useBizPayNotification,
} from 'ui';

import { EntityAuditLogsTable } from '../EntityAuditLogsTable';
import { EntityDirectDebitAccountsTable } from '../EntityDirectDebitAccountsTable';
import { EntityDirectorsTab } from '../EntityDirectorsTab';
import { EntityDocumentsTable } from '../EntityDocumentsTable';
import { EntityFinancialInfoTab } from '../EntityFinancialInfoTab';
import { EntityInfoForm } from '../EntityInfoForm';
import { EntityNotes } from '../EntityNotes';
import { EntityUserInfoForm } from '../EntityUserInfoForm';
import { LoansTable } from '../LoansTable';
import { ReferredByPartnerForm } from '../ReferredByPartnerForm';

import { ENTITY_ACCOUNT_STATUS_TO_BACKGROUND_COLOR_MAP, ENTITY_PAGE_CONSTANTS, PARTNER_NO_REFERRAL } from './EntityPage.constants';
import { EntityForEntityPage, EntityPageProps, PartnerForEntityPage, StaffMemberForEntityPage } from './EntityPage.types';

import { ProtectedRoute, useNavigationRoutes, useSignOut } from '../../hooks';

const { tabs } = ENTITY_PAGE_CONSTANTS;

const EntityPage: FC<EntityPageProps> = ({ id }) => {
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { findPageByRoute } = useNavigationRoutes();
  const { getIsAuthenticated, getLoggedInUserData, getStaffMemberDisplayName } = useAuthentication();
  const {
    push,
    query: { tab },
    route,
  } = useRouter();
  const { signOut } = useSignOut();

  const [accountStatus, setAccountStatus] = useState<string>(EntityAccountStatus.Current);
  const [confirmationStatusModalText, setConfirmStatusModalText] = useState<string>();
  const [entity, setEntity] = useState<EntityForEntityPage>();
  const [isConfirmationAccountStatusModalOpened, setIsConfirmAccountStatusModalOpened] = useState<boolean>(false);
  const [isConfirmationStatusModalOpened, setIsConfirmStatusModalOpened] = useState<boolean>(false);
  const [nextAccountStatus, setAccountNextStatus] = useState<string>();
  const [nextStatus, setNextStatus] = useState<string>();
  const [partners, setPartners] = useState<PartnerForEntityPage[]>([]);
  const [referredByPartnerId, setReferredByPartnerId] = useState<EntityForEntityPage['partnerId']>();
  const [salesStaffMembers, setSalesStaffMembers] = useState<StaffMemberForEntityPage[]>([]);
  const [shouldRefetchAuditLogs, setShouldRefetchAuditLogs] = useState<boolean>(false);
  const [shouldRefetchNotes, setShouldRefetchNotes] = useState<boolean>(false);
  const [staffMemberIdAssignedToEntity, setStaffMemberIdAssignedToEntity] = useState<EntityForEntityPage['assignedToStaffMemberId']>();
  const [status, setStatus] = useState<string>();
  const [tabIdValue, setTabIdValue] = useState<TabsValue>(tabs.config[0].id);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const staffMemberRoles = getLoggedInUserData()?.roles ?? [];

  const [executeAssignEntityToStaffMemberMutation, { loading: isAssignEntityToStaffMemberLoading }] = useAssignEntityToStaffMemberMutation({
    onCompleted: ({ assignEntityToStaffMember: { assignedToStaffMemberId } }) => {
      setStaffMemberIdAssignedToEntity(assignedToStaffMemberId);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to assign staff member to entity',
      });
    },
  });

  const [executeCreateNoteForEntityMutation, { loading: isCreateNoteForEntityLoading }] = useCreateNoteForEntityMutation({
    onCompleted: () => {
      setShouldRefetchNotes(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to create a note for this loan application',
      });
    },
  });

  const [executeRetrieveEntityQuery, { loading: isRetrieveEntityLoading }] = useRetrieveEntityForEntityPageLazyQuery({
    onCompleted: ({ retrieveEntity: returnedEntity }) => {
      const { accountStatus, assignedToStaffMemberId, partnerId, status } = returnedEntity;

      setAccountStatus(accountStatus);
      setEntity(returnedEntity);
      setReferredByPartnerId(partnerId);
      setStaffMemberIdAssignedToEntity(assignedToStaffMemberId);
      setStatus(status);

      executeRetrieveStaffMembersLazyQuery();
      executeRetrievePartnersLazyQuery();
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve entity',
      });
    },
  });

  const [executeRetrievePartnersLazyQuery, { loading: isRetrievePartnersLoading }] = useRetrievePartnersLazyQuery({
    onCompleted: ({ retrievePartners: returnedPartners }) => {
      setPartners(returnedPartners);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve partners',
      });
    },
  });

  const [executeRetrieveStaffMembersLazyQuery, { loading: isRetrieveStaffMembersLoading }] = useRetrieveStaffMembersLazyQuery({
    onCompleted: ({ retrieveStaffMembers: returnedStaffMembers }) => {
      setSalesStaffMembers(
        returnedStaffMembers.filter(({ roles }) => roles.includes(StaffMemberRole.Sales) || roles.includes(StaffMemberRole.SalesAdmin)),
      );
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve staff members',
      });
    },
  });

  const [executeUpdateEntityAddressMutation] = useUpdateEntityAddressMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Successfully updated the entity address',
      });

      setShouldRefetchAuditLogs(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the entity address',
      });
    },
  });

  const [executeUpdateEntityPartnerMutation, { loading: isUpdateEntityPartnerLoading }] = useUpdateEntityPartnerMutation({
    onCompleted: ({ updateEntityPartner: { partnerId } }) => {
      setReferredByPartnerId(partnerId);
      setShouldRefetchAuditLogs(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update referred partner to entity',
      });
    },
  });

  const [executeUpdateEntitySocialMediaUrlsMutation] = useUpdateEntitySocialMediaUrlsMutation({
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Successfully updated the social media urls',
      });

      setShouldRefetchAuditLogs(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the social media urls',
      });
    },
  });

  const [executeUpdateEntityStatusMutation, { loading: isUpdateEntityStatusLoading }] = useUpdateEntityStatusMutation({
    onCompleted: ({ updateEntityStatus: { status: entityStatus } }) => {
      displaySuccessNotification({
        message: `Successfully updated the status to ${entityStatus}`,
      });

      setIsConfirmStatusModalOpened(false);
      setStatus(entityStatus);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the status',
      });
    },
  });

  const [executeUpdateEntityMutation] = useUpdateEntityMutation({
    onCompleted: ({ updateEntityDetails: updatedEntity }) => {
      setEntity((previousEntity) => {
        if (!previousEntity) {
          return;
        }

        return {
          ...previousEntity,
          ...updatedEntity,
        };
      });

      displaySuccessNotification({
        message: 'Successfully updated the entity',
      });

      setShouldRefetchAuditLogs(true);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the entity',
      });
    },
  });

  const [executeUpdateEntityAccountStatusMutation, { loading: isUpdateEntityAccountStatusLoading }] = useUpdateEntityAccountStatusMutation({
    onCompleted: ({ updateEntityAccountStatus: { accountStatus } }) => {
      displaySuccessNotification({
        message: `Successfully updated the account status to ${accountStatus}`,
      });

      setAccountStatus(accountStatus);
      setIsConfirmAccountStatusModalOpened(false);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to update the account status',
      });
    },
  });

  const getAssignedTo = (staffMemberId?: string) => {
    if (!staffMemberId) {
      return undefined;
    }

    const assignedToStaffMember = salesStaffMembers.find(({ id }) => id === staffMemberId);

    if (!assignedToStaffMember) {
      return undefined;
    }

    return getStaffMemberDisplayName(assignedToStaffMember.fullName, assignedToStaffMember.id);
  };

  const handleAccountStatusChange = (selectedStatus: string) => {
    setAccountNextStatus(selectedStatus);
    setConfirmStatusModalText(
      `Are you sure you want to change the account status from ${sentenceCase(accountStatus)} to ${sentenceCase(selectedStatus)}?`,
    );
    setIsConfirmAccountStatusModalOpened(true);
  };

  const handleBreadcrumbItemClick = (route: string) => {
    push(route);
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmAccountStatusModalOpened(false);
    setIsConfirmStatusModalOpened(false);
  };

  const handleRefetchAuditLogsCompleted = () => {
    setShouldRefetchAuditLogs(false);
  };

  const handleRefetchNotesCompleted = () => {
    setShouldRefetchNotes(false);
  };

  const handleStatusChange = (selectedStatus: string) => {
    setConfirmStatusModalText(`Are you sure you want to change the status from ${status} to ${selectedStatus}?`);
    setIsConfirmStatusModalOpened(true);
    setNextStatus(selectedStatus);
  };

  const handleTabChange = (value: TabsValue) => {
    setTabIdValue(value);
    setTabIndex(tabs.config.findIndex(({ id }) => id === value));

    push(`${route.replace('[id]', id)}?tab=${value}`, undefined, {
      shallow: true,
    });
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!tab) {
      handleTabChange(tabs.config[0].id);
      return;
    }

    handleTabChange(String(tab));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveEntityQuery({
      variables: {
        id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const { title } = findPageByRoute(route);

  const isLoading =
    isAssignEntityToStaffMemberLoading ||
    isCreateNoteForEntityLoading ||
    isRetrieveEntityLoading ||
    isRetrievePartnersLoading ||
    isRetrieveStaffMembersLoading ||
    isUpdateEntityPartnerLoading;

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={`${title} - Entity id: ${id}`} />

      <BizPayConfirmationModal
        closeOnClickOutside={!isUpdateEntityStatusLoading}
        closeOnEscape={!isUpdateEntityStatusLoading}
        opened={isConfirmationStatusModalOpened}
        title="Change status"
        onClose={handleConfirmationModalClose}
        onConfirm={() => {
          if (!isAuthenticated) {
            signOut();
            return;
          }

          if (!nextStatus) {
            return;
          }

          executeUpdateEntityStatusMutation({
            variables: {
              entityId: id,
              status: nextStatus,
            },
          });
        }}
      >
        {confirmationStatusModalText}
      </BizPayConfirmationModal>

      <BizPayConfirmationModal
        closeOnClickOutside={!isUpdateEntityAccountStatusLoading}
        closeOnEscape={!isUpdateEntityAccountStatusLoading}
        opened={isConfirmationAccountStatusModalOpened}
        title="Change account status"
        onClose={handleConfirmationModalClose}
        onConfirm={() => {
          if (!isAuthenticated) {
            signOut();
            return;
          }

          if (!nextAccountStatus) {
            return;
          }

          executeUpdateEntityAccountStatusMutation({
            variables: {
              accountStatus: nextAccountStatus,
              entityId: id,
            },
          });
        }}
      >
        {confirmationStatusModalText}
      </BizPayConfirmationModal>

      {isLoading ? (
        <Stack align="center" h="100%" justify="center">
          {isAssignEntityToStaffMemberLoading && <BizPayLoader message="Assigning staff member to entity, please wait..." />}

          {isCreateNoteForEntityLoading && <BizPayLoader message="Creating a note for this entity, please wait..." />}

          {isRetrieveEntityLoading && <BizPayLoader message="Retrieving entity, please wait..." />}

          {isRetrievePartnersLoading && <BizPayLoader message="Retrieving partners, please wait..." />}

          {isRetrieveStaffMembersLoading && <BizPayLoader message="Retrieving staff members, please wait..." />}

          {isUpdateEntityPartnerLoading && <BizPayLoader message="Updating a partner to entity, please wait..." />}
        </Stack>
      ) : (
        <>
          {entity && (
            <DefaultPageLayout
              mainComponent={
                <FullHeightContainer>
                  <Group align="flex-start" w="100%" noWrap>
                    <Stack h="100%" w="100%">
                      <Tabs
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          height: '100%',
                        }}
                        value={tabIdValue}
                        onTabChange={handleTabChange}
                      >
                        <Tabs.List>
                          {tabs.config.map(({ id, title }) => {
                            return (
                              <Tabs.Tab key={`tab-${id}`} value={id}>
                                {title}
                              </Tabs.Tab>
                            );
                          })}
                        </Tabs.List>

                        <Tabs.Panel
                          pt={30}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                          }}
                          value={tabs.config[tabIndex].id}
                        >
                          {tabs.config[tabIndex].id === tabs.ids.directDebitAccounts && (
                            <FullHeightContainer>
                              <EntityDirectDebitAccountsTable
                                entityId={id}
                                paginationOptions={{
                                  isPaginationEnabled: true,
                                }}
                              />
                            </FullHeightContainer>
                          )}

                          {tabs.config[tabIndex].id === tabs.ids.directors && <EntityDirectorsTab entityId={id} />}

                          {tabs.config[tabIndex].id === tabs.ids.documents && (
                            <EntityDocumentsTable
                              entityId={id}
                              paginationOptions={{
                                isPaginationEnabled: true,
                              }}
                            />
                          )}

                          {tabs.config[tabIndex].id === tabs.ids.entityInfo && (
                            <FullHeightContainer>
                              <EntityInfoForm
                                initialDefaultValues={{
                                  abn: entity.abn ?? '-',
                                  abrRegisteredState: entity.abrRegisteredState ?? '',
                                  acn: entity.acn ?? '-',
                                  city: entity.registeredBusinessAddress?.city ?? '',
                                  country: entity.registeredBusinessAddress?.country ?? '',
                                  equifaxBureauScore: entity.equifaxBureauScore ? String(entity.equifaxBureauScore) : '-',
                                  facebookUrl: entity.facebookUrl ?? '',
                                  id: entity.id,
                                  industry: entity.industry ?? '',
                                  name: entity.name,
                                  instagramUrl: entity.instagramUrl ?? '',
                                  linkedInUrl: entity.linkedInUrl ?? '',
                                  onboardingDate: entity.createdAtUtc ? dayjs(entity.createdAtUtc).toDate().toLocaleString() : '-',
                                  postcode: entity.registeredBusinessAddress?.postcode ?? '',
                                  status: entity.status,
                                  street: entity.registeredBusinessAddress?.street ?? '',
                                  state: entity.registeredBusinessAddress?.state ?? '',
                                  suburb: entity.registeredBusinessAddress?.suburb ?? '',
                                  twitterUrl: entity.twitterUrl ?? '',
                                  type: entity.type?.name ?? '-',
                                  websiteUrl: entity.websiteUrl ?? '',
                                }}
                                onSubmit={({
                                  abrRegisteredState,
                                  city,
                                  country,
                                  facebookUrl,
                                  hasAddressFieldsChanged,
                                  hasEntityDetailsChanged,
                                  hasSocialMediaFieldsChanged,
                                  industry,
                                  instagramUrl,
                                  linkedInUrl,
                                  postcode,
                                  state,
                                  street,
                                  suburb,
                                  twitterUrl,
                                  websiteUrl,
                                }) => {
                                  if (!getIsAuthenticated()) {
                                    signOut();
                                    return;
                                  }

                                  if (hasAddressFieldsChanged) {
                                    executeUpdateEntityAddressMutation({
                                      variables: {
                                        city,
                                        country,
                                        entityId: id,
                                        postcode,
                                        state,
                                        street,
                                        suburb,
                                      },
                                    });
                                  }

                                  if (hasSocialMediaFieldsChanged) {
                                    executeUpdateEntitySocialMediaUrlsMutation({
                                      variables: {
                                        entityId: id,
                                        facebookUrl,
                                        instagramUrl,
                                        linkedInUrl,
                                        twitterUrl,
                                      },
                                    });
                                  }

                                  if (hasEntityDetailsChanged) {
                                    executeUpdateEntityMutation({
                                      variables: {
                                        abrRegisteredState,
                                        id,
                                        industry,
                                        websiteUrl,
                                      },
                                    });
                                  }
                                }}
                              />
                            </FullHeightContainer>
                          )}

                          {tabs.config[tabIndex].id === tabs.ids.financialInfo && (
                            <FullHeightContainer>
                              <EntityFinancialInfoTab entityId={entity.id} inceptFacilityLimitInCents={entity.inceptFacilityLimitInCents} />
                            </FullHeightContainer>
                          )}

                          {tabs.config[tabIndex].id === tabs.ids.loans && (
                            <FullHeightContainer>
                              <LoansTable
                                entityId={id}
                                paginationOptions={{
                                  isPaginationEnabled: true,
                                }}
                              />
                            </FullHeightContainer>
                          )}

                          {tabs.config[tabIndex].id === tabs.ids.logs && (
                            <FullHeightContainer>
                              <EntityAuditLogsTable
                                entityId={id}
                                paginationOptions={{
                                  isPaginationEnabled: true,
                                }}
                                refetchDataOptions={{
                                  onRefetchDataCompleted: handleRefetchAuditLogsCompleted,
                                  shouldRefetchData: shouldRefetchAuditLogs,
                                }}
                              />
                            </FullHeightContainer>
                          )}

                          {tabs.config[tabIndex].id === tabs.ids.userInfo && (
                            <FullHeightContainer>
                              <EntityUserInfoForm entityId={entity.id} />
                            </FullHeightContainer>
                          )}
                        </Tabs.Panel>
                      </Tabs>
                    </Stack>

                    <Stack ml="xs" w={450}>
                      <BaseContainer>
                        <AssignStatusForm
                          backgroundColor={status ? ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP[status as EntityStatus] : undefined}
                          dropdownListOptions={Object.keys(EntityStatus).map((entityStatus) => ({
                            label: sentenceCase(entityStatus),
                            disabled: entityStatus === status,
                            value: entityStatus,
                          }))}
                          label="Status"
                          selectedStatus={status?.toString()}
                          onChange={handleStatusChange}
                        />

                        <Stack mt="xs">
                          <AssignStatusForm
                            backgroundColor={
                              status ? ENTITY_ACCOUNT_STATUS_TO_BACKGROUND_COLOR_MAP[accountStatus as EntityAccountStatus] : undefined
                            }
                            dropdownListOptions={Object.keys(EntityAccountStatus).map((entityAccountStatus) => ({
                              label: sentenceCase(entityAccountStatus),
                              disabled: entityAccountStatus === accountStatus,
                              value: entityAccountStatus,
                            }))}
                            isReadOnly={
                              !(
                                staffMemberRoles.includes(StaffMemberRole.SuperAdmin) ||
                                staffMemberRoles.includes(StaffMemberRole.CSuite) ||
                                staffMemberRoles.includes(StaffMemberRole.CreditAdmin)
                              )
                            }
                            label="Account status"
                            selectedStatus={accountStatus?.toString()}
                            onChange={handleAccountStatusChange}
                          />
                        </Stack>

                        <Stack mt="xs">
                          <AssignToStaffMemberForm
                            dropdownListOptions={salesStaffMembers.map(({ id }) => ({
                              label: getAssignedTo(id),
                              value: id,
                            }))}
                            label="Sales lead:"
                            selectedAssignedToStaffMemberId={staffMemberIdAssignedToEntity}
                            onChange={(selectedAssignedToStaffMemberId) => {
                              if (!getIsAuthenticated()) {
                                signOut();
                                return;
                              }

                              executeAssignEntityToStaffMemberMutation({
                                variables: {
                                  assignedToStaffMemberId: selectedAssignedToStaffMemberId,
                                  id: entity.id,
                                },
                              });
                            }}
                          />
                        </Stack>

                        <Stack mt="xs">
                          <ReferredByPartnerForm
                            dropdownListOptions={partners.map(({ id, name }) => ({
                              label: sentenceCase(name),
                              value: id,
                            }))}
                            isReadOnly={!!referredByPartnerId || !staffMemberRoles.includes(StaffMemberRole.SuperAdmin)}
                            selectedPartnerId={referredByPartnerId}
                            onChange={(partnerId) => {
                              if (!getIsAuthenticated()) {
                                signOut();
                                return;
                              }

                              const selectedPartnerId = partnerId === PARTNER_NO_REFERRAL ? null : partnerId;

                              executeUpdateEntityPartnerMutation({
                                variables: {
                                  id: entity.id,
                                  partnerId: selectedPartnerId,
                                },
                              });
                            }}
                          />
                        </Stack>
                      </BaseContainer>

                      <FullHeightContainer mt="md">
                        <Text mb="md" weight="bold">
                          Notes
                        </Text>

                        <CreateNoteForm
                          onSubmit={({ message }) => {
                            if (!getIsAuthenticated()) {
                              signOut();
                              return;
                            }

                            executeCreateNoteForEntityMutation({
                              variables: {
                                entityId: id,
                                message,
                              },
                            });
                          }}
                        />

                        <EntityNotes
                          entityId={id}
                          refetchDataOptions={{
                            onRefetchDataCompleted: handleRefetchNotesCompleted,
                            shouldRefetchData: shouldRefetchNotes,
                          }}
                        />
                      </FullHeightContainer>
                    </Stack>
                  </Group>
                </FullHeightContainer>
              }
              pageHeadingComponent={
                <Group align="center" position="center" w="100%">
                  <Stack justify="center" w="100%">
                    <PageHeading
                      flexContainerProps={{
                        mb: 0,
                      }}
                      heading={title}
                      size="h4"
                    />

                    <Group
                      align="center"
                      mb="xs"
                      mt="xs"
                      p="xs"
                      sx={({ colors }) => ({
                        backgroundColor: ENTITY_STATUS_TO_BACKGROUND_COLOR_MAP[status as EntityStatus],
                        border: `0.075rem solid ${ENTITY_STATUS_TO_BORDER_COLOR_MAP[status as EntityStatus]}`,
                        borderRadius: '0.5rem',
                        boxShadow: `0.05rem 0.05rem ${colors['bizpay-light-gray'][3]}`,
                      })}
                    >
                      <Breadcrumbs separator=">">
                        {[
                          {
                            label: 'Entities',
                            route: ProtectedRoute.Entities,
                          },
                          {
                            label: (
                              <Group noWrap>
                                <Text weight="bold">ID:</Text>
                                <Text> {entity.niceId}</Text>
                                <Text ml={5} mr={5}>
                                  -
                                </Text>
                                <Text weight="bold">Name:</Text>
                                <Text> {entity.name}</Text>
                              </Group>
                            ),
                          },
                        ].map(({ label, route: breadcrumbRoute }) => {
                          const key = kebabCase(breadcrumbRoute);

                          return breadcrumbRoute ? (
                            <Anchor key={key} weight="bold" onClick={() => handleBreadcrumbItemClick(breadcrumbRoute)}>
                              {label}
                            </Anchor>
                          ) : (
                            <Text key={key}>{label}</Text>
                          );
                        })}
                      </Breadcrumbs>
                    </Group>
                  </Stack>
                </Group>
              }
            />
          )}
        </>
      )}
    </>
  );
};

export { EntityPage };
