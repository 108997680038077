import { UseTableFilter } from './useTableFilter.types';

import { FilterInput } from '../../types';

const useTableFilter = (): UseTableFilter => {
  const generateFilterInput: UseTableFilter['generateFilterInput'] = (fields, filterString) => {
    return filterString
      ? fields.map(
          (field): FilterInput => ({
            field: field as string,
            value: filterString,
          }),
        )
      : undefined;
  };

  return {
    generateFilterInput,
  };
};

export { useTableFilter };
