import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedLoanApplicationsForReviewQuery: DocumentNode = gql`
  query retrievePaginatedLoanApplicationsForReview($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedLoanApplicationsByAdminStatus(
      adminStatuses: [AwaitingInfoFromClient, ReadyForReview, UnderReview]
      pagination: $pagination
      sort: $sort
    ) {
      count
      data {
        adminStatus
        assignedToStaffMember {
          fullName
          id
        }
        createdAtUtc
        entity {
          name
          id
        }
        id
        invoice {
          id
          supplier {
            id
            name
          }
          totalAmountInCents
        }
        niceId
        product {
          displayName
          id
          name
        }
        submittedAtUtc
      }
    }
  }
`;

export { retrievePaginatedLoanApplicationsForReviewQuery };
