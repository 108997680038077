import { FC } from 'react';

import { Grid, TextInput, useForm } from 'ui';

import {
  LoanApplicationDirectDebitAccountFormData,
  LoanApplicationDirectDebitAccountFormProps,
} from './LoanApplicationDirectDebitAccountForm.types';

const LoanApplicationDirectDebitAccountForm: FC<LoanApplicationDirectDebitAccountFormProps<LoanApplicationDirectDebitAccountFormData>> = ({
  initialDefaultValues,
}) => {
  const { register } = useForm<LoanApplicationDirectDebitAccountFormData>({
    defaultValues: initialDefaultValues,
  });

  return (
    <form id="loan-application-direct-debit-account-form">
      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('bsb')} label="BSB" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('financialInstitutionName')} label="Financial institution name" size="md" readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('accountNumber')} label="Account number" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto">
          <TextInput {...register('status')} label="Status" size="md" readOnly />
        </Grid.Col>
      </Grid>

      <Grid gutter="sm">
        <Grid.Col span="auto">
          <TextInput {...register('accountName')} label="Account name" size="md" readOnly />
        </Grid.Col>

        <Grid.Col span="auto"></Grid.Col>
      </Grid>
    </form>
  );
};

export { LoanApplicationDirectDebitAccountForm };
