import { FC } from 'react';

import { Text } from '@mantine/core';

import { UnauthorizedAccessMessageProps } from './UnauthorizedAccessMessage.types';

const UnauthorizedAccessMessage: FC<UnauthorizedAccessMessageProps> = ({
  message = 'You do not have the authorization to view this page.',
}) => {
  return <Text>{message}</Text>;
};

export { UnauthorizedAccessMessage };
