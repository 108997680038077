import { FC, useEffect } from 'react';

import { ActionIcon, Group, TextInput } from '@mantine/core';
import { IconSearch, IconX } from '@tabler/icons-react';
import { useDebouncedCallback } from 'common';
import { useForm } from 'react-hook-form';

import { TableFilterFormData, TableFilterFormProps } from './TableFilterForm.types';

const TableFilterForm: FC<TableFilterFormProps> = ({
  inputFieldWidthInRem = 20,
  onFilterInputChange,
  onFilterInputClear,
  placeholderText,
}) => {
  const { register, reset, setFocus, setValue, watch } = useForm<TableFilterFormData>({
    mode: 'onChange',
  });

  const handleClearButtonClick = () => {
    onFilterInputClear();
    reset();
    setFocus('filterString');
  };

  const handleFilterInputChange = (filterString: string) => {
    if (filterString) {
      onFilterInputChange(filterString);
      return;
    }

    handleClearButtonClick();
  };

  useEffect(() => {
    setFocus('filterString');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedFilterInputChange = useDebouncedCallback(handleFilterInputChange, 1000);

  return (
    <form
      id="table-filter-form"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <Group position="right" w={`${inputFieldWidthInRem}rem`} noWrap>
        <TextInput
          {...register('filterString')}
          icon={<IconSearch size={18} />}
          placeholder={placeholderText}
          rightSection={
            watch('filterString') ? (
              <ActionIcon onClick={handleClearButtonClick}>
                <IconX size={14} />
              </ActionIcon>
            ) : null
          }
          size="md"
          w="100%"
          onChange={({ target: { value } }) => {
            const trimmedValue = value.trim();

            setValue('filterString', trimmedValue);
            debouncedFilterInputChange(trimmedValue);
          }}
        />
      </Group>
    </form>
  );
};

export { TableFilterForm };
