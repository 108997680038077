import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedAuditLogsForLoanQuery: DocumentNode = gql`
  query retrievePaginatedAuditLogsForLoan($loanId: String!, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedAuditLogs(loanId: $loanId, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        description
        id
        staffMember {
          fullName
          id
        }
      }
    }
  }
`;

export { retrievePaginatedAuditLogsForLoanQuery };
