import { FC } from 'react';

import { Stack } from '@mantine/core';

import { FullPageLayoutProps } from './FullPageLayout.types';

const FullPageLayout: FC<FullPageLayoutProps> = ({ mainComponent, pageHeadingComponent }) => {
  return (
    <Stack h="100%" id="full-page-layout-component-container" w="100%">
      <Stack align="center" id="full-page-layout-page-heading-component-container" w="100%">
        {pageHeadingComponent}
      </Stack>

      <Stack h="100%" id="full-page-layout-main-component-container" w="100%">
        {mainComponent}
      </Stack>
    </Stack>
  );
};

export { FullPageLayout };
