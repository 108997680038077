import { FC } from 'react';

import { ActionIcon, Flex } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';

import { BizPayTooltip } from '../BizPayTooltip';

import { RefreshDataIconButtonProps } from './RefreshDataIconButton.types';

const RefreshDataIconButton: FC<RefreshDataIconButtonProps> = ({
  isButtonDisabled = false,
  onClick,
  tooltipText = 'Click to refresh data',
}) => {
  const refreshIcon = <IconRefresh size={20} />;

  return (
    <BizPayTooltip label={tooltipText} offset={isButtonDisabled ? undefined : 2}>
      {isButtonDisabled ? (
        <Flex p={4}>{refreshIcon}</Flex>
      ) : (
        <ActionIcon color="dark" variant="transparent" onClick={onClick}>
          {refreshIcon}
        </ActionIcon>
      )}
    </BizPayTooltip>
  );
};

export { RefreshDataIconButton };
