import { createBizPayEmotionCache } from './useBizPayEmotionCache.helpers';
import { UseBizPayEmotionCache } from './useBizPayEmotionCache.types';

const useBizPayEmotionCache = (): UseBizPayEmotionCache => {
  return {
    emotionCache: createBizPayEmotionCache(),
  };
};

export { useBizPayEmotionCache };
