import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedLoansQuery: DocumentNode = gql`
  query retrievePaginatedLoans($entityId: String, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedLoans(entityId: $entityId, pagination: $pagination, sort: $sort) {
      count
      data {
        balanceInCents
        createdAtUtc
        entity {
          id
          name
        }
        id
        invoice {
          id
          supplier {
            id
            name
          }
        }
        niceId
        totalAmountInCents
        status
      }
    }
  }
`;

export { retrievePaginatedLoansQuery };
