import { FC, useEffect, useState } from 'react';

import { LoanApplicationAdminStatus, useRetrieveLatestDisbursementsFileLazyQuery } from 'graphql-library';
import { useRouter } from 'next/router';
import { Group, BizPayOutlinedButton, BizPayPrimaryButton, useBizPayNotification, BizPayLoader, Stack } from 'ui';

import { ConfirmDownloadDisbursementsFileModal, CreatedDisbursementsFile } from '../ConfirmDownloadDisbursementsFileModal';
import { CreateNewLoansModal } from '../CreateNewLoansModal';
import { LoanApplicationsForDisbursementTable } from '../LoanApplicationsForDisbursementTable';

import { isDateTodaysDate } from './LoanApplicationsForDisbursementTab.helpers';

import { ProtectedRoute } from '../../hooks';

const LoanApplicationsForDisbursementTab: FC = () => {
  const canGenerateMultipleDisbursementFilesPerDay =
    process.env.NEXT_PUBLIC_CAN_GENERATE_MULTIPLE_DISBURSEMENTS_FILES_PER_DAY?.toLowerCase() === 'true';

  const { displayErrorNotification } = useBizPayNotification();
  const { push } = useRouter();

  const [disbursementsFileSignedUrl, setDisbursementsFileSignedUrl] = useState<string>();
  const [hasGeneratedDisbursementsFileToday, setHasGeneratedDisbursementsFileToday] = useState<boolean>(false);
  const [isConfirmDownloadDisbursementsFileModalOpen, setIsConfirmDownloadDisbursementsFileModalOpen] = useState<boolean>(false);
  const [isCreateNewLoansModalOpen, setIsCreateNewLoansModalOpen] = useState<boolean>(false);
  const [shouldRefetchLoanApplicationsForDisbursements, setShouldRefetchLoanApplicationsForDisbursements] = useState<boolean>(false);
  const [totalLoanApplications, setTotalLoanApplications] = useState<number>(0);

  const [executeRetrieveLatestDisbursementsFileQuery, { loading: isRetrieveLatestDisbursementsFileLoading }] =
    useRetrieveLatestDisbursementsFileLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrieveLatestDisbursementsFile: latestDisbursementsFile }) => {
        if (!latestDisbursementsFile) {
          setHasGeneratedDisbursementsFileToday(false);
          return;
        }

        setHasGeneratedDisbursementsFileToday(isDateTodaysDate(latestDisbursementsFile.createdAtUtc));
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve the latest disbursements file',
        });
      },
    });

  const handleConfirmDownloadDisbursementsFileCompleted = ({ createdAtUtc, signedUrl }: CreatedDisbursementsFile) => {
    setDisbursementsFileSignedUrl(signedUrl);

    if (!canGenerateMultipleDisbursementFilesPerDay) {
      setHasGeneratedDisbursementsFileToday(isDateTodaysDate(createdAtUtc));
    }

    setShouldRefetchLoanApplicationsForDisbursements(true);
  };

  const handleConfirmDownloadDisbursementsFileModalClose = () => {
    setIsConfirmDownloadDisbursementsFileModalOpen(false);
  };

  const handleConfirmDownloadDisbursementsFileModalOpen = () => {
    setIsConfirmDownloadDisbursementsFileModalOpen(true);
  };

  const handleCreateNewLoansCompleted = () => {
    push(ProtectedRoute.Loans);
  };

  const handleCreateNewLoansModalClose = () => {
    setIsCreateNewLoansModalOpen(false);
  };

  const handleCreateNewLoansModalOpen = () => {
    setIsCreateNewLoansModalOpen(true);
  };

  const handleLoadTableDataCompleted = (count: number) => {
    setTotalLoanApplications(count);
  };

  const handleRefetchLoanApplicationsForDisbursementsCompleted = () => {
    setShouldRefetchLoanApplicationsForDisbursements(false);
  };

  useEffect(() => {
    if (canGenerateMultipleDisbursementFilesPerDay || !totalLoanApplications) {
      return;
    }

    executeRetrieveLatestDisbursementsFileQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalLoanApplications]);

  useEffect(() => {
    if (!disbursementsFileSignedUrl) {
      return;
    }

    window.open(disbursementsFileSignedUrl, '_blank');

    setDisbursementsFileSignedUrl(undefined);
  }, [disbursementsFileSignedUrl]);

  const isLoading = isRetrieveLatestDisbursementsFileLoading;

  return (
    <>
      {isLoading ? (
        <Stack align="center" h="100%" justify="center">
          {isRetrieveLatestDisbursementsFileLoading && <BizPayLoader message="Retrieving the latest disbursements file, please wait..." />}
        </Stack>
      ) : (
        <>
          {!!totalLoanApplications && (
            <>
              <ConfirmDownloadDisbursementsFileModal
                isOpen={isConfirmDownloadDisbursementsFileModalOpen}
                onClose={handleConfirmDownloadDisbursementsFileModalClose}
                onCompleted={handleConfirmDownloadDisbursementsFileCompleted}
              />

              <CreateNewLoansModal
                isOpen={isCreateNewLoansModalOpen}
                onClose={handleCreateNewLoansModalClose}
                onCompleted={handleCreateNewLoansCompleted}
              />

              <Group mb="md" position="right">
                <BizPayPrimaryButton
                  disabled={hasGeneratedDisbursementsFileToday}
                  mr="xs"
                  onClick={handleConfirmDownloadDisbursementsFileModalOpen}
                >
                  Download disbursements
                </BizPayPrimaryButton>

                <BizPayOutlinedButton
                  disabled={!canGenerateMultipleDisbursementFilesPerDay && !(hasGeneratedDisbursementsFileToday && totalLoanApplications)}
                  onClick={handleCreateNewLoansModalOpen}
                >
                  Create new loans
                </BizPayOutlinedButton>
              </Group>
            </>
          )}

          <LoanApplicationsForDisbursementTable
            adminStatuses={[LoanApplicationAdminStatus.Approved, LoanApplicationAdminStatus.AwaitingDisbursement]}
            paginationOptions={{
              isPaginationEnabled: true,
            }}
            refetchDataOptions={{
              onRefetchDataCompleted: handleRefetchLoanApplicationsForDisbursementsCompleted,
              shouldRefetchData: shouldRefetchLoanApplicationsForDisbursements,
            }}
            onLoadTableDataCompleted={handleLoadTableDataCompleted}
          />
        </>
      )}
    </>
  );
};

export { LoanApplicationsForDisbursementTab };
