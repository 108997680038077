import { ComponentProps, FC } from 'react';

const BizPayLogo: FC<ComponentProps<'svg'>> = (props) => {
  return (
    <svg
      aria-labelledby="bizpay-logo-title bizpay-logo-description"
      id="bizpay-logo"
      role="img"
      viewBox="0 0 428 122.7"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <desc id="bizpay-logo-description">BizPay logo</desc>
      <title id="bizpay-logo-title">BizPay logo</title>
      <path
        d="M61.9,60.1c1.2-1.3,2-3,2-5c0-1.9-0.8-3.6-1.9-4.9L12,1.7C10.7,0.7,9.2,0,7.4,0C3.5,0,0.2,3.1,0,7L0,103 c0.1,4,3.3,7.3,7.4,7.3c1.6,0,3.3-0.5,4.6-1.8L61.9,60.1L61.9,60.1z"
        style={{ fill: '#25D1C3' }}
      ></path>
      <path
        d="M61.9,60.1L61.9,60.1c1.2-1.3,2-3,2-5c0-1.9-0.8-3.6-1.9-4.9L40.2,29L18.4,50.2l0,0c-1.2,1.3-2,3-2,5 c0,1.9,0.8,3.6,1.9,4.9l21.7,21.2L61.9,60.1z"
        style={{ fill: '#2C4752' }}
      ></path>
      <path
        d="M279.6,10.8c19.4,0,35.7,6.1,35.7,27.1S299,65.1,279.6,65.1H261v29.8h-10.1v-84H279.6z M280.1,20.4H261v35.1 h19.1c13.7,0,25-3.4,25-17.5C305,23.7,293.9,20.4,280.1,20.4z"
        style={{ fill: '#2C4752' }}
      ></path>
      <path
        d="M370.1,87.3c1.4,0,3-0.4,4.4-0.8v7.8c-2.3,0.8-4.8,1.3-7.2,1.3c-4.7,0-8.9-1.9-10.6-7 c-5.8,4.8-13.1,7.7-22.4,7.7c-10.2,0-20.3-5.4-20.3-17.5c0-14.9,13.7-19.5,26.1-19.5c5,0,11.2,0.7,16,1.8v-2 c0-8.6-5.9-14.4-16.4-14.4c-8.9,0-16,2.2-21,4.3v-9.4c4.6-1.6,12.5-3.8,22-3.8c15.4,0,25.1,8.4,25.1,24.5v22.6 C365.6,86.3,367.6,87.3,370.1,87.3z M336.3,88c7.3,0,14.6-3,19.7-8.4V69c-4.1-0.8-10-1.7-14.6-1.7c-8.4,0-17.5,2.5-17.5,10.9 C323.9,85,330,88,336.3,88z"
        style={{ fill: '#2C4752' }}
      ></path>
      <path
        d="M417.8,37.2H428l-28.3,66.3c-6,13.9-13.3,19.2-21.7,19.2c-2,0-4.1-0.2-6.2-0.8v-8.3c1.7,0.5,3.4,0.7,5,0.7 c6.5,0,11.2-4.7,14.9-14l2.2-5.3l-27.4-57.8h10.2l21.7,46.7L417.8,37.2z"
        style={{ fill: '#2C4752' }}
      ></path>
      <path
        d="M92.1,95V11.1h29.4c10.2,0,17.9,2,23.2,5.8c5.3,3.9,7.9,9.6,7.9,17.1c0,4.1-1.1,7.7-3.2,10.9 c-2.1,3.1-5.1,5.4-8.8,6.9c4.3,1.1,7.7,3.2,10.2,6.5c2.5,3.3,3.7,7.3,3.7,12c0,8.1-2.6,14.2-7.7,18.3c-5.1,4.1-12.5,6.3-22,6.3H92.1 z M109.4,46.3h12.8c8.7-0.2,13.1-3.6,13.1-10.4c0-3.8-1.1-6.5-3.3-8.2c-2.2-1.7-5.7-2.5-10.5-2.5h-12.1V46.3z M109.4,58.5v22.6h14.8 c4.1,0,7.2-1,9.5-2.9c2.3-1.9,3.4-4.6,3.4-8c0-7.7-4-11.6-11.9-11.7H109.4z"
        style={{ fill: '#2C4752' }}
      ></path>
      <path
        d="M163.3,16.5c0-2.5,0.8-4.6,2.5-6.2s3.9-2.4,6.8-2.4c2.8,0,5.1,0.8,6.8,2.4c1.7,1.6,2.5,3.7,2.5,6.2 c0,2.5-0.9,4.6-2.6,6.2c-1.7,1.6-4,2.4-6.8,2.4c-2.8,0-5.1-0.8-6.8-2.4C164.1,21.1,163.3,19.1,163.3,16.5z M181,95h-16.7V32.7H181 V95z"
        style={{ fill: '#2C4752' }}
      ></path>
      <path d="M210.9,81.6h30.5V95h-51.7V84.9l29.3-38.8h-28.5V32.7h50v9.8L210.9,81.6z" style={{ fill: '#2C4752' }}></path>
    </svg>
  );
};

export { BizPayLogo };
