import { FC, useState } from 'react';

import Head from 'next/head';
import { useRouter } from 'next/router';
import { BizPayAdminPortalDynamicNextJsTitle, BizPayPrimaryButton, Box, FullHeightContainer, FullPageLayout, PageHeading } from 'ui';

import { ProductModal } from '../ProductModal/ProductModal';
import { ProductsTable } from '../ProductsTable';

import { useNavigationRoutes } from '../../hooks';

const ProductsPage: FC = () => {
  const { findPageByRoute } = useNavigationRoutes();
  const { route } = useRouter();

  const [isCreateProductModalOpen, setIsCreateProductModalOpen] = useState<boolean>(false);
  const [shouldRefetchProducts, setShouldRefetchProducts] = useState<boolean>(false);

  const handleCreateProductModalClose = () => {
    setIsCreateProductModalOpen(false);
  };

  const handleCreateProductModalCompleted = () => {
    setIsCreateProductModalOpen(false);
    setShouldRefetchProducts(true);
  };

  const handleRefetchProductsCompleted = () => {
    setShouldRefetchProducts(false);
  };

  const { title } = findPageByRoute(route);

  return (
    <>
      <BizPayAdminPortalDynamicNextJsTitle headComponent={Head} pageTitle={title} />

      <Box
        display="flex"
        sx={{
          justifyContent: 'space-between',
        }}
      >
        <PageHeading heading={title} size="h4" />

        <BizPayPrimaryButton mb="sm" onClick={() => setIsCreateProductModalOpen(true)}>
          Create new product
        </BizPayPrimaryButton>
      </Box>

      <ProductModal
        isOpen={isCreateProductModalOpen}
        onClose={handleCreateProductModalClose}
        onCompleted={handleCreateProductModalCompleted}
      />

      <FullPageLayout
        mainComponent={
          <FullHeightContainer>
            <ProductsTable
              paginationOptions={{
                isPaginationEnabled: true,
              }}
              refetchDataOptions={{
                onRefetchDataCompleted: handleRefetchProductsCompleted,
                shouldRefetchData: shouldRefetchProducts,
              }}
            />
          </FullHeightContainer>
        }
      />
    </>
  );
};

export { ProductsPage };
