import { FC, useEffect, useState } from 'react';

import { useAuthentication } from 'common';
import { LoanApplicationAdminStatus, useCreateLoansMutation } from 'graphql-library';
import { BizPayLoader, BizPayModal, ErrorAlert, FullHeightContainer, ScrollArea, Stack, Text } from 'ui';

import { CreateNewLoansForm } from '../CreateNewLoansForm';
import { LoanApplicationsForDisbursementTable } from '../LoanApplicationsForDisbursementTable';

import { CreateNewLoansModalProps } from './CreateNewLoansModal.types';

import { useSignOut } from '../../hooks';

const CreateNewLoansModal: FC<CreateNewLoansModalProps> = ({ isOpen, onClose, onCompleted }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();

  const [errorMessage, setErrorMessage] = useState<string>();

  const [executeCreateLoansMutation, { loading: isCreateLoansLoading }] = useCreateLoansMutation({
    onCompleted: () => {
      setErrorMessage(undefined);

      onClose();
      onCompleted();
    },
    onError: ({ message }) => {
      setErrorMessage(message);
    },
  });

  const handleClose = () => {
    setErrorMessage(undefined);
    onClose();
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isLoading = isCreateLoansLoading;

  return (
    <BizPayModal
      closeOnClickOutside={!isCreateLoansLoading}
      opened={isOpen}
      size={850}
      title="Create new loans"
      withCloseButton={!isCreateLoansLoading}
      onClose={handleClose}
    >
      <Stack h={errorMessage ? 550 : 450} w="100%">
        {isLoading ? (
          <Stack align="center" h="100%" justify="center">
            {isCreateLoansLoading && <BizPayLoader message="Creating new loans, please wait..." />}
          </Stack>
        ) : (
          <>
            <Stack h="100%" justify="flex-start">
              <Text mb="md" mt="md" weight="bold">
                New loans will be created for the following the loan applications:
              </Text>

              <FullHeightContainer mah={200} mb="md" mih={200}>
                <ScrollArea h="100%" w="100%">
                  <LoanApplicationsForDisbursementTable
                    adminStatuses={[LoanApplicationAdminStatus.AwaitingDisbursement]}
                    paginationOptions={{
                      maxPageSize: 10,
                    }}
                    refetchDataOptions={{
                      shouldRefetchData: false,
                    }}
                  />
                </ScrollArea>
              </FullHeightContainer>

              <CreateNewLoansForm
                onSubmit={({ disbursementReference }) => {
                  if (!getIsAuthenticated()) {
                    signOut();
                    return;
                  }

                  executeCreateLoansMutation({
                    variables: {
                      disbursementReference,
                    },
                  });
                }}
              />
            </Stack>

            {errorMessage && (
              <ErrorAlert mt="lg" title="Create loans error" w="100%">
                {errorMessage}
              </ErrorAlert>
            )}
          </>
        )}
      </Stack>
    </BizPayModal>
  );
};

export { CreateNewLoansModal };
