import { DocumentNode, gql } from 'graphql-library';

const retrieveLoanFacilityForEntityQuery: DocumentNode = gql`
  query retrieveLoanFacilityForEntity($entityId: String!) {
    retrieveLoanFacilityForEntity(entityId: $entityId) {
      createdAtUtc
      entity {
        debtServiceCoverRatio
        id
        inceptFacilityLimitInCents
        outstandingBalanceInCents
        provisionInCents
      }
      id
      limitInCents
      remainingLimitInCents
      totalAmountInCentsOfNonDisbursedLoanApplications
      updatedAtUtc
    }
  }
`;

const updateEntityInceptFacilityLimitMutation: DocumentNode = gql`
  mutation updateEntityInceptFacilityLimit($id: ID!, $input: UpdateEntityInput!) {
    updateEntityInceptFacilityLimit(id: $id, input: $input) {
      id
      inceptFacilityLimitInCents
    }
  }
`;

const updateLoanFacilityLimitForEntityMutation: DocumentNode = gql`
  mutation updateLoanFacilityLimitForEntity($id: ID!, $updatedLoanFacilityLimitInCents: Int!) {
    updateLoanFacilityLimitForEntity(id: $id, updatedLoanFacilityLimitInCents: $updatedLoanFacilityLimitInCents) {
      id
      limitInCents
    }
  }
`;

export { retrieveLoanFacilityForEntityQuery, updateEntityInceptFacilityLimitMutation, updateLoanFacilityLimitForEntityMutation };
