import { FC } from 'react';

import { Select, useForm } from 'ui';

import { PARTNER_NO_REFERRAL } from '../EntityPage';

import { ReferredByPartnerFormData, ReferredByPartnerFormProps } from './ReferredByPartnerForm.types';

const ReferredByPartnerForm: FC<ReferredByPartnerFormProps> = ({
  dropdownListOptions,
  isReadOnly,
  label = 'Referred by',
  onChange,
  selectedPartnerId,
}) => {
  const { register } = useForm<ReferredByPartnerFormData>();

  const partnerOptions = [
    {
      label: PARTNER_NO_REFERRAL,
      value: PARTNER_NO_REFERRAL,
    },
    ...dropdownListOptions,
  ];

  return (
    <form id="referred-by-partner-form">
      <Select
        {...register('partnerId')}
        data={partnerOptions}
        defaultValue={PARTNER_NO_REFERRAL}
        label={label}
        nothingFound="No partner found"
        readOnly={isReadOnly}
        size="md"
        sx={({ colors }) => ({
          input: {
            backgroundColor: selectedPartnerId ? `${colors['bizpay-light-gray'][1]} !important` : undefined,
          },
        })}
        value={!selectedPartnerId ? PARTNER_NO_REFERRAL : selectedPartnerId}
        w="100%"
        searchable
        onChange={(value) => {
          if (!value) {
            return;
          }

          onChange(value);
        }}
      />
    </form>
  );
};

export { ReferredByPartnerForm };
