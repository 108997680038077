import { camelCase, pascalCase } from 'change-case';
import dayjs from 'dayjs';

import { formatWithThousandsSeparator } from '../useNumberFormatter';
import { getByteSize } from '../useUnitConversion';

import {
  COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP,
  COOKIE_EXPIRED_DATE,
  COOKIE_MAX_SIZE,
  COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP,
} from './useCookie.constants';
import { CookieOptionalAttributes, Cookie, UseCookie } from './useCookie.types';

const createCookie: UseCookie['createCookie'] = (name, value, optionalAttributesInput) => {
  const optionalAttributes = optionalAttributesInput
    ? ((): CookieOptionalAttributes => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const {
          expires,
          path = '/',
          sameSite = 'Lax',
          secure,
          storageExpiryOptions,
          ...remainingOptionalAttributes
        } = optionalAttributesInput;

        const { expiryTime } = storageExpiryOptions ?? {};
        const { unit: expiryTimeUnit, value: expiryTimeValue } = expiryTime ?? {};

        const updatedExpires =
          expiryTimeUnit && expiryTimeValue
            ? COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP[expiryTimeUnit](dayjs().toDate(), expiryTimeValue).toUTCString()
            : expires;

        return {
          ...remainingOptionalAttributes,
          expires: updatedExpires,
          path,
          sameSite,
          secure: sameSite === 'None' ? true : secure,
        };
      })()
    : ((): CookieOptionalAttributes => {
        // Make sure cookies expire in one day by default
        const expires = COOKIE_TIME_UNIT_EXPIRY_FUNCTION_MAP.days(dayjs().toDate(), 1).toUTCString();

        return {
          expires,
          sameSite: 'Lax',
        };
      })();

  return {
    name,
    value,
    ...optionalAttributes,
  };
};

const getAllCookies: UseCookie['getAllCookies'] = () => {
  if (typeof window === 'undefined') {
    return undefined;
  }

  return document.cookie.split('; ').reduce((accumulator, cookie) => {
    const [name, value] = cookie.split('=');

    accumulator.set(name, value);

    return accumulator;
  }, new Map<string, string>());
};

const getCookieValue: UseCookie['getCookieValue'] = (name) => {
  return getAllCookies()?.get(name);
};

const serializeCookie: UseCookie['serializeCookie'] = (cookie) => {
  const { name, value, ...optionalAttributes } = cookie;
  const { httpOnly, maxAge, secure } = COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP;

  const optionalAttributesMap = Object.keys(optionalAttributes).reduce((accumulator, key) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const optionalCookieAttribute = cookie[key];

    const cookieKey = key === maxAge.interfaceKey ? maxAge.attributeName : pascalCase(key);
    const cookieAttributeValue = key === httpOnly.interfaceKey || key === secure.interfaceKey ? '' : `=${optionalCookieAttribute}`;

    if (optionalCookieAttribute) {
      accumulator.set(cookieKey, `${cookieKey}${cookieAttributeValue}`);
    }

    return accumulator;
  }, new Map());

  const optionalAttributesMapValues = Array.from<string>(optionalAttributesMap.values());
  const serializedOptionalAttributes = optionalAttributesMapValues.join('; ');
  const outputSerializedOptionalAttributes = serializedOptionalAttributes ? `; ${serializedOptionalAttributes}` : '';

  return `${name}=${value}${outputSerializedOptionalAttributes}`;
};

const setCookie: UseCookie['setCookie'] = (cookie) => {
  if (typeof window === 'undefined') {
    return;
  }

  const serializedCookie = serializeCookie(cookie);

  document.cookie = serializedCookie;
};

const deleteCookie: UseCookie['deleteCookie'] = (name, deleteCookieOptionalAttributesInput) => {
  if (typeof window === 'undefined') {
    return;
  }

  const value = getCookieValue(name);

  if (!value) {
    return;
  }

  const updatedCookie: Cookie = {
    ...deleteCookieOptionalAttributesInput,
    expires: COOKIE_EXPIRED_DATE,
    maxAge: 0,
    name,
    value: '',
  };

  setCookie(updatedCookie);
};

const deserializeCookie: UseCookie['deserializeCookie'] = (cookie) => {
  const { expires, httpOnly, maxAge, secure } = COOKIE_ATTRIBUTES_ATTRIBUTE_NAME_INTERFACE_KEY_MAP;

  return cookie.split('; ').reduce((accumulator, keyValuePair, index) => {
    const [key, value] = keyValuePair.split('=');

    const cookieKey = camelCase(key);
    const isFirstIndex = index === 0;

    let cookieValue;

    switch (cookieKey) {
      case expires.interfaceKey: {
        cookieValue = dayjs(value).toDate().toISOString();
        break;
      }
      case httpOnly.interfaceKey: {
        cookieValue = true;
        break;
      }
      case maxAge.interfaceKey: {
        cookieValue = Number(value);
        break;
      }
      case secure.interfaceKey: {
        cookieValue = true;
        break;
      }
      default: {
        cookieValue = value;
      }
    }

    const partialCookie: Partial<Cookie> = isFirstIndex
      ? {
          name: key,
          value,
        }
      : {
          [cookieKey]: cookieValue,
        };

    return {
      ...accumulator,
      ...partialCookie,
    };
  }, {}) as Cookie;
};

const isSecureCookie: UseCookie['isSecureCookie'] = (secureCookieAttribute) => {
  return (secureCookieAttribute ?? '').toLowerCase() === 'true';
};

const isValidCookieSize: UseCookie['isValidCookieSize'] = (cookie) => {
  return getByteSize(cookie) <= COOKIE_MAX_SIZE;
};

const validateCookieSize = (cookie: Cookie): void => {
  const serializedCookie = serializeCookie(cookie);
  const cookieSize = getByteSize(serializedCookie);

  if (cookieSize > COOKIE_MAX_SIZE) {
    throw new Error(
      `The ${cookie.name} cookie is ${formatWithThousandsSeparator(
        cookieSize,
      )} bytes and exceeded the maximum cookie size of ${formatWithThousandsSeparator(COOKIE_MAX_SIZE)} bytes`,
    );
  }
};

const createAccessTokenCookie: UseCookie['createAccessTokenCookie'] = ({ optionalCookieAttributesInput, value }) => {
  const cookie = createCookie('accessToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createAppVersionCookie: UseCookie['createAppVersionCookie'] = ({ optionalCookieAttributesInput, value }) => {
  const cookie = createCookie('appVersion', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createLastVisitedUrlCookie: UseCookie['createLastVisitedUrlCookie'] = ({ optionalCookieAttributesInput, value }) => {
  const cookie = createCookie('lastVisitedUrl', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const createRefreshTokenCookie: UseCookie['createRefreshTokenCookie'] = ({ optionalCookieAttributesInput, value }) => {
  const cookie = createCookie('refreshToken', value, optionalCookieAttributesInput);
  validateCookieSize(cookie);
  setCookie(cookie);
};

const deleteAccessTokenCookie: UseCookie['deleteAccessTokenCookie'] = (deleteCookieOptionalAttributesInput) => {
  deleteCookie('accessToken', deleteCookieOptionalAttributesInput);
};

const deleteLastVisitedUrlCookie: UseCookie['deleteLastVisitedUrlCookie'] = (deleteCookieOptionalAttributesInput) => {
  deleteCookie('lastVisitedUrl', deleteCookieOptionalAttributesInput);
};

const deleteRefreshTokenCookie: UseCookie['deleteRefreshTokenCookie'] = (deleteCookieOptionalAttributesInput) => {
  deleteCookie('refreshToken', deleteCookieOptionalAttributesInput);
};

const getAccessTokenCookie: UseCookie['getAccessTokenCookie'] = () => {
  return getCookieValue('accessToken');
};

const getAppVersionCookie: UseCookie['getAppVersionCookie'] = () => {
  return getCookieValue('appVersion');
};

const getLastVisitedUrlCookie: UseCookie['getLastVisitedUrlCookie'] = () => {
  return getCookieValue('lastVisitedUrl');
};

const getRefreshTokenCookie: UseCookie['getRefreshTokenCookie'] = () => {
  return getCookieValue('refreshToken');
};

export {
  createAccessTokenCookie,
  createAppVersionCookie,
  createCookie,
  createLastVisitedUrlCookie,
  createRefreshTokenCookie,
  deleteAccessTokenCookie,
  deleteCookie,
  deleteLastVisitedUrlCookie,
  deleteRefreshTokenCookie,
  deserializeCookie,
  getAccessTokenCookie,
  getAllCookies,
  getAppVersionCookie,
  getCookieValue,
  getLastVisitedUrlCookie,
  getRefreshTokenCookie,
  isSecureCookie,
  isValidCookieSize,
  serializeCookie,
  setCookie,
};
