/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';

import { Menu } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { kebabCase } from 'common';

import { UserDropdownMenuItem } from '../UserDropdownMenuItem';
import { UserProfileButton } from '../UserProfileButton';

import { UserDropdownMenuProps } from './UserDropdownMenu.types';

const UserDropdownMenu: FC<UserDropdownMenuProps> = ({ email, fullName, menuItems = [], onSignOut }) => {
  return (
    <Menu offset={18} position="bottom-end" shadow="xs" width={200}>
      <Menu.Target>
        <UserProfileButton email={email} fullName={fullName} />
      </Menu.Target>

      <Menu.Dropdown>
        {menuItems.map(({ icon, label, onClick }) => {
          return <UserDropdownMenuItem key={kebabCase(label)} icon={icon} label={label} onClick={onClick} />;
        })}

        {!!menuItems.length && <Menu.Divider />}

        <UserDropdownMenuItem icon={<IconLogout size={20} />} label="Sign out" onClick={onSignOut} />
      </Menu.Dropdown>
    </Menu>
  );
};

export { UserDropdownMenu };
