import { FC, useEffect, useState } from 'react';

import { dayjs, sentenceCase, useAuthentication, useNumberFormatter } from 'common';
import { useRetrievePaginatedLoanApplicationsDeclinedOrWithdrawnLazyQuery, useTablePagination } from 'graphql-library';
import { useRouter } from 'next/router';
import {
  BizPayPagination,
  BizPayTable,
  createColumnHelper,
  getCoreRowModel,
  RefreshDataControl,
  SortingState,
  TablePageLayout,
  Updater,
  useBizPayNotification,
  useReactTable,
} from 'ui';

import { LOAN_APPLICATIONS_PAGE_CONSTANTS } from '../LoanApplicationsPage';

import {
  LoanApplicationsDeclinedOrWithdrawnTableProps,
  PaginatedLoanApplicationDeclinedOrWithdrawn,
} from './LoanApplicationsDeclinedOrWithdrawnTable.types';

import { useSignOut } from '../../hooks';

const LoanApplicationsDeclinedOrWithdrawnTable: FC<LoanApplicationsDeclinedOrWithdrawnTableProps> = ({
  paginationOptions: { isPaginationEnabled = false, maxPageSize },
  tabId,
}) => {
  const { getIsAuthenticated } = useAuthentication();
  const { displayErrorNotification } = useBizPayNotification();
  const { formatCurrency } = useNumberFormatter();
  const {
    calculateRecordsToSkip,
    calculateTotalPages,
    canPaginate,
    generatePaginationResultsDescription,
    getInitialPageSize,
    getPageNumberFromQuerystring,
  } = useTablePagination();
  const { push, query, route } = useRouter();
  const { signOut } = useSignOut();

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(getPageNumberFromQuerystring(query.pageNumber));
  const [currentPageSize, setCurrentPageSize] = useState<number>(maxPageSize ?? getInitialPageSize());
  const [hasRetrievedData, setHasRetrievedData] = useState<boolean>(false);
  const [isRefreshDataIconButtonDisabled, setIsRefreshDataIconButtonDisabled] = useState<boolean>();
  const [lastRetrievedDataAt, setLastRetrievedDataAt] = useState<string>();
  const [loanApplications, setLoanApplications] = useState<PaginatedLoanApplicationDeclinedOrWithdrawn[]>([]);
  const [shouldRefetchData, setShouldRefetchData] = useState<boolean>(false);
  const [sortingState, setSortingState] = useState<SortingState>([
    {
      desc: true,
      id: 'updatedAtUtc',
    },
  ]);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const [
    executeRetrievePaginatedLoanApplicationsDeclinedOrWithdrawnQuery,
    { loading: isRetrievePaginatedLoanApplicationsLoading, refetch },
  ] = useRetrievePaginatedLoanApplicationsDeclinedOrWithdrawnLazyQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: ({ retrievePaginatedLoanApplicationsByAdminStatus: { count, data } }) => {
      setHasRetrievedData(true);
      setIsRefreshDataIconButtonDisabled(true);
      setLastRetrievedDataAt(dayjs().toDate().toLocaleString());
      setLoanApplications(data);
      setShouldRefetchData(false);
      setTotalPages(calculateTotalPages(currentPageSize, count));
      setTotalRecords(count);

      if (canPaginate(currentPageSize, count) && isPaginationEnabled) {
        push(`${route}?tab=${tabId}&pageNumber=${currentPageNumber}`, undefined, {
          shallow: true,
        });
      }
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve declined/withdrawn loan applications',
      });
    },
  });

  const getTableColumns = (records: number) => {
    const columnHelper = createColumnHelper<PaginatedLoanApplicationDeclinedOrWithdrawn>();
    const isSortingEnabled = records > 1;

    return [
      columnHelper.accessor('niceId', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Id',
        size: 150,
      }),
      columnHelper.accessor('entity.name', {
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        header: 'Entity name',
        size: 200,
      }),
      columnHelper.accessor(
        ({ invoice }) => ({
          invoice,
        }),
        {
          cell: ({ getValue }) => {
            const { invoice } = getValue();

            if (!invoice) {
              return '-';
            }

            return invoice.supplier?.name ?? '-';
          },
          enableSorting: false,
          header: 'Supplier name',
          size: 300,
        },
      ),
      columnHelper.accessor('adminStatus', {
        cell: ({ getValue }) => {
          const adminStatus = getValue();
          return adminStatus ? sentenceCase(adminStatus) : '-';
        },
        enableSorting: isSortingEnabled,
        header: 'Status',
        meta: {
          align: 'center',
        },
        size: 200,
      }),
      columnHelper.accessor(
        ({ invoice }) => ({
          invoice,
        }),
        {
          cell: ({ getValue }) => {
            const { invoice } = getValue();

            if (!invoice) {
              return '-';
            }

            return invoice.totalAmountInCents ? formatCurrency(invoice.totalAmountInCents) : '-';
          },
          enableSorting: false,
          header: 'Invoice amount',
          meta: {
            align: 'right',
          },
          size: 150,
        },
      ),
      columnHelper.accessor('updatedAtUtc', {
        cell: ({ getValue }) => {
          const submittedAtUtc = getValue();
          return submittedAtUtc ? dayjs(submittedAtUtc).toDate().toLocaleString() : '-';
        },
        enableSorting: isSortingEnabled,
        header: 'Date last updated',
        size: 200,
      }),
      columnHelper.accessor('withdrawnAtUtc', {
        cell: ({ getValue }) => {
          const withdrawnAtUtc = getValue();
          return withdrawnAtUtc ? dayjs(withdrawnAtUtc).toDate().toLocaleString() : '-';
        },
        enableSorting: isSortingEnabled,
        header: 'Date withdrawn',
        size: 200,
      }),
      columnHelper.accessor(
        ({ id }) => ({
          id,
        }),
        {
          cell: () => undefined,
          enableSorting: false,
          header: () => (
            <RefreshDataControl
              dataType="loan-application"
              isRefreshDataIconButtonDisabled={isRefreshDataIconButtonDisabled}
              lastRetrievedDataAt={lastRetrievedDataAt}
              onRefreshDataIconButtonClick={handleRefreshDataIconButtonClick}
            />
          ),
          id: 'action',
          meta: {
            align: 'center',
          },
          minSize: 10,
          size: 10,
        },
      ),
    ];
  };

  const handleLoanApplicationClick = ({ id }: PaginatedLoanApplicationDeclinedOrWithdrawn) => {
    push(`/loan-applications/${id}?returnTab=${LOAN_APPLICATIONS_PAGE_CONSTANTS.tabs.ids.loanApplicationsDeclinedWithdrawn}`);
  };

  const handlePageNumberChange = (number: number) => {
    setCurrentPageNumber(number);
  };

  const handleRefreshDataIconButtonClick = () => {
    setShouldRefetchData(true);
  };

  const handleSortColumnChange = (sortingState: Updater<SortingState>) => {
    setCurrentPageNumber(1);
    setSortingState(sortingState);
  };

  const [firstSortingState] = sortingState;
  const { desc: isDescendingSort, id: sortField } = firstSortingState ?? {};

  const isAuthenticated = getIsAuthenticated();

  const { getHeaderGroups, getRowModel } = useReactTable<PaginatedLoanApplicationDeclinedOrWithdrawn>({
    columns: getTableColumns(totalRecords),
    data: loanApplications,
    getCoreRowModel: getCoreRowModel(),
    manualPagination: true,
    manualSorting: true,
    onSortingChange: handleSortColumnChange,
    state: {
      sorting: sortingState,
    },
  });

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrievePaginatedLoanApplicationsDeclinedOrWithdrawnQuery({
      variables: {
        pagination: {
          skip: calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
          take: currentPageSize,
        },
        sort: {
          field: sortField,
          isDescending: isDescendingSort,
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageNumber, currentPageSize, isAuthenticated, isDescendingSort, sortField]);

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    if (!shouldRefetchData) {
      return;
    }

    refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, shouldRefetchData]);

  useEffect(() => {
    if (!isRefreshDataIconButtonDisabled) {
      return;
    }

    const timerId = setTimeout(() => {
      setIsRefreshDataIconButtonDisabled(false);
    }, Number(process.env.NEXT_PUBLIC_ENABLE_REFRESH_DATA_INTERVAL_IN_MILLISECONDS));

    return () => {
      clearTimeout(timerId);
    };
  }, [isRefreshDataIconButtonDisabled]);

  return (
    <TablePageLayout
      loadingMessage={isRetrievePaginatedLoanApplicationsLoading ? 'Retrieving declined/withdrawn loan applications...' : undefined}
      paginationComponent={
        <BizPayPagination
          description={generatePaginationResultsDescription(
            isPaginationEnabled,
            currentPageNumber,
            currentPageSize,
            calculateRecordsToSkip(isPaginationEnabled, currentPageNumber, currentPageSize),
            totalRecords,
          )}
          hasRetrievedData={hasRetrievedData}
          isEnabled={isPaginationEnabled}
          pageSize={currentPageSize}
          totalPages={totalPages}
          totalRecords={totalRecords}
          value={currentPageNumber}
          onChange={handlePageNumberChange}
        />
      }
      tableComponent={
        <BizPayTable<PaginatedLoanApplicationDeclinedOrWithdrawn>
          hasRetrievedData={hasRetrievedData}
          headerGroups={getHeaderGroups()}
          noRecordsMessage="No declined/withdrawn loan applications found"
          rowModel={getRowModel()}
          onRowClick={handleLoanApplicationClick}
        />
      }
    />
  );
};

export { LoanApplicationsDeclinedOrWithdrawnTable };
