import { FC } from 'react';

import { IconAlertCircle } from '@tabler/icons-react';

import { BizPayTickIcon } from '../BizPayTickIcon';

import { TextInputRightSectionProps } from './TextInputRightSection.types';

import { useBizPayUITheme } from '../../hooks';

const TextInputRightSection: FC<TextInputRightSectionProps> = ({ error, value }) => {
  const {
    theme: { colors },
  } = useBizPayUITheme();

  if (error) {
    return <IconAlertCircle color={colors?.['bizpay-red']?.[7]} size={20} />;
  }

  if (!!value) {
    return <BizPayTickIcon />;
  }

  return null;
};

export { TextInputRightSection };
