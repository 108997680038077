import { FC } from 'react';

import { Box } from '@mantine/core';

const IconEftsureGreenThumbsLeft: FC = () => {
  const svgData = `
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="25" height="25" viewBox="0 0 256 256" xml:space="preserve">
    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934158 254.59340659340654) rotate(-90) scale(2.81 2.81)" >
      <circle cx="45" cy="45" r="45" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(19,161,81); fill-rule: nonzero; opacity: 1;" transform="  matrix(1 0 0 1 0 0) "/>
      <path d="M 20.142 66.312 h 10.208 c 0.795 0 1.44 -0.645 1.44 -1.44 V 37.665 c 0 -0.795 -0.645 -1.44 -1.44 -1.44 H 20.142 V 66.312 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
      <path d="M 66.094 43.729 c 2.079 0 3.764 -1.685 3.764 -3.764 c 0 -2.079 -1.685 -3.764 -3.764 -3.764 h -11.93 c 2.076 -3.739 2.139 -15.096 -2.787 -16.46 c -0.933 -0.258 -1.859 0.454 -1.963 1.417 c -0.866 7.97 -5.742 17.877 -10.7 18.164 h -3.862 v 23.755 h 2.003 c 1.078 0 2.108 0.368 3.008 0.963 c 2.245 1.486 6.025 2.356 9.648 2.265 h 1.752 v 0.006 h 12.036 c 2.079 0 3.764 -1.685 3.764 -3.764 s -1.685 -3.764 -3.764 -3.764 h 1.678 c 2.079 0 3.764 -1.685 3.764 -3.764 c 0 -2.079 -1.685 -3.764 -3.764 -3.764 h 1.118 c 2.079 0 3.764 -1.685 3.764 -3.764 S 68.173 43.729 66.094 43.729 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
    </g>
  </svg>`;

  return <Box dangerouslySetInnerHTML={{ __html: svgData }} display="flex" style={{ alignItems: 'center', justifyContent: 'center' }} />;
};

export { IconEftsureGreenThumbsLeft };
