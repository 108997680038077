import { FC, useEffect } from 'react';

import { useAuthentication } from 'common';
import { BizPayPrimaryButton, ErrorMessage, Group, Stack, TextInput, TextInputRightSection, useForm } from 'ui';

import { createNewLoansFormZodResolver } from './CreateNewLoansForm.helpers';
import { CreateNewLoansFormData, CreateNewLoansFormProps } from './CreateNewLoansForm.types';

import { useSignOut } from '../../hooks';

const CreateNewLoansForm: FC<CreateNewLoansFormProps<CreateNewLoansFormData>> = ({ onSubmit }) => {
  const { getIsAuthenticated } = useAuthentication();
  const { signOut } = useSignOut();

  const {
    formState: { errors, isValid },
    handleSubmit,
    register,
    setFocus,
    watch,
  } = useForm<CreateNewLoansFormData>({
    mode: 'onChange',
    resolver: createNewLoansFormZodResolver(),
  });

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) {
      return;
    }

    signOut();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    setFocus('disbursementReference');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { disbursementReference: disbursementReferenceError } = errors;

  return (
    <form
      id="create-new-loans-form"
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Stack h="100%" w="100%">
        <TextInput
          {...register('disbursementReference')}
          error={disbursementReferenceError ? <ErrorMessage message={disbursementReferenceError.message} /> : null}
          label="Disbursement reference"
          placeholder="Please enter the disbursement reference from the payment provider"
          rightSection={<TextInputRightSection error={disbursementReferenceError} value={watch('disbursementReference')} />}
          size="md"
        />
      </Stack>

      <Group mt="xl" position="right" w="100%">
        <BizPayPrimaryButton disabled={!isValid} type="submit">
          Create loans
        </BizPayPrimaryButton>
      </Group>
    </form>
  );
};

export { CreateNewLoansForm };
