import { DocumentNode, gql } from 'graphql-library';

const createPartnerMutation: DocumentNode = gql`
  mutation createPartner($name: String!) {
    createPartner(name: $name) {
      id
    }
  }
`;

export { createPartnerMutation };
