import { DocumentNode, gql } from 'graphql-library';

const createDisbursementsFileMutation: DocumentNode = gql`
  mutation createDisbursementsFile {
    createDisbursementsFile {
      createdAtUtc
      id
      signedUrl
    }
  }
`;

export { createDisbursementsFileMutation };
