import { FC } from 'react';

import { Alert, ScrollArea, Text } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

import { ErrorAlertProps } from './ErrorAlert.types';

import { useBizPayUITheme } from '../../hooks';

const ErrorAlert: FC<ErrorAlertProps> = ({ children, contentScrollAreaHeight, title, ...otherProps }) => {
  const {
    theme: { colors },
  } = useBizPayUITheme();

  return (
    <Alert
      icon={<IconAlertCircle color={colors?.['bizpay-red']?.[8]} />}
      sx={({ colors }) => ({
        backgroundColor: colors['bizpay-red'][2],
        border: `0.075rem solid ${colors['bizpay-red'][3]}`,
      })}
      title={
        title && (
          <Text
            mt={4}
            sx={({ colors }) => ({
              color: colors['bizpay-red'][8],
            })}
          >
            {title}
          </Text>
        )
      }
      variant="filled"
      {...otherProps}
    >
      <ScrollArea h={contentScrollAreaHeight}>
        <Text
          sx={({ colors }) => ({
            color: colors['bizpay-red'][8],
            lineHeight: '1rem',
          })}
        >
          {children}
        </Text>
      </ScrollArea>
    </Alert>
  );
};

export { ErrorAlert };
