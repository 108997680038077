import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import { z } from 'zod';

const createNoteFormZodResolver = () => {
  return zodResolver(
    z.object({
      message: z.string().min(1, {
        message: 'Please enter a message',
      }),
    }),
  );
};

export { createNoteFormZodResolver };
