import { FC } from 'react';

import { EntityStakeholderDirectorsTable } from '../EntityStakeholderDirectorsTable';
import { EntityStakeholderShareholdersTable } from '../EntityStakeholderShareholdersTable';

import { EntityDirectorsTabProps } from './EntityDirectorsTab.types';

const EntityDirectorsTab: FC<EntityDirectorsTabProps> = ({ entityId }) => {
  return (
    <>
      <EntityStakeholderDirectorsTable entityId={entityId} />
      <EntityStakeholderShareholdersTable entityId={entityId} />
    </>
  );
};

export { EntityDirectorsTab };
