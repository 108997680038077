import { isURL, z, zodResolver } from 'ui';

const socialMediaUrlValidationZodEffect = z.string().refine(
  (value) => {
    if (!value) {
      return true;
    }

    return isURL(value, {
      protocols: ['http', 'https'],
      require_protocol: true,
    });
  },
  {
    message: 'Please enter a valid url',
  },
);

const entityWebsiteUrlValidationZodEffect = socialMediaUrlValidationZodEffect;

const entityInfoFormZodResolver = () => {
  return zodResolver(
    z.object({
      abrRegisteredState: z.string(),
      city: z
        .string()
        .max(50, {
          message: 'City must be at most 50 characters',
        })
        .regex(/^[a-zA-Z0-9 ]*$/, {
          message: 'City can only contain alphanumeric characters and spaces',
        }),
      country: z.string(),
      facebookUrl: socialMediaUrlValidationZodEffect,
      industry: z.string(),
      instagramUrl: socialMediaUrlValidationZodEffect,
      linkedInUrl: socialMediaUrlValidationZodEffect,
      postcode: z
        .string()
        .regex(/^\d*$/, {
          message: 'Postcode can only contain numerical digits',
        })
        .refine(
          (value) => {
            if (!value) {
              return true;
            }

            if (/^\d{4}$/.test(value)) {
              return true;
            }
          },
          {
            message: 'Postcode must be a 4-digit number',
          },
        ),
      street: z.string().max(50, {
        message: 'Street must be at most 50 characters',
      }),
      state: z.string().min(1, {
        message: 'Please select state',
      }),
      suburb: z
        .string()
        .max(50, {
          message: 'Suburb must be at most 50 characters',
        })
        .regex(/^[a-zA-Z0-9 ]*$/, {
          message: 'Suburb can only contain alphanumeric characters and spaces',
        }),
      twitterUrl: socialMediaUrlValidationZodEffect,
      websiteUrl: entityWebsiteUrlValidationZodEffect,
    }),
  );
};

export { entityInfoFormZodResolver };
