import { FC } from 'react';

import { Group, ScrollArea, Stack } from '@mantine/core';

import { BizPayLoader } from '../BizPayLoader';

import { TablePageLayoutProps } from './TablePageLayout.types';

const TablePageLayout: FC<TablePageLayoutProps> = ({
  height = '100%',
  loadingMessage,
  paginationComponent,
  stackProps,
  tableComponent,
}) => {
  return (
    <Stack h={height} id="table-page-layout" w="100%" {...stackProps}>
      {loadingMessage ? (
        <Stack align="center" h="100%" justify="center">
          <BizPayLoader message={loadingMessage} />
        </Stack>
      ) : (
        <Stack h={height} id="table-component-container" w="100%">
          <ScrollArea h="100%" w="100%">
            {tableComponent}
          </ScrollArea>

          <Group align="center" id="table-pagination-component-container" mt="sm" position="center" w="100%">
            {paginationComponent}
          </Group>
        </Stack>
      )}
    </Stack>
  );
};

export { TablePageLayout };
