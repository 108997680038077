import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedStaffMembersQuery: DocumentNode = gql`
  query retrievePaginatedStaffMembers($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedStaffMembers(pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        email
        firstName
        id
        lastName
        lastSignedInAtUtc
        roles
      }
    }
  }
`;

export { retrievePaginatedStaffMembersQuery };
