import { DocumentNode, gql } from 'graphql-library';

const invoiceFragment: DocumentNode = gql`
  fragment invoiceFragment on Invoice {
    dateDueAtUtc
    dateIssuedAtUtc
    document {
      id
      originalFileName
      signedUrl
    }
    id
    number
    paymentReference
    supplier {
      abn
      email
      id
      supplierBankAccounts {
        accountName
        accountNumber
        bsb
        eftsureVerificationReason
        eftsureVerificationStatus
        eftsureVerifiedAtUtc
        id
        isEftsureVerified
        supplierId
      }
      name
      phone
    }
    totalAmountInCents
    uploadedByUser {
      fullName
      id
    }
  }
`;

const supplierBankAccountFragment: DocumentNode = gql`
  fragment supplierBankAccountFragment on SupplierBankAccount {
    accountName
    accountNumber
    bsb
    eftsureVerificationReason
    eftsureVerificationStatus
    eftsureVerifiedAtUtc
    id
    isEftsureVerified
    supplierId
  }
`;

const createNoteForLoanApplicationMutation: DocumentNode = gql`
  mutation createNoteForLoanApplication($loanApplicationId: String!, $message: String!) {
    createNoteForLoanApplication(loanApplicationId: $loanApplicationId, message: $message) {
      id
      message
    }
  }
`;

const retrieveLoanApplicationForLoanApplicationPageQuery: DocumentNode = gql`
  ${invoiceFragment}

  query retrieveLoanApplicationForLoanApplicationPage($id: ID!) {
    retrieveLoanApplication(id: $id) {
      adminStatus
      assignedToStaffMemberId
      createdAtUtc
      createdByUser {
        fullName
        id
      }
      customerEditedFields
      customerStatus
      directDebitAccount {
        accountName
        accountNumber
        bsb
        financialInstitutionName
        id
        status
      }
      entity {
        id
        name
      }
      id
      invoice {
        ...invoiceFragment
      }
      niceId
      notes {
        createdAtUtc
        id
        message
        submittedByStaffMember {
          firstName
          id
          lastName
        }
      }
      product {
        code
        displayName
        feeType
        feeValue
        frequencyType
        frequencyValue
        id
        name
      }
      submittedAtUtc
      supplierBankAccountId
      updatedAtUtc
    }
  }
`;

const updateInvoiceDetailsForLoanApplicationByStaffMemberMutation: DocumentNode = gql`
  mutation updateInvoiceDetailsForLoanApplicationByStaffMember(
    $invoiceDateDueAtUtc: DateTime!
    $invoiceDateIssuedAtUtc: DateTime!
    $invoiceId: ID!
    $invoiceNumber: String!
    $invoicePaymentReference: String!
    $invoiceTotalAmountInCents: Int!
    $loanApplicationId: String!
    $supplierAbn: String!
    $supplierEmail: String!
    $supplierName: String!
    $supplierPhone: String
  ) {
    updateInvoiceDetailsForLoanApplicationByStaffMember(
      dateDueAtUtc: $invoiceDateDueAtUtc
      dateIssuedAtUtc: $invoiceDateIssuedAtUtc
      email: $supplierEmail
      invoiceId: $invoiceId
      loanApplicationId: $loanApplicationId
      number: $invoiceNumber
      paymentReference: $invoicePaymentReference
      phone: $supplierPhone
      supplierAbn: $supplierAbn
      supplierName: $supplierName
      totalAmountInCents: $invoiceTotalAmountInCents
    ) {
      ...invoiceFragment
    }
  }
`;

const updateInvoiceSupplierBankAccountForLoanApplicationMutation: DocumentNode = gql`
  ${supplierBankAccountFragment}

  mutation updateSupplierBankAccountForLoanApplication(
    $input: UpdateSupplierBankAccountInput!
    $loanApplicationId: String!
    $supplierBankAccountId: ID!
  ) {
    updateSupplierBankAccountForLoanApplication(
      input: $input
      loanApplicationId: $loanApplicationId
      supplierBankAccountId: $supplierBankAccountId
    ) {
      ...supplierBankAccountFragment
    }
  }
`;

const updateLoanApplicationSupplierBankAccountIdMutation: DocumentNode = gql`
  mutation updateLoanApplicationSupplierBankAccountId($id: ID!, $supplierBankAccountId: String!) {
    updateLoanApplicationSupplierBankAccountId(id: $id, supplierBankAccountId: $supplierBankAccountId) {
      id
      supplierBankAccountId
    }
  }
`;

export {
  createNoteForLoanApplicationMutation,
  retrieveLoanApplicationForLoanApplicationPageQuery,
  updateInvoiceDetailsForLoanApplicationByStaffMemberMutation,
  updateInvoiceSupplierBankAccountForLoanApplicationMutation,
  updateLoanApplicationSupplierBankAccountIdMutation,
};
