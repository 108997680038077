import { FC, useState } from 'react';

import { Stack } from '@mantine/core';
import { AxiosError, get, useCookie, useFileUpload } from 'common';

import { BizPayLoader } from '../BizPayLoader';
import { BizPayModal } from '../BizPayModal';
import { PageHeading } from '../PageHeading';
import { UploadEntityDocumentForm, UploadEntityDocumentFormData } from '../UploadEntityDocumentForm';

import { UploadEntityDocumentModalProps } from './UploadEntityDocumentModal.types';

import { useBizPayNotification } from '../../hooks';

const UploadEntityDocumentModal: FC<UploadEntityDocumentModalProps> = ({ endpointPath, isOpen, entityId, onClose, onCompleted }) => {
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { getAccessTokenCookie } = useCookie();

  const [entityDocumentSignedUrl, setEntityDocumentSignedUrl] = useState<string>();

  const { getUploadFileFormData, uploadFile, uploadFileResponse } = useFileUpload<Document>({
    baseApiEndpointUrl: String(process.env.NEXT_PUBLIC_API_BASE_URL),
    onCompleted: () => {
      displaySuccessNotification({
        message: 'Successfully uploaded the document',
      });

      onCompleted();
    },
    onError: (error: AxiosError) => {
      displayErrorNotification({
        message: get(error, 'response.data.message') ?? error.message,
      });
    },
    path: `/entity/${endpointPath}`,
    token: getAccessTokenCookie(),
  });

  const handleClose = () => {
    onClose();
  };

  const handleFileUpload = async (data: UploadEntityDocumentFormData) => {
    const { description, document, documentType } = data;

    setEntityDocumentSignedUrl(undefined);

    const formData = getUploadFileFormData({
      file: document,
    });

    formData.append('description', description);
    formData.append('documentType', documentType);
    formData.append('entityId', entityId);

    await uploadFile({
      formData,
    });
  };

  const { isLoading } = uploadFileResponse;

  return (
    <BizPayModal
      closeOnClickOutside={!isLoading}
      opened={isOpen}
      size={650}
      title={
        <PageHeading
          flexContainerProps={{
            mb: 0,
          }}
          heading="Upload document"
          size="h4"
        />
      }
      withCloseButton={!isLoading}
      onClose={handleClose}
    >
      {isLoading ? (
        <Stack align="center" h={300} justify="center">
          {uploadFileResponse.isLoading && <BizPayLoader message="Uploading document, please wait..." />}
        </Stack>
      ) : (
        <UploadEntityDocumentForm signedUrl={entityDocumentSignedUrl} onSubmit={handleFileUpload} />
      )}
    </BizPayModal>
  );
};

export { UploadEntityDocumentModal };
