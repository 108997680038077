import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedInvoicesQuery: DocumentNode = gql`
  query retrievePaginatedInvoices($pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedInvoices(pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        dateDueAtUtc
        dateIssuedAtUtc
        id
        number
        totalAmountInCents
      }
    }
  }
`;

export { retrievePaginatedInvoicesQuery };
