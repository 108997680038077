import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedDirectDebitAccountsForEntityQuery: DocumentNode = gql`
  query retrievePaginatedDirectDebitAccountsForEntity($entityId: ID!, $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedDirectDebitAccountsForEntity(entityId: $entityId, pagination: $pagination, sort: $sort) {
      count
      data {
        accountName
        accountNumber
        alias
        bsb
        createdAtUtc
        financialInstitutionName
        id
        isDefault
        status
      }
    }
  }
`;

export { retrievePaginatedDirectDebitAccountsForEntityQuery };
