import { DocumentNode, gql } from 'graphql-library';

const retrievePaginatedPartnersQuery: DocumentNode = gql`
  query retrievePaginatedPartners($filters: [FilterInput!], $pagination: PaginationInput!, $sort: SortInput!) {
    retrievePaginatedPartners(filters: $filters, pagination: $pagination, sort: $sort) {
      count
      data {
        createdAtUtc
        id
        name
        token
        url
      }
    }
  }
`;

export { retrievePaginatedPartnersQuery };
