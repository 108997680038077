import { FC, useEffect, useState } from 'react';

import { sentenceCase, useAuthentication } from 'common';
import {
  LoanApplicationAdminStatus,
  useAssignLoanApplicationToStaffMemberMutation,
  useRetrieveLatestDisbursementsFileLazyQuery,
  useRetrieveStaffMembersLazyQuery,
  useUpdateLoanApplicationAdminStatusMutation,
} from 'graphql-library';
import { AssignStatusForm, AssignToStaffMemberForm, BizPayConfirmationModal, BizPayLoadingOverlay, Stack, useBizPayNotification } from 'ui';

import { LoanApplicationPageLoanApplication } from '../LoanApplicationPage';
import { isDateTodaysDate } from '../LoanApplicationsForDisbursementTab';

import { LOAN_APPLICATION_ADMIN_STATUS_TO_COLOR_MAP } from './LoanApplicationActions.constants';
import { generateLoanApplicationStatusDropdownListOptions } from './LoanApplicationActions.helpers';
import { LoanApplicationActionsAssignedToStaffMember, LoanApplicationActionsProps } from './LoanApplicationActions.types';

import { useSignOut } from '../../hooks';

const LoanApplicationActions: FC<LoanApplicationActionsProps> = ({
  loanApplication,
  onLoanApplicationAdminStatusUpdated,
  onLoanApplicationAssignedToStaffMemberUpdated,
}) => {
  const { getIsAuthenticated, getStaffMemberDisplayName } = useAuthentication();
  const { displayErrorNotification, displaySuccessNotification } = useBizPayNotification();
  const { signOut } = useSignOut();

  const [assignToStaffMembers, setAssignToStaffMembers] = useState<LoanApplicationActionsAssignedToStaffMember[]>([]);
  const [confirmationStatusModalText, setConfirmStatusModalText] = useState<string>();
  const [hasGeneratedDisbursementsFileToday, setHasGeneratedDisbursementsFileToday] = useState<boolean>(false);
  const [isConfirmationStatusModalOpened, setIsConfirmStatusModalOpened] = useState<boolean>(false);
  const [loanApplicationAdminStatus, setLoanApplicationAdminStatus] = useState<LoanApplicationPageLoanApplication['adminStatus']>();
  const [loanApplicationAssignedToStaffMemberId, setLoanApplicationAssignedToStaffMemberId] =
    useState<LoanApplicationPageLoanApplication['assignedToStaffMemberId']>();
  const [nextLoanApplicationAdminStatus, setNextLoanApplicationAdminStatus] = useState<LoanApplicationPageLoanApplication['adminStatus']>();

  const [executeAssignLoanApplicationToStaffMemberMutation, { loading: isAssignLoanApplicationToStaffMemberLoading }] =
    useAssignLoanApplicationToStaffMemberMutation({
      onCompleted: ({ assignLoanApplicationToStaffMember: { adminStatus, assignedToStaffMemberId } }) => {
        onLoanApplicationAssignedToStaffMemberUpdated(assignedToStaffMemberId);

        setLoanApplicationAdminStatus(adminStatus);
        setLoanApplicationAssignedToStaffMemberId(assignedToStaffMemberId);
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to assign staff member',
        });
      },
    });

  const [executeRetrieveLatestDisbursementsFileQuery, { loading: isRetrieveLatestDisbursementsFileLoading }] =
    useRetrieveLatestDisbursementsFileLazyQuery({
      fetchPolicy: 'cache-and-network',
      onCompleted: ({ retrieveLatestDisbursementsFile: latestDisbursementsFile }) => {
        if (!latestDisbursementsFile) {
          setHasGeneratedDisbursementsFileToday(false);
          return;
        }

        setHasGeneratedDisbursementsFileToday(isDateTodaysDate(latestDisbursementsFile.createdAtUtc));
      },
      onError: () => {
        displayErrorNotification({
          message: 'Unable to retrieve the latest disbursements file',
        });
      },
    });

  const [executeRetrieveStaffMembersLazyQuery, { loading: isRetrieveStaffMembersLoading }] = useRetrieveStaffMembersLazyQuery({
    onCompleted: ({ retrieveStaffMembers: returnedStaffMembers }) => {
      setAssignToStaffMembers(returnedStaffMembers);
    },
    onError: () => {
      displayErrorNotification({
        message: 'Unable to retrieve staff members',
      });
    },
  });

  const [executeUpdateLoanApplicationAdminStatusMutation, { loading: isUpdateLoanApplicationAdminStatusLoading }] =
    useUpdateLoanApplicationAdminStatusMutation({
      onCompleted: ({ updateLoanApplicationAdminStatus: { adminStatus, assignedToStaffMemberId } }) => {
        if (!adminStatus) {
          displayErrorNotification({
            message: 'No loan application admin status was returned',
          });
          return;
        }

        if (adminStatus !== loanApplication.adminStatus) {
          displaySuccessNotification({
            message: `Successfully updated the status to ${sentenceCase(adminStatus)}`,
          });
        }

        onLoanApplicationAdminStatusUpdated(adminStatus, assignedToStaffMemberId);

        setIsConfirmStatusModalOpened(false);
        setLoanApplicationAdminStatus(adminStatus);

        if (
          adminStatus === LoanApplicationAdminStatus.UnderReview &&
          loanApplication.adminStatus === LoanApplicationAdminStatus.ReadyForReview
        ) {
          setLoanApplicationAssignedToStaffMemberId(assignedToStaffMemberId);
        }
      },
      onError: ({ message }) => {
        displayErrorNotification({
          message,
        });
        setIsConfirmStatusModalOpened(false);
      },
    });

  const getAssignedTo = (staffMemberId?: string) => {
    if (!staffMemberId) {
      return undefined;
    }

    const assignedToStaffMember = assignToStaffMembers.find(({ id }) => id === staffMemberId);

    if (!assignedToStaffMember) {
      return undefined;
    }

    return getStaffMemberDisplayName(assignedToStaffMember.fullName, assignedToStaffMember.id);
  };

  const handleConfirmationModalClose = () => {
    setIsConfirmStatusModalOpened(false);
  };

  const isAuthenticated = getIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated) {
      signOut();
      return;
    }

    executeRetrieveLatestDisbursementsFileQuery();
    executeRetrieveStaffMembersLazyQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    setLoanApplicationAdminStatus(loanApplication.adminStatus);
    setLoanApplicationAssignedToStaffMemberId(loanApplication.assignedToStaffMemberId);
  }, [loanApplication]);

  return (
    <>
      {isAssignLoanApplicationToStaffMemberLoading && <BizPayLoadingOverlay message="Assigning staff member, please wait..." />}

      {isRetrieveLatestDisbursementsFileLoading && <BizPayLoadingOverlay message="Retrieving latest disbursement file, please wait..." />}

      {isRetrieveStaffMembersLoading && <BizPayLoadingOverlay message="Retrieving staff members, please wait..." />}

      {isUpdateLoanApplicationAdminStatusLoading && <BizPayLoadingOverlay message="Updating admin status, please wait..." />}

      <BizPayConfirmationModal
        closeOnClickOutside={!isUpdateLoanApplicationAdminStatusLoading}
        closeOnEscape={!isUpdateLoanApplicationAdminStatusLoading}
        opened={isConfirmationStatusModalOpened}
        size={750}
        title="Change status"
        onClose={handleConfirmationModalClose}
        onConfirm={() => {
          if (!isAuthenticated) {
            signOut();
            return;
          }

          if (!nextLoanApplicationAdminStatus) {
            return;
          }

          executeUpdateLoanApplicationAdminStatusMutation({
            variables: {
              id: loanApplication.id,
              loanApplicationAdminStatus: nextLoanApplicationAdminStatus,
            },
          });
        }}
      >
        {confirmationStatusModalText}
      </BizPayConfirmationModal>

      <Stack w="100%">
        <AssignStatusForm
          backgroundColor={loanApplicationAdminStatus ? LOAN_APPLICATION_ADMIN_STATUS_TO_COLOR_MAP[loanApplicationAdminStatus] : undefined}
          dropdownListOptions={generateLoanApplicationStatusDropdownListOptions(
            hasGeneratedDisbursementsFileToday,
            loanApplicationAdminStatus,
          ).map((loanApplicationAdminStatus) => ({
            label: sentenceCase(loanApplicationAdminStatus),
            value: loanApplicationAdminStatus,
          }))}
          isReadOnly={
            loanApplicationAdminStatus === LoanApplicationAdminStatus.AwaitingDisbursement ||
            loanApplicationAdminStatus === LoanApplicationAdminStatus.Declined
          }
          label="Loan application status"
          selectedStatus={loanApplicationAdminStatus}
          onChange={(selectedLoanApplicationAdminStatus) => {
            if (!getIsAuthenticated()) {
              signOut();
              return;
            }

            if (!loanApplicationAdminStatus || selectedLoanApplicationAdminStatus === loanApplicationAdminStatus) {
              return;
            }

            setConfirmStatusModalText(
              `Are you sure you want to change the status from ${sentenceCase(loanApplicationAdminStatus)} to ${sentenceCase(
                selectedLoanApplicationAdminStatus,
              )}?`,
            );

            setIsConfirmStatusModalOpened(true);
            setNextLoanApplicationAdminStatus(selectedLoanApplicationAdminStatus as LoanApplicationAdminStatus);
          }}
        />
      </Stack>

      <Stack mt="xs">
        <AssignToStaffMemberForm
          dropdownListOptions={assignToStaffMembers.map(({ id }) => ({
            label: getAssignedTo(id),
            value: id,
          }))}
          isReadOnly={
            loanApplicationAdminStatus === LoanApplicationAdminStatus.AwaitingDisbursement ||
            loanApplicationAdminStatus === LoanApplicationAdminStatus.Declined
          }
          selectedAssignedToStaffMemberId={loanApplicationAssignedToStaffMemberId}
          onChange={(selectedAssignedToStaffMemberId) => {
            if (!getIsAuthenticated()) {
              signOut();
              return;
            }

            executeAssignLoanApplicationToStaffMemberMutation({
              variables: {
                assignedToStaffMemberId: selectedAssignedToStaffMemberId,
                id: loanApplication.id,
              },
            });
          }}
        />
      </Stack>
    </>
  );
};

export { LoanApplicationActions };
