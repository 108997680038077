import { zodResolver } from '@hookform/resolvers/zod/dist/zod';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { z } from 'zod';

const userProfileFormZodResolver = () => {
  return zodResolver(
    z.object({
      email: z
        .string()
        .min(1, {
          message: 'Please enter your email',
        })
        .refine((value) => isEmail(value), {
          message: 'Please enter a valid email',
        }),
      firstName: z.string().min(2, {
        message: 'Please enter your first name',
      }),
      lastName: z.string().min(2, {
        message: 'Please enter your last name',
      }),
      middleName: z.string().optional(),
      mobileNumber: z
        .string()
        .nullable()
        .optional()
        .refine(
          (value) => {
            if (!value) {
              return true;
            }

            return isMobilePhone(value + '', 'en-AU');
          },
          {
            message: 'Please enter a valid Australian mobile number',
          },
        ),
    }),
  );
};

export { userProfileFormZodResolver };
