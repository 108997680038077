import './BizPayTable.css';

import { Flex, Group, Table } from '@mantine/core';
import { IconArrowDown, IconArrowUp } from '@tabler/icons-react';
import { flexRender } from '@tanstack/react-table';

import { useBizPayTableStyles } from './BizPayTable.helpers';
import { BizPayTableProps } from './BizPayTable.types';

const BizPayTable = <T,>({
  disableOnClickForRowIndexes = [],
  hasRetrievedData,
  headerGroups,
  noRecordsMessage = 'No records found',
  onRowClick,
  rowIndexToRowColorMap,
  rowModel: { rows },
  tbodyTdStyles,
  ...otherTableProps
}: BizPayTableProps<T>) => {
  const { classes, cx } = useBizPayTableStyles();

  return (
    <Table striped {...otherTableProps}>
      <thead className={cx(classes.header)}>
        {hasRetrievedData &&
          headerGroups.map(({ headers, id: headerGroupId }) => (
            <tr key={headerGroupId}>
              {headers.map(
                ({
                  column: {
                    columnDef: { header, meta },
                    getCanSort,
                    getIsSorted,
                    getSize,
                    getToggleSortingHandler,
                  },
                  getContext,
                  id: headerId,
                  isPlaceholder,
                }) => {
                  const { align } = (meta ?? {}) as { align: 'center' | 'right' };

                  const canSort = getCanSort();
                  const isSorted = getIsSorted();
                  const sortArrow =
                    canSort || isSorted
                      ? {
                          asc: <IconArrowUp size={18} />,
                          desc: <IconArrowDown size={18} />,
                        }[isSorted as string] ?? null
                      : null;
                  const width = getSize();

                  return (
                    <th
                      key={headerId}
                      style={{
                        cursor: canSort ? 'pointer' : 'default',
                        width: width === 0 ? undefined : width,
                      }}
                      onClick={(event) => {
                        const toggleSortingHandler = getToggleSortingHandler();
                        toggleSortingHandler?.(event);
                      }}
                    >
                      {isPlaceholder ? null : (
                        <Group align="center" position={align} noWrap>
                          <Flex align="center">{flexRender(header, getContext())}</Flex>
                          {sortArrow && <Flex align="center">{sortArrow}</Flex>}
                        </Group>
                      )}
                    </th>
                  );
                },
              )}
            </tr>
          ))}
      </thead>
      <tbody>
        {hasRetrievedData && !rows.length ? (
          <tr>
            <td colSpan={headerGroups[0].headers.length}>{noRecordsMessage}</td>
          </tr>
        ) : (
          rows.map(({ getVisibleCells, id: rowIndex, original }) => {
            const isRowClickDisabled = disableOnClickForRowIndexes.includes(rowIndex);
            const rowColor = rowIndexToRowColorMap?.[rowIndex];

            return (
              <tr
                key={`row-${rowIndex}`}
                className={!isRowClickDisabled && onRowClick ? 'bizpay-table-clickable-row' : undefined}
                style={{
                  backgroundColor: rowColor,
                }}
                onClick={() => {
                  if (isRowClickDisabled) {
                    return;
                  }

                  if (!onRowClick) {
                    return;
                  }

                  onRowClick(original);
                }}
              >
                {getVisibleCells().map(
                  ({
                    column: {
                      columnDef: { cell, meta },
                    },
                    getContext,
                    id: cellId,
                  }) => {
                    const { align } = (meta ?? {}) as { align: 'center' | 'right' };

                    return (
                      <td key={cellId} style={tbodyTdStyles}>
                        <Group
                          align="center"
                          position={align}
                          style={{
                            whiteSpace: 'pre-wrap',
                            ...tbodyTdStyles,
                          }}
                        >
                          {flexRender(cell, getContext())}
                        </Group>
                      </td>
                    );
                  },
                )}
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  );
};

export { BizPayTable };
