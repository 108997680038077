import { FC } from 'react';

import { Stack } from '@mantine/core';

import { BaseContainerProps } from './BaseContainer.types';

const BaseContainer: FC<BaseContainerProps> = ({ boxShadowCss, children, id = 'base-container', ...stackProps }) => {
  return (
    <Stack
      id={id}
      p="xs"
      sx={({ colors, radius, white }) => ({
        backgroundColor: white,
        border: `0.075rem solid ${colors['bizpay-light-gray'][2]}`,
        borderRadius: radius.lg,
        boxShadow: boxShadowCss || `0.05rem 0.05rem ${colors['bizpay-light-gray'][3]}`,
      })}
      w="100%"
      {...stackProps}
    >
      {children}
    </Stack>
  );
};

export { BaseContainer };
