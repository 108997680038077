import { FC } from 'react';

import { BizPayPrimaryButton } from '../BizPayPrimaryButton';

import { BizPayRefreshAppButtonProps } from './BizPayRefreshAppButton.types';

const BizPayRefreshAppButton: FC<BizPayRefreshAppButtonProps> = ({ onClick }) => {
  return <BizPayPrimaryButton onClick={onClick}>A new version is available! Click here to load the latest version.</BizPayPrimaryButton>;
};

export { BizPayRefreshAppButton };
